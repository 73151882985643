import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Demo extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="page-header py-4 no-gutters row">
                {/* <div className="text-sm-left mb-3 text-center text-md-left mb-sm-0 col-12 col-sm-4">
                    <span
                        className="text-uppercase page-subtitle">{t('Escritorio ejemplo')}
                    </span>
                    <h3 className="page-title">{t('Titulo secundario')}</h3>
                </div> */}
            </div>
        );
    }
}

export default withTranslation()(Demo);
