import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/admins';
import EditarAdmin from './EditarAdmin';

const ms2p = (state) => {
    return {
        ...state.superAdmins,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EditarAdmin);
