import React, { Component } from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Registro, CambioPwd  } from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dashboard } from './common/components/App/Home';
import { CrearEmpresa, ListarEmpresas } from './common/components/App/Empresas';
import { ListarAdmins, CrearAdmin, EditarAdmin } from './common/components/App/Admins';
import { ListarCohortes, CrearCohorte, EditarCohorte, GruposCohorte, Reconocimientos } from './common/components/App/Cohortes';
import { CrearGrowthAngels, ListarGrowthAngels, EditarGrowthAngels } from './common/components/App/GrowthAngels';
import { CrearEvaluaciones, CrearTaller, ListarEvaluaciones, ListarTalleres } from './common/components/App/Talleres';
import { ListarMentorias, CrearMentoria, SlotsMentoria } from './common/components/App/Mentorias';
import Guerreros from './common/components/App/Guerreros';
import { EditarPerfil, VerPerfil, Notificaciones } from './common/components/App/Perfil';
import { CrearAviso, ListarAvisos, DetalleAviso } from './common/components/App/Avisos';
import { CrearMembresia, ListarMembresias } from './common/components/App/Membresias';
import FamilyMeeting from './common/components/App/FamilyMeeting';
import AmigosGrowmotor from './common/components/App/AmigosGrowmotor';
import { ReporteAsistencia, DetalleInasistencias, ReporteNivelacion, ReporteEmpresas, ReporteDemografico } from './common/components/App/Reportes';
import CalificacionesEventos from './common/components/App/Reportes/CalificacionEventos';
import InvitacionesMasivas from './common/components/App/InvitacionesMasivas'

import { withTranslation } from 'react-i18next';

require('../style/index.css');

try { require('../assets/sw') } catch (e) {
    console.error('sw ', e)
}

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

class Routes extends Component {

    componentDidMount() {
        console.log("fdaf ", this.props);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="container__content">
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/registro" component={Registro} />
                        <Route exact path="/cambio_contraseña/:id" component={CambioPwd.Recuperar} />
                        <Route exact path="/cambio_contraseña/" component={CambioPwd.Solicitar} />
                        <ProtectedRoute exact path="/" component={Dashboard} title={t('Dashboard')} subtitle={t("KPIs")} />

                        {/* Nuevas Rutas */}
                        <ProtectedRoute exact path="/empresas" component={ListarEmpresas} title={t('Empresas')} />
                        <ProtectedRoute exact path="/empresa/crear" component={CrearEmpresa} title={`${t('Empresa')} / `} subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/empresa/:id/editar" component={CrearEmpresa} title={`${t('Empresa')} / `} subtitle={t('Editar')} />
                        <ProtectedRoute exact path="/invitaciones_masivas" component={InvitacionesMasivas} title={t('Invitaciones Masivas')} />

                        {/* Rutas CRUD super admin */}
                        <ProtectedRoute exact path="/admins" component={ListarAdmins} title={t('Administradores')} />
                        <ProtectedRoute exact path="/admins/crear" component={CrearAdmin} title={`${t('Administradores')} / `} subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/admins/:id/editar" component={EditarAdmin} title={`${t('Administradores')} / `} subtitle={t('Editar')} />

                        {/* Rutas CRUD cohortes */}
                        <ProtectedRoute exact path="/cohortes" component={ListarCohortes} title={t('Cohortes')}/>
                        <ProtectedRoute exact path="/cohortes/crear" component={CrearCohorte} title={`${t('Cohortes')} / `} subtitle={t('Nuevo')}/>
                        <ProtectedRoute exact path="/cohortes/:id/editar" component={EditarCohorte} title={`${t('Cohortes')} / `} subtitle={t('Editar')}/>
                        <ProtectedRoute exact path="/cohortes/:id/grupos" component={GruposCohorte} title={`${t('Cohortes')} / `} subtitle={t('Grupos')}/>
                        <ProtectedRoute exact path="/cohortes/:id/reconocimientos" component={Reconocimientos} title={`${t('Cohortes')} / `} subtitle={t('Badges y diplomas')}/>

                        {/* Rutas CRUD growth angels*/}
                        <ProtectedRoute exact path="/growth_angels" component={ListarGrowthAngels} title={"Growth Angels"} />
                        <ProtectedRoute exact path="/growth_angels/crear" component={CrearGrowthAngels} title={"Growth Angel / "} subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/growth_angels/:id/editar" component={EditarGrowthAngels} title={"Growth Angel / "} subtitle={t('Editar')} />

                        {/* Talleres */}
                        <ProtectedRoute exact path="/talleres" component={ListarTalleres} title={t('Talleres')}/>
                        <ProtectedRoute exact path="/taller/crear" component={CrearTaller} title={`${t('Taller')} / `}  subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/taller/:idReuse/reutilizar" component={CrearTaller} title={`${t('Taller')} / `}  subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/taller/:id/editar" component={CrearTaller} title={`${t('Taller')} / `}  subtitle={t('Editar')}/>
                        <ProtectedRoute exact path="/taller/:id/evaluaciones" component={ListarEvaluaciones} title={`${t('Talleres')} / `}  subtitle={t('Autoevaluaciones')}/>
                        <ProtectedRoute exact path="/taller/:id/evaluacion/crear" component={CrearEvaluaciones} title={`${t('Talleres')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Nuevo')}`}/>
                        <ProtectedRoute exact path="/taller/:id/evaluacion/:idEva/editar" component={CrearEvaluaciones} title={`${t('Talleres')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Editar')}`} />

                        {/* Mentorias */}
                        <ProtectedRoute exact path="/mentorias" component={ListarMentorias} title={t('Mentorías')}/>
                        <ProtectedRoute exact path="/mentoria/crear" component={CrearMentoria} title={`${t('Mentoría')} / `}  subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/mentoria/:id/editar" component={CrearMentoria} title={`${t('Mentoría')} / `}  subtitle={t('Editar')}/>
                        <ProtectedRoute exact path="/mentoria/:id/slots" component={SlotsMentoria} title={`${t('Mentoría')} / `}  subtitle={t('Slots')}/>
                        <ProtectedRoute exact path="/mentoria/:id/evaluaciones" component={ListarEvaluaciones} title={`${t('Mentoría')} / `}  subtitle={t('Autoevaluaciones')}/>
                        <ProtectedRoute exact path="/mentoria/:id/evaluacion/crear" component={CrearEvaluaciones} title={`${t('Mentoría')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Nuevo')}`}/>
                        <ProtectedRoute exact path="/mentoria/:id/evaluacion/:idEva/editar" component={CrearEvaluaciones} title={`${t('Mentoría')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Editar')}`} />

                        {/* Encuentro Guerreros */}
                        <ProtectedRoute exact path="/panel" component={Guerreros.ListarEncuentro} title={t('Panel')}/>
                        <ProtectedRoute exact path="/panel/crear" component={Guerreros.CrearEncuentro} title={`${t('Panel')} / `}  subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/panel/:id/editar" component={Guerreros.CrearEncuentro} title={`${t('Panel')} / `}  subtitle={t('Editar')}/>
                        <ProtectedRoute exact path="/panel/:id/evaluaciones" component={Guerreros.ListarEvaluaciones} title={`${t('Panel')} / `}  subtitle={t('Autoevaluaciones')}/>
                        <ProtectedRoute exact path="/panel/:id/evaluacion/crear" component={Guerreros.CrearEvaluaciones} title={`${t('Panel')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Nuevo')}`}/>
                        <ProtectedRoute exact path="/panel/:id/evaluacion/:idEva/editar" component={Guerreros.CrearEvaluaciones} title={`${t('Panel')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Editar')}`} />

                         {/* Family Meetings */}
                        <ProtectedRoute exact path="/family_meetings" component={FamilyMeeting.Listar} title={t('Reuniones Familiares')}/>
                        <ProtectedRoute exact path="/family_meeting/crear" component={FamilyMeeting.Crear} title={`${t('Reuniones Familiares')} / `}  subtitle={t('Nuevo')} />
                        <ProtectedRoute exact path="/family_meeting/:id/editar" component={FamilyMeeting.Crear} title={`${t('Reuniones Familiares')} / `}  subtitle={t('Editar')}/>
                        <ProtectedRoute exact path="/family_meeting/:id/evaluaciones" component={FamilyMeeting.ListarEvaluaciones} title={`${t('Reuniones Familiares')} / `}  subtitle={t('Autoevaluaciones')}/>
                        <ProtectedRoute exact path="/family_meeting/:id/evaluacion/crear" component={FamilyMeeting.CrearEvaluaciones} title={`${t('Reuniones Familiares')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Nuevo')}`}/>
                        <ProtectedRoute exact path="/family_meeting/:id/evaluacion/:idEva/editar" component={FamilyMeeting.CrearEvaluaciones} title={`${t('Reuniones Familiares')} / `} subtitle={`${t('Autoevaluaciones')} / ${t('Editar')}`} />

                        {/* Amigos growmotor */}
                        <ProtectedRoute exact path="/amigos_growmotor" component={AmigosGrowmotor.Listar} title={t('Partners y Patrocinadores')}/>
                        <ProtectedRoute exact path="/amigos_growmotor/crear" component={AmigosGrowmotor.Crear} title={`${t('Partners y Patrocinadores')} / `}  subtitle={t('Nuevo')}/>
                        <ProtectedRoute exact path="/amigos_growmotor/:id/editar" component={AmigosGrowmotor.Crear} title={`${t('Partners y Patrocinadores')} / `}  subtitle={t('Editar')}/>

                        {/* Avisos Generales */}
                        <ProtectedRoute exact path="/avisos_generales" component={ListarAvisos} title={t('Avisos Generales')}/>
                        <ProtectedRoute exact path="/aviso_general/crear" component={CrearAviso} title={`${t('Avisos Generales')} / `}  subtitle={t('Nuevo')}/>
                        <ProtectedRoute exact path="/aviso_general/:id" component={DetalleAviso} title={`${t('Avisos Generales')} / `}  subtitle={t('Detalle')}/>

                        {/* Membresias */}
                        <ProtectedRoute exact path="/membresias" component={ListarMembresias} title={t('Membresias')}/>
                        <ProtectedRoute exact path="/membresia/crear" component={CrearMembresia} title={`${t('Membresia')} / `}  subtitle={t('Nuevo')}/>
                        <ProtectedRoute exact path="/membresia/:id/editar" component={CrearMembresia} title={`${t('Membresia')} / `}  subtitle={t('Editar')}/>

                        {/* Reportes */}
                        <ProtectedRoute exact path="/reporte/asistencias" component={ReporteAsistencia} title={`${t('Reportes')} / `}  subtitle={t('Inasistencias y Asistencias')}/>
                        <ProtectedRoute exact path="/reporte/inasistencias/:id" component={DetalleInasistencias} title={`${t('Reportes')} / `}  subtitle={t('Inasistencias / Detalle')}/>
                        <ProtectedRoute exact path="/reporte/nivelacion" component={ReporteNivelacion} title={`${t('Reportes')} / `} subtitle={t('Evaluación de nivelación')}/>
                        <ProtectedRoute exact path="/reporte/empresas" component={ReporteEmpresas} title={`${t('Reportes')} / `} subtitle={t('Empresas')}/>
                        <ProtectedRoute exact path="/reporte/demigrafico" component={ReporteDemografico} title={`${t('Reportes')} / `} subtitle={t('Demográfico')}/>
                        <ProtectedRoute exact path="/reporte/calificaciones_eventos" component={CalificacionesEventos.Listar} title={`${t('Reportes')} / `} subtitle={t('Calificaciones')} />
                        <ProtectedRoute exact path="/reporte/detalle_calificacion_evento/:id" component={CalificacionesEventos.Detalle} title={`${t('Reportes')} / `} subtitle={t('Calificaciones / Detalle')} />
                        <ProtectedRoute exact path="/notificaciones" component={Notificaciones} title={t('Notificaciones')}/>
                        {/* Perfil */}
                        <ProtectedRoute exact path="/perfil" component={VerPerfil} title={t('Perfil')}/>
                        <ProtectedRoute exact path="/perfil/editar" component={EditarPerfil} title={`${t('Perfil')} / `}  subtitle={t('Editar')} />
                        <Route component={NotFound} />
                    </Switch>
                </div>
                <NotificationContainer />
            </div>
        )
    }
}

const Routes2 = withTranslation()(Routes);

module.exports = (
    <Routes2 />
)
