import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import 'font-awesome/css/font-awesome.min.css';
import { getI18n } from 'react-i18next';

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: getI18n().t('¿Eliminar?'),
                text: getI18n().t('¡No podrá revertir esta acción!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: getI18n().t('¡Sí, eliminar!'),
                cancelButtonText: getI18n().t('No, cancelar'),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    reenviar = (id)=>{
        return () => {
            Swal.fire({
                title: getI18n().t('¿Reenviar?'),
                text: getI18n().t('¡Desea volver a enviar la invitación!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: getI18n().t('¡Sí, enviar!'),
                cancelButtonText: getI18n().t('No, cancelar'),
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.reenviar(id);
                }
            });
        }
    }
    Link =(id)=>{
        return()=>{
                Swal.fire({
                    title: getI18n().t('¿Generar link Zoom?'),
                    text: getI18n().t('¡El link tiene una vigencia de 90 días!'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: getI18n().t('¡Sí, Generar!'),
                    cancelButtonText: getI18n().t('No, cancelar'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.props.generarLink(id, false);
                    }
                });
            }
    }
    newLink =(id)=>{
        return()=>{
                Swal.fire({
                    title: getI18n().t('¿Generar un nuevo link Zoom?'),
                    text: getI18n().t('¡Esto elimina el link anterior, el nuevo tiene una vigencia de 90 días!'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: getI18n().t('¡Sí, Generar!'),
                    cancelButtonText: getI18n().t('No, cancelar'),
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.props.generarLink(id, false);
                    }
                });
            }
    }
    render() {
        const { id, ver, editar, eliminar, reenviar, evaluaciones, grupos, generarLink, slots, reconocimientos, row, reutilizarTaller } = this.props;

        return (
            <div className="d-flex justify-content-center">
                {(editar !== undefined) && (
                    <Link title={this.props.t('Editar')} className="action action-marron-claro px-1 py-2" to={`${editar}/${id}/editar`} ><i className="fas fa-pen-alt marron-claro"></i></Link>
                )}
                {(ver !== undefined) && (
                    <Link title={this.props.t('Ver')} className="action action-azul-oscuro  px-1 py-2" to={`${ver}/${id}/`}><i className="fas fa-eye azul-oscuro"></i></Link>
                )}
                {(reenviar !== undefined && row.is_active===false) && (
                    <a title={this.props.t('Reenviar')} className="action action-azul-oscuro  px-1 py-2" style={{cursor: "pointer"}} onClick={this.reenviar(id)}> <i className="fas fa-share"></i></a>
                )}
                {(eliminar !== undefined) && (
                    <a title={this.props.t('Eliminar')} className="action action-gris px-1 py-2" style={{cursor: "pointer"}} onClick={this.eliminar(id)}><i className="fas fa-times gris"></i></a>
                )}
                {(evaluaciones !== undefined) && (
                    <Link title={this.props.t('Autoevaluaciones')} className="action action-celeste-oscuro px-1 py-2" style={{cursor: "pointer"}} to={`${evaluaciones}/${id}/evaluaciones`}><i className="fas fa-book celeste-oscuro"></i></Link>
                )}
                {(generarLink !== undefined && row.link_host===null) && (
                    <a title={this.props.t('Generar Link')} className="action action-azul-oscuro px-1 py-2" style={{cursor: "pointer"}} onClick={this.Link(id)}><i className="fas fa-link azul-oscuro"></i></a>
                )}
                {(generarLink !== undefined && row.link_host!==null) && (
                    <a title={this.props.t('Generar Nuevo Link')} className="action action-azul-oscuro px-1 py-2" style={{cursor: "pointer"}} onClick={this.newLink(id)}><i className="fas fa-redo azul-oscuro"></i></a>
                )}
                {(grupos !== undefined) && (
                    <Link title={this.props.t('Grupos')} className="action action-azul-oscuro px-1 py-2" style={{cursor: "pointer"}} to={`${grupos}/${id}/grupos`}><i className="fas fa-users azul-oscuro"></i></Link>
                )}
                {(slots !== undefined) && (
                    <Link title={this.props.t('Slots')} className="action action-celeste-oscuro px-1 py-2" style={{cursor: "pointer"}} to={`${slots}/${id}/slots`}><i className="fas fa-clipboard-list celeste-oscuro"></i></Link>
                )}
                {(reconocimientos !== undefined) && (
                    <Link title={this.props.t('Reconocimientos')} className="action action-azul-gris-oscuro px-1 py-2" style={{cursor: "pointer"}} to={`${reconocimientos}/${id}/reconocimientos`}><i className="fas fa-graduation-cap azul-gris-oscuro"></i></Link>
                )}
                {(reutilizarTaller !== undefined) && (
                    <a title={this.props.t('Reutilizar contenido')} className="action action-azul-oscuro px-1 py-2" style={{cursor: "pointer"}}
                        onClick={() => {
                            this.props.history.push(`/taller/${id}/reutilizar`)
                        }}
                    >
                        <i className="fas fa-clone azul-oscuro"></i>
                    </a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

const Actions = withTranslation()(withRouter(Acciones))

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Actions id={cell} row={row} {...acciones}/> )
    };
}
