import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/empresas';
import CrearEmpresa from './CrearEmpresa';

const ms2p = (state) => {
    return {
        ...state.empresas,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEmpresa);
