import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Async } from 'react-select';
import Grid from '../../../Utils/Grid';
import Card from '../../../Utils/Cards/Card';
import { Link } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import { api } from 'api';
import Modal from 'react-responsive-modal';
import Cuestionario from './cuestionario';

const  IconoEmpresa = require('../../../../../../assets/img/icono-empresa.png');

const getCohortes = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

class evaluacionNivelacion extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
        this.props.clearForm();
    };
    onVer(id) {
        this.props.getCalificacion(id).then(() => {
            this.onOpenModal();
        });
    }

    componentDidMount = ()=>{
        this.props.listarEmpresas()
    } 

    render(){
        const { data1, page1, loader, listarEmpresas } = this.props;
        return(
            <div className="px-lg-3 px-0" >
                <Card className="p-lg-4">
                <div className='px-1' >
                    <HeaderBasico 
                        search={this.props.search1} 
                        searchChange={this.props.searchChange1}
                        filtro2={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.cohorte}
                            isSearchable={true}
                            loadOptions={getCohortes}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeCohorte(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={this.props.t('Filtrar por cohorte')+'...'}/>
                        }/>
                </div>
                <div className="d-flex flex-1 py-3 justify-content-end" >
                    <button type="button" className="btn btn-primary" onClick={e=>this.props.descargarExcel()} >{this.props.t('Descargar Excel')}</button>
                </div>
                    <Grid hover striped data={data1} page={page1} loading={loader} onPageChange={listarEmpresas}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataFormat={(cell,row) => (
                                <div className="d-flex justify-content-center">
                                    <a
                                        title={this.props.t("Ver")}
                                        onClick={(e) => this.onVer(cell, row)}
                                        className="action action-azul-oscuro  px-1 py-2"
                                    >
                                        <i className="fas fa-eye azul-oscuro"></i>
                                    </a>
                                </div>)}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataFormat={(cell,row)=>(
                                <div className="d-flex flex-1 align-items-center">
                                    <img className="logo-tabla mr-2" src={row.logo ? row.logo : IconoEmpresa } />
                                    <span>{cell}</span>
                                </div>)}
                        >
                            {this.props.t('Empresa')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="responsable"
                        >
                            {this.props.t('CEO / Responsable')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cohorte"
                        >
                            {this.props.t('Cohorte')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_finanzas"
                        >
                            {this.props.t('Puntaje finanzas')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_marketing"
                        >
                            {this.props.t('Puntaje marketing')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
                <Modal
                    key="modal-1"
                    open={this.state.open}
                    onClose={this.onCloseModal}
                    center
                >
                    <Cuestionario
                        loader={this.props.loader}
                        onSubmit={this.onCloseModal}
                    />
                </Modal>
            </div> 
        )
    }


}


export default withTranslation()(evaluacionNivelacion);