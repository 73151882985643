import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../Utils/renderField';
import { withTranslation } from "react-i18next";

const validators= (values, props)=>{
    const errors = {};
    if(props.cambioPwd&&!values.password){
        errors.password = `${props.t('Campo requerido')}`; 
    }
    if(props.cambioPwd&&!values.conf_password){
        errors.conf_password = `${props.t('Campo requerido')}`; 
    }
    if(values.password&&String(values.password).length<8){
        errors.password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(values.conf_password&&String(values.conf_password).length<8){
        errors.conf_password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(props.cambioPwd&&values.password&& values.conf_password){
        if(values.password!==values.conf_password){
            errors.conf_password = `${props.t('Las contraseñas no coinciden')}`; 

        }
    }
    if(!props.cambioPwd&&!values.email){
        errors.email = `${props.t('Campo requerido')}`; 
    }
    return errors;
}

const CambioPwdForm = (props) => {
    const { handleSubmit, cambioPwd } = props;
    return (
        <form name="loginForm" className="login-form d-flex flex-column flex-1" onSubmit={handleSubmit}>
            {
                !cambioPwd ? (
                    <React.Fragment>
                        <div className="d-flex flex-column flex-1 mt-3 has-feedback" >
                            <label htmlFor="email" className="blanco bold text-sm">{props.t('Email')}</label>
                            <Field name="email" label="Email" component={renderField} type="email" className="form-control" />
                        </div>

                    </React.Fragment>

                ): (
                    <div className="d-flex flex-column flex-1 mt-3 has-feedback" >
                        <label htmlFor="password" className="blanco bold text-sm">{props.t('Contraseña')}</label>
                        <Field
                            name="password"
                            label="Contraseña"
                            component={renderField}
                            type="password"
                            className="form-control"
                        />
                        <label htmlFor="conf_password" className="blanco bold text-sm pt-3">{props.t('Verificar Contraseña')}</label>
                        <Field
                            name="conf_password"
                            label={props.t("Verificar Contraseña")}
                            component={renderField}
                            type="password"
                            className="form-control"
                        />
                    </div>
                )
            }
            <div className="d-flex flex-row justify-content-center align-items-center flex-1 mt-5">
                <button type="submit" className="btn btn-primary d-flex flex-1">{
                    !cambioPwd?props.t('Recuperar contraseña'):props.t('Cambiar Contraseña')
                }</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'CambioPwdForm', // a unique identifier for this form
    validate: validators,
})(withTranslation()(CambioPwdForm));
