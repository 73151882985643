import React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '../../../Utils/Grid';
import Card from '../../../Utils/Cards/Card';
import { Link } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import moment from "moment";
import { LEGEND } from '../../../../../utility/constants';
import i18n from '../../../../../i18nextInit'

class CalificacionesEventos extends React.Component{

    componentDidMount = ()=>{
        this.props.listarEventos()
    }

    render(){
        const { data1, page1, loader, listarEventos, promedioEvento } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        return(
            <div className="px-lg-4 px-0" >
                <Card className='p-lg-4'>
                    <div className="mb-2">
                        <span className="text-md regular" >{this.props.t('RETROALIMENTACION')}</span>
                    </div>
                    <HeaderBasico
                        search={this.props.search1}
                        searchChange={this.props.searchChange1}
                        className="p-0"
                        placeholder =  {`${this.props.t('Buscar')}: ${this.props.t('Evento')}, ${this.props.t('Cohorte')}`}
                        />
                    <br/>
                    <div className="row" style={{margin: "5px"}}>
                        <div className="col-xs-6">
                            <h5>Leyenda:</h5>
                            <table className="table table-sm table-bordered">
                                <thead style={{color: "white", backgroundColor: "#0A1F40"}}>
                                    <tr>
                                        <th style={{textAlign: "center"}} scope="row">{this.props.t("Pregunta")}</th>
                                        <th style={{textAlign: "center"}} colSpan="5">{this.props.t("Datos valuables")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LEGEND.map((value, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td style={{textAlign: "center"}} rowSpan="2">{value.pregunta}</td>
                                                    {value.titulos.map((title, llave) => {
                                                        return (<React.Fragment key={llave}><td style={{textAlign: "center"}} scope="col">{this.props.t(title)}</td></React.Fragment>) 
                                                    })}
                                                </tr>
                                                <tr>
                                                    {value.valores.map((valor, chiave) => {
                                                        return (<React.Fragment key={chiave}><td style={{textAlign: "center"}} scope="col">{valor}</td></React.Fragment>) 
                                                    })}
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br/>
                    <Grid hover striped data={data1} page={page1} loading={loader} onPageChange={listarEventos}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataFormat={cell => (
                            <div className="d-flex justify-content-center">
                                <Link title={this.props.t('Ver')} className="action action-azul-oscuro  px-1 py-2" to={`/reporte/detalle_calificacion_evento/${cell}`}>
                                    <i className="fas fa-eye azul-oscuro"></i>
                                </Link>
                                <a className='action action-celeste-oscuro px-1 py-2' onClick={()=> promedioEvento(cell) }>
                                    <i className="fas fa-book celeste-oscuro"></i>
                                </a>
                            </div>)}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                        >
                            {this.props.t('Evento')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cohorte_text"
                        >
                            {this.props.t('Cohorte')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataFormat={cell => moment(cell).locale(lang).format("MMM-DD-YYYY")}
                        >
                            {this.props.t('Fecha realizada')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_evento_text"
                        >
                            {this.props.t('Tipo evento')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_participantes"
                        >
                            {this.props.t('Cant. Personas')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pregunta1"
                            dataFormat={cell=> cell.toFixed(2)}
                        >
                            {this.props.t('Pregunta 1')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pregunta2"
                            dataFormat={cell=> cell.toFixed(2)}
                        >
                            {this.props.t('Pregunta 2')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pregunta3"
                            dataFormat={cell=> cell.toFixed(2)}
                        >
                            {this.props.t('Pregunta 3')}
                        </TableHeaderColumn>

                    </Grid>

                </Card>
            </div>
        )
    }


}


export default withTranslation()(CalificacionesEventos);
