import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderCountrySelect, renderTextArea, SelectField, renderDatePicker3, renderField } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import { MODELOS, TIPO_SECTORES } from '../../../../../utility/constants';

const validators = (values, props) => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = `${props.t('Campo requerido')}`; ;
    }
    if (!values.correo_admin) {
        errors.correo_admin = `${props.t('Campo requerido')}`; ;
    }
    if (!values.nombre_admin) {
        errors.nombre_admin = `${props.t('Campo requerido')}`; ;
    }
    return errors;
}

const FormEmpresa = props => {
    const { handleSubmit } = props;
    const id = window.id
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre" className="m-0">{props.t('Nombre de la empresa')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombre" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre_admin" className="m-0">{props.t('Nombre del dueño/dueña')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombre_admin" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="correo_admin" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="correo_admin" component={renderField} type="email" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="telefono" className="m-0">{props.t('Teléfono')}</label>
                        <Field name="telefono" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                { id && <div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Dirección')} </span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px'}} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="pais" className="m-0"> {props.t('País')}</label>
                        <Field name="pais" component={renderCountrySelect} type="text" />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="ciudad" className="m-0">{props.t('Ciudad')}</label>
                        <Field name="ciudad" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="localidad" className="m-0">{props.t('Localidad')}</label>
                        <Field name="localidad" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="codigo_postal" className="m-0">{props.t('Código postal')}</label>
                        <Field name="codigo_postal" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="linea1" className="m-0">{props.t('Línea 1')}</label>
                        <Field name="linea1" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="linea2" className="m-0">{props.t('Línea 2')} </label>
                        <Field name="linea2" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Giro de negocio')} </span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px'}} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="descripcion_empresa" className="m-0">{props.t('¿Qué es lo que hace la empresa?')}</label>
                        <Field name="descripcion_empresa" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="sector" className="m-0">{props.t('Sector')} {props.t('(todos los que apliquen)')}</label>
                        <Field
                            name="sectores"
                            isMulti
                            options={TIPO_SECTORES}
                            component={SelectField}
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_fundacion" className="m-0">{props.t('Fecha de fundación de la empresa')}</label>
                        <Field name="fecha_fundacion" component={renderDatePicker3} noFuturos={true} />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="sitio_web" className="m-0">{props.t('Sitio web o página de Facebook')}</label>
                        <Field name="sitio_web" component={renderField} type="url" className="form-control" />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="sector" className="m-0">{props.t('Modelo de negocio')}</label>
                        <Field
                            name="modelo_negocios"
                            component={SelectField}
                            options={MODELOS}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="retos_importantes" className="m-0">{props.t('Retos más importantes que están impidiendo el crecimiento de mi empresa')}</label>
                        <Field name="retos_importantes" component={renderTextArea} type="text" className="form-control"/>
                    </div>
                </div>
                </div>
                }
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/empresas">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/empresas">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'EmpresaForm',
    asyncBlurFields: [],
    initialValues: {
        // fecha_fundacion: moment()
    },
    validate: validators,
})(withTranslation()(FormEmpresa));
