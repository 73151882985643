import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';



class Listar extends Component {

    componentDidMount() {
       
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }
        listar(page);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, reenviar } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/amigos_growmotor/crear' textBtn={this.props.t('Crear Partner o Patrocinador')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "/amigos_growmotor", eliminar, reenviar })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombres"
                            dataSort
                        >
                            {this.props.t('Nombre')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="empresa_nombre"
                            dataSort
                        >
                            {this.props.t('Empresa')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_usuario_text"
                            dataSort
                        >
                            {this.props.t('Tipo')}
                        </TableHeaderColumn>

                    </Grid>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(Listar)

