import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { withTranslation } from 'react-i18next';
import Search from '../../../Utils/Grid/SearchInput';
import _ from 'lodash';
import {api} from 'api';

const Checkbox = (props)=> {
    const [checked, setChecked] = React.useState(false);

    React.useEffect(()=>{
        const index = _.findIndex(props.cohortes,(item)=>(item.cohorte.id===props.id.id));
        if(index!==-1){
            setChecked(true);
        }
    },[checked])

    const toggleChange = () => {
        if(!checked){
            props.agregarCohorte(props.id);
            setChecked(true);
        }else {
            props.removerCohorte(props.id);
            setChecked(false);
        }
    }
    return (
        <div className="">
            <label className="checkbox c-checkbox" >
                <input type="checkbox"
                    checked={checked}
                    onChange={toggleChange}
                />
                <span className="fa fa-check"></span>
            </label>
        </div>
    );
}
  
class ListarCohortes extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            page:1,
            loader:false,
            search_cohortes:'',
            cohortes: [],
            data_cohortes:{
                results:[],
                count:0,
            },
        }
    }
    insertarCohortes=(cohortes)=>{
        this.props.insertarCohortes(cohortes);
        this.props.closeModal();
    }
    agregarCohorte = (cohorte)=>{
        const { cohortes } = this.state;
        const existe = _.find(cohortes, (item)=>{
            return item.cohorte.id ===cohorte.id
        } )
        if(existe===undefined)cohortes.push({cohorte:{...cohorte}})
        this.setState({cohortes})
    }
    removerCohorte = (cohorte)=>{
        let { cohortes } = this.state;
        cohortes = _.remove(cohortes,(item)=>(item.cohorte.id!==cohorte.id));
        this.setState({cohortes});
    }
    componentDidMount= ()=>{
        this.listarCohortes(1)
    }
    listarCohortes = (page = 1, search='')=>{
        const params = { page };
        params.search = search;
        this.setState({loader:true})

        const existentes = this.props.cohortes.map(item=>item.cohorte.id);
        if(existentes.length>0){
            params.ids_in = existentes;
        }
        api.get('cohorte/obtener_cohortes', params).then((response) => {
            const data = {};
            data.results  = _.differenceWith(response.results, this.props.cohortes,function(val1, val2){
                return val1.id===val2.cohorte.id
            })
            data.results = data.results.map((item)=>({id:item.id, nombre:item.nombre,cohorte:{...item}}))
            data.count = response.count;

            this.setState({data_cohortes:data})
            this.setState({page:page})
        }).catch(() => {
        }).finally(() => {
            this.setState({loader:false})
        });
    }
    searchChange=(search)=>{
        this.setState({search_cohortes:search});
        this.listarCohortes(1, search);
    }

    render() {
        return (
            <div className="mb-4 col-12 mt-3">
                <Card>
                    <div className="d-flex flex-row my-4">
                        <div className="d-none d-lg-flex flex-1"/>
                        <div className="d-flex flex-column flex-1">
                            <Search search={this.state.search_cohortes}  searchChange={this.searchChange} />
                        </div>
                    </div>
                    <Grid 
                        hover 
                        striped 
                        data={this.state.data_cohortes} 
                        page={this.state.page} 
                        loading={this.state.loader} 
                        onPageChange={e=>this.listarCohortes(e)} 
                        noResponsive={true} >
                        <TableHeaderColumn
                            isKey
                            dataField="cohorte"
                            dataAlign="center"
                            dataSort
                            dataFormat={
                                (cell)=> (  <Checkbox
                                                id={cell}
                                                agregarCohorte={this.agregarCohorte} 
                                                removerCohorte={this.removerCohorte}
                                                cohortes={this.state.cohortes} />)
                            }
                        >
                            {this.props.t('ACCIÓNES')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            {this.props.t('NOMBRES')}
                        </TableHeaderColumn>
                    </Grid>
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-1 justify-content-end" >
                            <button className="btn btn-secondary mr-2" onClick={()=>this.props.closeModal()} >{this.props.t('Cancelar')}</button>
                        </div>
                        <div className="d-flex flex-1" >
                            <button className="btn btn-primary ml-2" onClick={()=> this.insertarCohortes(this.state.cohortes)} >{this.props.t('Guardar')}</button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(ListarCohortes)