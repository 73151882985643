import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { SelectField, renderField } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import { PARTNER,SPONSOR } from '../../../../../utility/constants';
import Modal from  'react-responsive-modal';

import GridCohortes from '../Extra-Componets/GridCohortes';
import ModalCohortes from '../Extra-Componets/ModalCohortes';

const Options=[
    {value:PARTNER, label:"Partner"},
    {value:SPONSOR, label:"Patrocinador"}
]


let Form = props => {
    const [open, setOpen] = React.useState(false);
    const { handleSubmit, cohortes } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombres" className="m-0">{props.t('Nombre representante')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombres" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="empresa_nombre" className="m-0">{props.t('Nombre de la empresa')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="empresa_nombre" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="email" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="email" component={renderField} type="email" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="tipo_usuario" className="m-0">{props.t('Tipo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="tipo_usuario" component={SelectField} options={Options} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap p-2">
                    <div className="col-md-4 col-12 form-group mt-sm-3 mt-0 px-0">
                        <button type="button" className="btn btn-secondary-fill btn-block" onClick={(e)=>setOpen(true)} >{props.t('Agregar cohortes')}</button>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap p-2">
                    <label htmlFor="cohortes" className="m-0 mb-1">{props.t('Cohortes')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                    <GridCohortes data_cohortes={cohortes} removeCohorte={props.removeCohorte} />
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/amigos_growmotor">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                    <button type="button" onClick={handleSubmit(props.onSubmitEnviar)} className="btn btn-option-one ml-3 ">{props.t('Guardar Y Enviar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/amigos_growmotor">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                    <button type="button" onClick={handleSubmit(props.onSubmitEnviar)} className="btn btn-option-one flex-1 mt-3">{props.t('Guardar Y Enviar')}</button>
                </div>
                <Modal open={open} onClose={e=>setOpen(false)} >
                    <ModalCohortes  cohortes={cohortes} closeModal={e=>setOpen(false)} insertarCohortes={props.insertarCohortes} />
                </Modal>
            </div>
        </form>
    )

}

const selector = formValueSelector('AmigosGrowmotorForm');

Form = reduxForm({
    form: 'AmigosGrowmotorForm',
    asyncBlurFields: [],
    initialValues: {
        cohortes: [],
    },
    validate: (data, props) => {
        return validate(data, {
            'nombres': validators.exists()(`${props.t('Campo requerido')}`),
            'empresa_nombre': validators.exists()(`${props.t('Campo requerido')}`),
            'email': validators.exists()(`${props.t('Campo requerido')}`),
            'tipo_usuario': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(withTranslation()(Form));


const mstp = state => {
    const cohortes = selector(state, 'cohortes');

    return {
        cohortes,
    }
};

export default connect(mstp, null)(Form);

