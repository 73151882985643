import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Modal from 'react-responsive-modal';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import i18n from '../../../../../i18nextInit'

import ModalColaboradores from './ModalColaboradores';

class DetalleInasistencias extends Component {

    state = {
        modalColab: false,
        item: null,
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.obtenerDetalleEmpresa(id);
        this.onPageChange(1)
    }

    onPageChange = (page) => {
        const id = this.props.match.params.id;
        this.props.listarDetalleInasistencia(id, page)
    }

    openModalColab = (item) => {
        this.setState({ modalColab: true, item });
    };

    closeModal = (item) => {
        this.setState({ modalColab: false, item: null });
    };

    renderModalResponsable = () => {
        return (
            <Modal
                open={this.state.modalColab}
                onClose={this.closeModal}
                center
            >
                <div className="px-md-5 px-2 pt-2">
                    <ModalColaboradores
                        loader={this.props.loader}
                        item={this.state.item}
                        cerrar={this.closeModal}
                        t={this.props.t}
                    />
                </div>
            </Modal>
        )
    };

    render() {
        const { data3, loader, page3, item } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        return (
            <div className="mb-4 col-12 mt-0">
                {this.renderModalResponsable()}
                <Card noPadding={true}>
                    <div className="d-flex flex-column flex-md-row flex-1 align-items-md-center mx-3 mx-md-5 my-3">
                        <div className="d-flex flex-1 justify-content-center align-items-center">
                            <img style={{width: "2.5rem", height: "2.5rem"}} className="logo-tabla" src={item.empresa ? item.empresa.logo : null} />
                        </div>
                        <div className="d-flex flex-column flex-4 justify-content-center mt-2 mt-md-0">
                            <span className="text-sm">{this.props.t('Empresa')}</span>
                            <span className="celeste-oscuro bold text-sm">{item.empresa ? item.empresa.nombre : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-4 justify-content-center mt-2 mt-md-0">
                            <span className="text-sm">{this.props.t('Responsable')}</span>
                            <span className="celeste-oscuro bold text-sm">{item.responsable ? item.responsable.nombres : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-4 justify-content-center mt-2 mt-md-0">
                            <span className="text-sm">{this.props.t('Total de inasisencias')}</span>
                            <span className="celeste-oscuro bold text-sm">{data3.count}</span>
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="px-lg-2 my-2 borde-bottom">
                        <span className="text-sm">{this.props.t('Talleres')}</span>
                    </div>
                    <Grid hover striped data={data3} page={page3} loading={loader} onPageChange={this.onPageChange}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={(cell, row) => (<div className="d-flex justify-content-center">
                                <a title={this.props.t('Ver')} className="action action-azul-oscuro  px-1 py-2" style={{cursor: "pointer"}} onClick={() => this.openModalColab(row)}>
                                    <i className="fas fa-eye azul-oscuro"></i>
                                </a>
                            </div>)}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                        >
                            {this.props.t('Taller')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataFormat={cell => moment(cell).locale(lang).format('MMM-DD-YYYY hh:mm a')}

                        >
                            {this.props.t('Fecha a realizarse')}
                        </TableHeaderColumn>
                    </Grid>
                    <div className="d-flex flex-row justify-content-center mb-4">
                        <Link to="/reporte/asistencias" className="btn btn-secondary w-100-responsivo">{this.props.t('Regresar')}</Link>
                    </div>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(DetalleInasistencias)

