import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import AdminForm from '../FormAdmin';
import moment from 'moment';

import { withTranslation } from  'react-i18next';

class EditarAdmin extends Component {

    componentDidMount=()=>{
        const id = this.props.match.params.id;
        const {me}= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            if (id)
                this.props.leer(id);
        }
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values};
        delete body.verificar_password;
        if (id) {
            this.props.editar(body, id);
        }
    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <AdminForm onSubmit={this.onSubmit} editar={true} t={this.props.t}/>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
export default withTranslation()(EditarAdmin);