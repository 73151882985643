import React, { Component, Fragment } from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { SelectField } from '../../../Utils/renderField/renderField';


const ModalEmpresa = (props) => {
    const { handleSubmit, cancelar, empresas } = props;
    console.log("empresas ", empresas)
    return (
        <div className="d-flex flex-row flex-1 justify-content-center mx-lg-5 my-5">
            <form onSubmit={handleSubmit}>
                <div className="form-group d-flex flex-column flex-1">
                    <span className="text-sm bold azul-gris-oscuro mb-4">{props.t('Selecciona la empresa a asignar')}</span>
                    <div className="d-flex flex-column flex-1 my-3">
                        <label htmlFor="cohorte" className="m-0">{props.t('Empresa')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field
                            name="empresa"
                            options={empresas}
                            component={SelectField}
                            labelKey="nombre"
                            valueKey="id"
                        />
                    </div>
                    <div className="d-none d-lg-flex buttons-box mt-5">
                        <button type="button" onClick={cancelar} className="btn btn-secondary mr-3" to="/mentorias">{props.t('Cancelar')}</button>
                        <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-5">
                        <button type="button" onClick={cancelar} className="btn btn-secondary flex-1" to="/mentorias">{props.t('Cancelar')}</button>
                        <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default reduxForm({
    form: 'ModalEmpresaForm',
    asyncBlurFields: [],
    validate: (data, props) => {
        return validate(data, {
            'empresa': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(ModalEmpresa);
