import Listar  from './Listar';
import Crear  from './Crear';
import ListarEvaluaciones  from './ListarEvaluaciones';
import CrearEvaluaciones  from './CrearEvaluaciones';

export default {
    Listar,
    Crear,
    ListarEvaluaciones,
    CrearEvaluaciones,
}