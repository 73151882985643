import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';

class ListarGrowthAngels extends Component {
    componentDidMount=()=>{
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            listar(page);
        }
    }
    
    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, reenviar, page } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <Toolbar to='/growth_angels/crear' textBtn={this.props.t('Invitar Growth Angel')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "/growth_angels", eliminar, reenviar })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombres"
                            dataSort
                        >
                            {this.props.t('Nombres')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            {this.props.t('Correo')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="is_active"
                            dataSort
                            dataFormat={(cell) => {
                                if (cell) {
                                    return <span className="verde">{this.props.t('Registrado')}</span>
                                } else {
                                    return <span className="marron" >{this.props.t('Sin registrar')}</span>
                                }
                            }}
                        >
                            {this.props.t('Estado')}
                        </TableHeaderColumn>
                    </Grid>

                </Card>
            </div>
        )
    }
}

export default withTranslation()(ListarGrowthAngels)

