import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_ASISTENCIAS';
const DATA1 = 'DATA1_ASISTENCIAS';
const PAGE1 = 'PAGE1_ASISTENCIAS';
const SEARCH1 = 'SEARCH1_ASISTENCIAS';
const DATA2 = 'DATA2_ASISTENCIAS';
const PAGE2 = 'PAGE2_ASISTENCIAS';
const DATA3 = 'DATA3_ASISTENCIAS';
const PAGE3 = 'PAGE3_ASISTENCIAS';
const ITEM = 'ITEM_ASISTENCIAS';
const SEARCH2 = 'SEARCH2_ASISTENCIAS';
const COHORTE = 'COHORTE_ASISTENCIAS';
const EVENTO = 'EVENTO_ASISTENCIAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA1) => ({
    type,
    data
});

const setPage = (page, type=PAGE1) => ({
    type,
    page
});

const setSearch = (search, type=SEARCH1) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarInasistencias = (page=1) => (dispatch, getStore) => {
    const resource = getStore().asistencias;
    const params = { page };
    params.search = resource.search1;
    if (resource.cohorte)
        params.cohorte = resource.cohorte.id;
    dispatch(setLoader(true));
    api.get('reportes/inasistencia_eventos', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarAsistencias = (page=1) => (dispatch, getStore) => {
    const resource = getStore().asistencias;
    const params = { page };
    params.search = resource.search2;
    if (resource.cohorte)
        params.cohorte = resource.cohorte.id;
    if (resource.evento)
        params.evento = resource.evento.id;
    dispatch(setLoader(true));
    api.get('reportes/asistencia_eventos', params).then((response) => {
        dispatch(setData(response, DATA2));
        dispatch(setPage(page, PAGE2));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDetalleInasistencia = (id, page=1) => (dispatch, getStore) => {
    const resource = getStore().asistencias;
    const params = { page, responsable: id };
    dispatch(setLoader(true));
    api.get('reportes/detalle_inasistencias', params).then((response) => {
        dispatch(setData(response, DATA3));
        dispatch(setPage(page, PAGE3));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerDetalleEmpresa = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('reportes/detalle_empresa_inasistencia', { responsable: id }).then((response) => {
        dispatch(setData(response, ITEM));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange1 = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarInasistencias());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(search, SEARCH2));
    dispatch(listarAsistencias());
};

const changeCohorte = value => (dispatch) => {
    dispatch(setData(value, COHORTE));
    dispatch(listarInasistencias());
    dispatch(listarAsistencias());
}

const changeEvento = value => (dispatch) => {
    dispatch(setData(value, EVENTO));
    dispatch(listarAsistencias());
}

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().asistencias;
    let endpoint = '/api/reportes/asistencia_eventos?excel=true&';
    if (resource.cohorte)
        endpoint += `cohorte=${resource.cohorte.id}&`;
    if (resource.evento)
        endpoint += `evento=${resource.evento.id}&`;

    endpoint += `auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

export const actions = {
    listarInasistencias,
    listarAsistencias,
    listarDetalleInasistencia,
    obtenerDetalleEmpresa,
    searchChange1,
    searchChange2,
    changeCohorte,
    changeEvento,
    descargarExcel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA1]: (state, { data }) => {
        return {
            ...state,
            data1: data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [DATA3]: (state, { data }) => {
        return {
            ...state,
            data3: data,
        };
    },
    [PAGE1]: (state, { page }) => {
        return {
            ...state,
            page1: page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [PAGE3]: (state, { page }) => {
        return {
            ...state,
            page3: page,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [SEARCH1]: (state, { search }) => {
        return {
            ...state,
            search1: search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
    [COHORTE]: (state, { data }) => {
        return {
            ...state,
            cohorte: data,
        };
    },
    [EVENTO]: (state, { data }) => {
        return {
            ...state,
            evento: data,
        };
    },
};

export const initialState = {
    loader: false,
    data1: {
        count: 0,
        results: []
    },
    data2: {
        count: 0,
        results: []
    },
    data3: {
        count: 0,
        results: []
    },
    page1: 1,
    page2: 1,
    page3: 1,
    item: {},
    search1: '',
    search2: '',
    cohorte: null,
    evento: null,
};

export default handleActions(reducers, initialState);
