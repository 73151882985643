import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../i18nextInit'

import moment from 'moment';


class ListarAvisos extends Component {

    componentDidMount=()=>{
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            listar(page);
        }
    }
    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/aviso_general/crear' textBtn={this.props.t('Nuevo')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ ver: "/aviso_general" })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pais"
                            dataSort
                        >
                            {this.props.t('País')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cohorte"
                            dataFormat={cell => cell ? cell.nombre : ''}
                            dataSort
                        >
                            {this.props.t('Cohorte')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="empresa"
                            dataFormat={cell => cell ? cell.nombre : ''}
                            dataSort
                        >
                            {this.props.t('Empresa')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={cell => `${moment(cell).format("MMM-DD-YYYY hh:mm a")}`}
                        >
                            {this.props.t('Fecha')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(ListarAvisos)
