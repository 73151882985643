import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { NotificationManager } from "react-notifications";
import { withTranslation } from 'react-i18next';
import Card from '../../../Utils/Cards/Card';
import GrupoForm from './GrupoForm';
import i18n from '../../../../../i18nextInit'

import moment from 'moment';

class GruposCohorte extends Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        const { leer, obtenerGrupos, obtenerEmpresasCohorte, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            leer(id);
            obtenerGrupos(id);
            obtenerEmpresasCohorte(id);
        }
    }

    onSubmit = (values) => {
        const data = {...values};
        const id = this.props.match.params.id;

        if (data.grupos && data.grupos.length) {
            this.props.organizarGrupos(id, data);
        } else {
            NotificationManager.error(this.props.t('Cree por lo menos un grupo'), this.props.t('ERROR'), 3000);
        }
    }

    render() {
        const { loader, item, me } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        return (
            <div className="mb-4 col-12 mt-0">
                <Card>
                    <div className="d-flex flex-lg-row flex-column justify-content-center align-items-lg-center px-lg-2 mb-3">
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('Nombre')}:</span>
                            <span className="celeste-oscuro bold text-sm">{item.nombre}</span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('Ciudad')}:</span>
                            <span className="celeste-oscuro bold text-sm">{item.ciudad}</span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('País')}:</span>
                            <span className="celeste-oscuro bold text-sm">{item.pais}</span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('Fecha inicio')}:</span>
                            <span className="celeste-oscuro bold text-sm">
                                {(me&&me.timezone) ? moment(item.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY"):moment(item.fecha_inicio).format("MMM-DD-YYYY")}
                            </span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('Fecha fin')}:</span>
                            <span className="celeste-oscuro bold text-sm">
                                {(me&&me.timezone) ? moment(item.fecha_fin).tz(me.timezone).format("MMM-DD-YYYY"):moment(item.fecha_fin).format("MMM-DD-YYYY")}
                            </span>
                        </div>
                    </div>
                </Card>
                <LoadMask loading={loader} blur>
                    <GrupoForm {...this.props} onSubmit={this.onSubmit} />
                </LoadMask>
            </div>
        )
    }
}

export default withTranslation()(GruposCohorte)
