import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput';

export class HeaderBasico extends Component {
    render() {
        const { search, searchChange, placeholder } = this.props;

        return (
            <div className="d-flex flex-1 row px-2">
                <div className="col-md-4 col-6 px-2">
                    {(this.props.filtro1) ? (
                        this.props.filtro1
                    ) : <div></div>}
                </div>
                <div className="col-md-4 col-6 px-2">
                    {(this.props.filtro2) ? (
                        this.props.filtro2
                    ) : <div></div>}
                </div>
                <div className="col-md-4 col-12 px-0">
                    {(search !== undefined) && (
                        <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                    )}
                </div>
            </div>
        )
    }
}
