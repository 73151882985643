import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFieldCheck, TimeZoneSelectField } from '../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';

const validators = (values, props)=>{
    const errors= [];
    if (!values.nombres){
        errors.nombres= `${props.t('Campo requerido')}`; 
    }
    if (!values.email){
        errors.email = `${props.t('Campo requerido')}`; 
    }
    if (!values.timezone){
        errors.timezone = `${props.t('Campo requerido')}`; 
    }
    if (!values.password){
        errors.password = `${props.t('Campo requerido')}`; 
    }
    if (!values.verificar_password){
        errors.verificar_password = `${props.t('Campo requerido')}`; 
    }
    if(values.password&&String(values.password).length<8){
        errors.password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(values.verificar_password&&String(values.verificar_password).length<8){
        errors.verificar_password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(values.new_password&&String(values.new_password).length<8){
        errors.new_password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(values.verificar_new_password&&String(values.verificar_new_password).length<8){
        errors.verificar_new_password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if( values.password && values.verificar_password ){
        if (values.password !==values.verificar_password){
            errors.verificar_password =  `${props.t('Las contraseñas no coinciden')}`; 
        }
    }
    if( values.new_password && values.verificar_new_password ){
        if (values.new_password !==values.verificar_new_password){
            errors.verificar_new_password =  `${props.t('Las contraseñas no coinciden')}`; 
        }
    }
    return errors;
}

let AdminForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombres" className="m-0">{props.t('Nombres')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombres" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="email" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="email" component={renderField} type="email" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="rol" className="m-0">{props.t('Super Administrador')} </label>
                        <Field name="rol" component={renderFieldCheck} label={props.rol? props.t('Si'): props.t('No') } />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2" >
                        <label htmlFor="rol" className="m-0">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="timezone" component={TimeZoneSelectField}/>
                    </div>
                </div>

                {(!props.editar) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="tipo_negocio" className="m-0">{props.t('Contraseña')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="password" component={renderField} type="password" className="form-control"/>
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="telefono" className="m-0">{props.t('Verificar contraseña')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="verificar_password" component={renderField} type="password" className="form-control"/>
                        </div>
                    </div>
                )}
                {(props.editar) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="tipo_negocio" className="m-0">{props.t('Nueva contraseña')}</label>
                            <Field name="new_password" component={renderField} type="password" className="form-control"/>
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="telefono" className="m-0">{props.t('Verificar contraseña')}</label>
                            <Field name="verificar_new_password" component={renderField} type="password" className="form-control"/>
                        </div>
                    </div>
                )}
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/admins">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/admins">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )
}

/* export default reduxForm({
    form: 'AdminForm',
    asyncBlurFields: [],
    validate: validators,
})(withTranslation()(AdminForm));

 */

const selector = formValueSelector('AdminForm');

AdminForm = reduxForm({
    form: 'AdminForm',
    asyncBlurFields: [],
    validate:validators,
    initialValues: {
        bloques: [{ preguntas: [{}] }]
    },
})(AdminForm);

const mstp = state => {
    const rol = selector(state, 'rol');
    return {
        rol,
    }
};

export default connect(mstp, null)(withTranslation()(AdminForm));