import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, TimeZoneSelectField } from '../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import Modal from  'react-responsive-modal';

import GridCohortes from './Extra-Componets/GridCohortes';
import ModalCohortes from './Extra-Componets/ModalCohortes';

const validators = (values, props)=>{
    const errors= [];
    if (!values.nombres){
        errors.nombres= `${props.t('Campo requerido')}`; 
    }
    if (!values.email){
        errors.email = `${props.t('Campo requerido')}`; 
    }
    if (!values.timezone){
        errors.timezone = `${props.t('Campo requerido')}`; 
    }
    if (!values.password){
        errors.password = `${props.t('Campo requerido')}`; 
    }
    if (!values.verificar_password){
        errors.verificar_password = `${props.t('Campo requerido')}`; 
    }
    if(values.password&&String(values.password).length<8){
        errors.password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if(values.verificar_password&&String(values.verificar_password).length<8){
        errors.verificar_password= `${props.t('La contraseña debe que tener mínimo 8 caracteres')}`; 
    }
    if( values.password && values.verificar_password ){
        if (values.password !==values.verificar_password){
            errors.verificar_password =  `${props.t('Las contraseñas no coinciden')}`; 
        }
    }
    return errors;
}

let GrowthForm = props => {
    const [open, setOpen] = React.useState(false);
    const { handleSubmit, desactivar, cohortes, item } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombres" className="m-0">{props.t('Nombres')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombres" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="email" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="email" component={renderField} type="email" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap p-2">
                    <div className="col-md-4 col-12 form-group mt-sm-3 mt-0 px-0">
                        <button type="button" className="btn btn-secondary-fill btn-block" onClick={(e)=>setOpen(true)} >{props.t('Agregar cohortes')}</button>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap p-2">
                    <label htmlFor="cohortes" className="m-0 mb-1">{props.t('Cohortes')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                    <GridCohortes data_cohortes={cohortes} removeCohorte={props.removeCohorte} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    {(item&&!item.is_active||!item)&&(
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="desactivar" className="m-0">{props.t('Desactivar formulario primer ingreso')}</label>
                            <Field name="desactivar" component={renderSwitch} />
                        </div>
                    )}
                </div>
                {(desactivar)&&(
                    <React.Fragment>
                        <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor="timezone" className="m-0">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                                <Field name="timezone" component={TimeZoneSelectField}/>
                            </div>
                            <div className="d-none d-md-flex flex-1 p-2" ></div>
                        </div>
                        <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor="nombres" className="m-0">{props.t('Contraseña')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                                <Field name="password" component={renderField} type="password" className="form-control"/>
                            </div>
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor="verificar_password" className="m-0">{props.t('Confirmar contraseña')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                                <Field name="verificar_password" component={renderField} type="password" className="form-control"/>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <br className="d-none d-lg-flex"/>
                <br className="d-none d-lg-flex"/>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary" to="/growth_angels">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-option-one ml-3">{props.t('Guardar')}</button>
                    {(desactivar===false||(item&&!item.is_active&&!desactivar))&&(
                        <button type="button" onClick={handleSubmit(props.onSubmitEnviar)} className="btn btn-primary ml-3 ">
                            {props.t('Enviar')}</button>
                    )}
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/growth_angels">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-option-one flex-1 mt-3">{props.t('Guardar')}</button>
                    {(desactivar===false||(item&&!item.is_active&&!desactivar))&&(
                        <button type="button" onClick={handleSubmit(props.onSubmitEnviar)} className="btn btn-primary flex-1 mt-3">
                            {props.t('Enviar')}</button>
                    )}
                </div>
                <Modal open={open} onClose={e=>setOpen(false)} >
                    <ModalCohortes  cohortes={cohortes} closeModal={e=>setOpen(false)} insertarCohortes={props.insertarCohortes} />
                </Modal>

            </div>
        </form>
    )
}

const selector = formValueSelector('GrowthForm');

GrowthForm= reduxForm({
    form: 'GrowthForm',
    asyncBlurFields: [],
    initialValues: {
        cohortes: [],
        desactivar:false,
    },
    validate: validators

})(withTranslation()(GrowthForm));

const mstp = state => {
    const desactivar = selector(state, 'desactivar');
    const cohortes = selector(state, 'cohortes');

    return {
        desactivar,
        cohortes,
    }
};

export default connect(mstp, null)(GrowthForm);