import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../i18nextInit'

import moment from 'moment';


class ListarCohortes extends Component {

    componentDidMount() {
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            listar(page);
        }
    }

    render() {
        const { data, page, loader, listar: onPageChange, onSortChange, eliminar, me } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/cohortes/crear' textBtn={this.props.t('Crear cohorte')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width="15%"
                            dataFormat={standardActions({ editar: "/cohortes", eliminar, grupos: "/cohortes", reconocimientos: '/cohortes' })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width="20%"
                            dataSort
                        >
                            {this.props.t('Nombre')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pais"
                            dataSort
                        >
                            {this.props.t('País')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ciudad"
                            dataSort
                        >
                            {this.props.t('Ciudad')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={
                                (time) => (me&&me.timezone)? moment(time).tz(me.timezone).format("MMM-DD-YYYY"):moment(time).format("MMM-DD-YYYY")
                            }
                        >
                            {this.props.t('Fecha inicio')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={
                                (time) => (me&&me.timezone)? moment(time).tz(me.timezone).format("MMM-DD-YYYY"):moment(time).format("MMM-DD-YYYY")
                            }
                        >
                            {this.props.t('Fecha fin')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tiene_costo"
                            dataAlign="right"
                            dataSort
                            dataFormat={(cell, row) => cell ? <RenderCurrency value={row.costo} prefix="USD " /> : <span className="marron">{this.props.t('Sin costo')}</span>}
                        >
                            {this.props.t('Costo')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tiene_costo"
                            dataAlign="right"
                            width="5%"
                            dataFormat={cell => ""}
                        />
                    </Grid>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(ListarCohortes)
