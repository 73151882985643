import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {reset} from 'redux-form';
import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import { getI18n } from 'react-i18next';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { FAMILY_MEETING, PREWORKSHOP_FINANZAS } from '../../../utility/constants';
import { SwalExito, SwalError } from '../../../common/components/Utils/Notificaciones'

const LOADER = 'LOADER_ENCUENTRO_FAMILY_MEETING';
const DATA = 'DATA_ENCUENTRO_FAMILY_MEETING';
const ITEM_DATA = 'ITEM_ENCUENTRO_FAMILY_MEETING';
const PAGE = 'PAGE_ENCUENTRO_FAMILY_MEETING';
const ORDERING = 'ORDERING_ENCUENTRO_FAMILY_MEETING';
const SEARCH = 'SEARCH_ENCUENTRO_FAMILY_MEETING';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().familyMeeting;
    const params = { page };
    params.tipo_publicacion = FAMILY_MEETING;
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('evento', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, preSetArchivos = null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`evento/${id}`).then((response) => {
        // Para instrucciones
        const blocksFromHtml = htmlToDraft(response.instrucciones ? response.instrucciones : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        //Para descripcion
        const blocksFromHtml2 = htmlToDraft(response.descripcion ? response.descripcion : "");
        const { contentBlocks:contentBlocks2, entityMap:entityMap2 } = blocksFromHtml2;
        const contentState2 = ContentState.createFromBlockArray(contentBlocks2, entityMap2);

        const data = { ...response };
        if (data.cohorte && data.cohorte.timezone) {
            data.fecha_inicio = moment(data.fecha_inicio).tz(data.cohorte.timezone)
            data.fecha_fin = moment(data.fecha_fin).tz(data.cohorte.timezone)
        }
        dispatch(setItem({...data}));
        data.instrucciones = EditorState.createWithContent(contentState);
        data.descripcion = EditorState.createWithContent(contentState2);
        data.hora_inicio = moment(data.fecha_inicio).format("HH:mm");
        data.hora_fin = moment(data.fecha_fin).format("HH:mm");
        data.fecha_inicio = moment(data.fecha_inicio).format('YYYY-MM-DD');
        data.fecha_fin = moment(data.fecha_fin).format('YYYY-MM-DD');
        dispatch(initializeForm('FamilyMeetingForm', data));
        if (preSetArchivos){
            preSetArchivos(data.archivos);
            preSetArchivos(data.archivos_drill, true);
        }
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, attachments = []) => (dispatch) => {
    const body = {...data};
    if (body.instrucciones) {
        const draw = data.instrucciones;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.instrucciones = html;
    }

    const draw2 = data.descripcion;
    const html2 = draftToHtml(convertToRaw(draw2.getCurrentContent()));
    body.descripcion = html2;

    dispatch(setLoader(true));
    api.postAttachments('evento', body, attachments).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(cleanForm());
        dispatch(push('/family_meetings'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data, attachments = []) => (dispatch) => {
    const body = {...data};
    if (body.instrucciones) {
        const draw = data.instrucciones;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.instrucciones = html;
    }
    
    const draw2 = data.descripcion;
    const html2 = draftToHtml(convertToRaw(draw2.getCurrentContent()));
    body.descripcion = html2;

    dispatch(setLoader(true));
    api.putAttachments(`evento/${id}`, body, attachments).then(() => {
        NotificationManager.success(getI18n().t('Registro actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(cleanForm());
        dispatch(push('/family_meetings'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`evento/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('FamilyMeetingForm', {
        tiene_drill: false,
        pre_workshop: false,
        tipo_publicacion: FAMILY_MEETING,
        tipo_preworkshop: PREWORKSHOP_FINANZAS,
        calificar_evento: true,
    }));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().familyMeeting.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const generarLink=(id, leer=true)=>(dispatch)=>{
    api.post(`evento/${id}/generar_meeting_link`).then(
        ()=>{
            SwalExito(getI18n().t('Exito'), getI18n().t('Se ha generado el link Zoom'));
            if(leer){
                dispatch(leer(id));
            }
            dispatch(listar(1));
        }
    ).catch(()=>{
        SwalError(getI18n().t('Error'), getI18n().t('Error al generar link'));
    })
}

const setArchivos = (archivos_nuevos, drill=false) => (dispatch, getStore) => {
    const form = getStore().form.FamilyMeetingForm.values;
    
    if(drill){
        const archivos_drill = form.archivos_drill ? _.cloneDeep(form.archivos_drill) : [];
        for (const item of archivos_nuevos) {
            archivos_drill.push({
                nombre: item.name,
            });
        }
        dispatch(change('FamilyMeetingForm', 'archivos_drill', archivos_drill));
    }else{
        const archivos = form.archivos ? _.cloneDeep(form.archivos) : [];
        for (const item of archivos_nuevos) {
            archivos.push({
                nombre: item.name,
            });
        }
        dispatch(change('FamilyMeetingForm', 'archivos', archivos));
    }
}

const eliminarArchivo = (index, drill=false) => (dispatch, getStore) => {
    const form = getStore().form.FamilyMeetingForm.values;

    if(drill){
        let archivos_drill = _.cloneDeep(form.archivos_drill);
        archivos_drill = _.filter(archivos_drill, (item, i) => i != index );
        dispatch(change('FamilyMeetingForm', 'archivos_drill', archivos_drill));
    }else{
        let archivos = _.cloneDeep(form.archivos);
        archivos = _.filter(archivos, (item, i) => i != index );
        dispatch(change('FamilyMeetingForm', 'archivos', archivos));
    }
}

const cambiarFechaDrill = () => (dispatch, getStore) => {
    const form = getStore().form.FamilyMeetingForm.values;
    const fecha_inicio = moment(form.fecha_inicio);
    let fecha = moment(`${fecha_inicio.format('YYYY-MM-DD')}`);
    if (form.hora_inicio)
        fecha = moment(`${fecha_inicio.format('YYYY-MM-DD')} ${form.hora_inicio}`);
    fecha.subtract(2, 'days');
    dispatch(change('FamilyMeetingForm', 'fecha_limite_entregables', fecha));

}

const removerGrowthAngel =(user)=>(dispatch, getStore)=>{
    const { values } = getStore().form.FamilyMeetingForm;
    let lista = []
    if (values&&values.profesores){
        lista = [...values.profesores]
    }
    lista = _.remove(lista,function(item){
        return item.profesor.id!==user.id
    })
    const formValues = {...values, profesores:lista};
    dispatch(initializeForm('FamilyMeetingForm', formValues ))
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    generarLink,
    setArchivos,
    eliminarArchivo,
    cambiarFechaDrill,
    removerGrowthAngel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState)
