import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { withTranslation } from 'react-i18next';
import Search from '../../../Utils/Grid/SearchInput';
import { standardActions } from "../../../Utils/Grid/StandardActions";


class GridCohortes extends Component {

    render() {
        const { data_cohortes } = this.props;

        return (
            <div className="col-12 p-0">
                <Grid hover striped noResponsive={true} pagination={false}
                    data={data_cohortes ? { results: data_cohortes, count: data_cohortes.length } : { results: [],count: 0}} >
                    <TableHeaderColumn
                        isKey
                        dataField="cohorte"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({eliminar:this.props.removeCohorte})}
                    >
                        {this.props.t('ACCIÓNES')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cohorte"
                        dataSort
                        dataFormat={cell=>cell.nombre}
                    >
                        {this.props.t('NOMBRE')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cohorte"
                        dataSort
                        dataFormat={cell=>cell.pais}
                    >
                        {this.props.t('PAÍS')}
                    </TableHeaderColumn>
                </Grid>
            </div>
        )
    }
}

export default withTranslation()(GridCohortes)