import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import moment from 'moment';
import 'moment-timezone';
import _ from "lodash";
import { getI18n } from 'react-i18next';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { MENTORIA, PREWORKSHOP_FINANZAS } from '../../../utility/constants';
import { SwalExito, SwalError } from '../../../common/components/Utils/Notificaciones'

const LOADER = 'LOADER_MENTORIAS';
const DATA = 'DATA_MENTORIAS';
const ITEM_DATA = 'ITEM_MENTORIAS';
const PAGE = 'PAGE_MENTORIAS';
const ORDERING = 'ORDERING_MENTORIAS';
const SEARCH = 'SEARCH_MENTORIAS';
const EMPRESAS_COHORTE = 'EMPRESAS_COHORTE_MENTORIAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().mentorias;
    const params = { page };
    params.tipo_publicacion = MENTORIA;
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('evento', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, preSetArchivos = null, slots=false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`evento/${id}`).then((response) => {
        // Para instrucciones
        const blocksFromHtml = htmlToDraft(response.instrucciones ? response.instrucciones : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        //Para descripcion
        const blocksFromHtml2 = htmlToDraft(response.descripcion ? response.descripcion : "");
        const { contentBlocks:contentBlocks2, entityMap:entityMap2 } = blocksFromHtml2;
        const contentState2 = ContentState.createFromBlockArray(contentBlocks2, entityMap2);
        
        const data = { ...response };
        if (data.cohorte && data.cohorte.timezone) {
            data.fecha_inicio = moment(data.fecha_inicio).tz(data.cohorte.timezone)
            data.fecha_fin = moment(data.fecha_fin).tz(data.cohorte.timezone)
            data.bloque_slots = data.bloque_slots.map((item)=>({...item,
                fecha_inicio:moment(item.fecha_inicio).tz(data.cohorte.timezone),
                fecha_fin:moment(item.fecha_fin).tz(data.cohorte.timezone)}))
        }
        
        data.growth_angels = []
        data.instrucciones = EditorState.createWithContent(contentState);
        data.descripcion = EditorState.createWithContent(contentState2);
        data.fecha_hora_inicio = moment(data.fecha_inicio).format("HH:mm");
        data.fecha_hora_fin = moment(data.fecha_fin).format("HH:mm");
        data.fecha_inicio = moment(data.fecha_inicio).format('YYYY-MM-DD');
        data.fecha_fin = moment(data.fecha_fin).format('YYYY-MM-DD');
        dispatch(setItem(response));
        dispatch(initializeForm('MentoriaForm', data));
        if (preSetArchivos){
            preSetArchivos(data.archivos);
            preSetArchivos(data.archivos_drill, true);
        }
        if (slots)
            dispatch(obtenerEmpresas(response.cohorte.id));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerEmpresas = (idCohorte) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`cohorte/${idCohorte}/empresas_asignadas`).then((response) => {
        dispatch(setData(response, EMPRESAS_COHORTE));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const crear = (data, attachments = []) => (dispatch) => {
    const body = {...data};
    if (body.instrucciones) {
        const draw = data.instrucciones;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.instrucciones = html;
    }
    const draw2 = data.descripcion;
    const html2 = draftToHtml(convertToRaw(draw2.getCurrentContent()));
    body.descripcion = html2;

    dispatch(setLoader(true));
    api.postAttachments('evento', body, attachments).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(cleanForm());
        dispatch(push('/mentorias'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data, attachments = []) => (dispatch) => {
    const body = {...data};
    if (body.instrucciones) {
        const draw = data.instrucciones;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.instrucciones = html;
    }

    const draw2 = data.descripcion;
    const html2 = draftToHtml(convertToRaw(draw2.getCurrentContent()));
    body.descripcion = html2;

    dispatch(setLoader(true));
    api.putAttachments(`evento/${id}`, body, attachments).then(() => {
        NotificationManager.success(getI18n().t('Registro actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(cleanForm());
        dispatch(push('/mentorias'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`evento/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('MentoriaForm', {
        tiene_drill: false,
        pre_workshop: false,
        tipo_publicacion: MENTORIA,
        tipo_preworkshop: PREWORKSHOP_FINANZAS,
        calificar_evento: true,
        bloque_slots: [],
    }));
};

const getSlots = (hora_inicio, hora_fin) => {
    const array = [];
    if (hora_inicio&&hora_fin) {
        let horaInicio = moment(hora_inicio,['HH:mm']);
        let horaFin = moment(hora_fin,['HH:mm']);

        while(horaInicio.format('HH:mm') < horaFin.format('HH:mm')) {
            const horaAux = moment(horaInicio).add(30, 'minutes');
            if (horaAux > horaFin) {
                array.push({hora_inicio: horaInicio.format('HH:mm')});
            } else {
                array.push({hora_inicio: horaInicio.format('HH:mm')});
            }
            horaInicio.add(30, 'minutes');
        }
    }
    return array;
}

const agregarBloque = (cantidad) => (dispatch, getStore)=>{
    const { values } = getStore().form.MentoriaForm;
    const copyBloqueSlots = [...values.bloque_slots];
    const bloque = {}
    if(values.growth_angels&&values.growth_angels.length>0){
            const uniqueId = `key-${values.hora_inicio}--${values.hora_fin}`
            const fecha = moment(values.fecha_inicio);
            bloque.fecha_inicio = moment(`${fecha.format('YYYY-MM-DD')} ${values.hora_inicio}`).format();
            bloque.fecha_fin =  moment(`${fecha.format('YYYY-MM-DD')} ${values.hora_fin}`).format();
            bloque.growth_angels =values.growth_angels;
            bloque.cantidad = getSlots(values.hora_inicio, values.hora_fin).length
            bloque.id = uniqueId;
            copyBloqueSlots.push(bloque);
            const valuesForm = {
                ...values,
                hora_inicio: '',
                hora_fin: '',
                growth_angels:[],
                bloque_slots: copyBloqueSlots,
            }
            dispatch(initializeForm('MentoriaForm',valuesForm));
        } else {
            SwalError(getI18n().t('Error'), getI18n().t('Datos incorrectos para agregar un bloque de slots'));
        }
}

const removerBloque = (bloque)=>(dispatch,getStore)=>{
    const { values } = getStore().form.MentoriaForm;
    const copyBloqueSlots = [...values.bloque_slots];

    const bloqueSlots = _.without(copyBloqueSlots, bloque);
    const valuesForm = {
        ...values,
        bloque_slots: bloqueSlots,
    }
    dispatch(initializeForm('MentoriaForm',valuesForm));
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().mentorias.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const generarLink=(id, leer=true)=>(dispatch)=>{
    api.post(`evento/${id}/generar_meeting_link`).then(
        ()=>{
            SwalExito(getI18n().t('Éxito'), getI18n().t('Se ha generado el link Zoom'));
            if(leer){
                dispatch(leer(id));
            }
            dispatch(listar(1));
        }
    ).catch(()=>{
        SwalError(getI18n().t('Error'), getI18n().t('Error al generar link'));
    })
}

const setArchivos = (archivos_nuevos, drill=false) => (dispatch, getStore) => {
    const form = getStore().form.MentoriaForm.values;
    
    if(drill){
        const archivos_drill = form.archivos_drill ? _.cloneDeep(form.archivos_drill) : [];
        for (const item of archivos_nuevos) {
            archivos_drill.push({
                nombre: item.name,
            });
        }
        dispatch(change('MentoriaForm', 'archivos_drill', archivos_drill));
    }else{
        const archivos = form.archivos ? _.cloneDeep(form.archivos) : [];
        for (const item of archivos_nuevos) {
            archivos.push({
                nombre: item.name,
            });
        }
        dispatch(change('MentoriaForm', 'archivos', archivos));
    }
}

const eliminarArchivo = (index, drill=false) => (dispatch, getStore) => {
    const form = getStore().form.MentoriaForm.values;

    if(drill){
        let archivos_drill = _.cloneDeep(form.archivos_drill);
        archivos_drill = _.filter(archivos_drill, (item, i) => i != index );
        dispatch(change('MentoriaForm', 'archivos_drill', archivos_drill));
    }else{
        let archivos = _.cloneDeep(form.archivos);
        archivos = _.filter(archivos, (item, i) => i != index );
        dispatch(change('MentoriaForm', 'archivos', archivos));

    }
}

const cambiarFechaDrill = () => (dispatch, getStore) => {
    const form = getStore().form.MentoriaForm.values;
    const fecha_inicio = moment(form.fecha_inicio);
    let fecha = moment(`${fecha_inicio.format('YYYY-MM-DD')}`);
    if (form.hora_inicio)
        fecha = moment(`${fecha_inicio.format('YYYY-MM-DD')} ${form.hora_inicio}`);
    fecha.subtract(2, 'days');
    dispatch(change('MentoriaForm', 'fecha_limite_entregables', fecha));

}

const asignarEmpresaSlot = (idEvento, data, closeModal) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evento/${idEvento}/asignar_slot_admin`, data).then(() => {
        dispatch(leer(idEvento));
        closeModal();
        NotificationManager.success(getI18n().t('Empresa asignada'), getI18n().t('Éxito'), 3000);
    }).catch((error) => {
        let mensaje = getI18n().t('Error al asignar empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const desasignarEmpresaSlot = (idEvento, slot) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evento/${idEvento}/desasignar_empresa_slot`, {slot}).then(() => {
        dispatch(leer(idEvento));
        NotificationManager.success(getI18n().t('Empresa designada'), getI18n().t('Éxito'), 3000);
    }).catch((error) => {
        let mensaje = getI18n().t('Error al desasignar empresa');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    generarLink,
    setArchivos,
    eliminarArchivo,
    cambiarFechaDrill,
    asignarEmpresaSlot,
    desasignarEmpresaSlot,
    agregarBloque,
    removerBloque,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [EMPRESAS_COHORTE]: (state, { data }) => {
        return {
            ...state,
            empresas: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    empresas: [],
};

export default handleActions(reducers, initialState);
