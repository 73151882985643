import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Async } from 'react-select';
import classNames from 'classnames';
import { TableHeaderColumn } from "react-bootstrap-table";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import { api } from "api";


const getCohortes = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const InasistenciaTab = (props) => {
    const { data1, page1, loader, listarInasistencias } = props;
    return (
        <Card className="p-lg-4" >
            <div className="px-lg-2 my-3">
                <HeaderBasico
                    search={props.search1} searchChange={props.searchChange1}
                    filtro2={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={props.cohorte}
                            isSearchable={true}
                            loadOptions={getCohortes}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => props.changeCohorte(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={props.t('Filtrar por cohorte')+'...'}
                        />
                    }
                />
            </div>
            <Grid hover striped data={data1} page={page1} loading={loader} onPageChange={listarInasistencias}>
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    width="12%"
                    dataFormat={cell => (
                    <div className="d-flex justify-content-center">
                        <Link title={props.t('Ver')} className="action action-azul-oscuro  px-1 py-2" to={`/reporte/inasistencias/${cell}`}>
                            <i className="fas fa-eye azul-oscuro"></i>
                        </Link>
                    </div>)}
                >
                    {props.t('Acciones')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="empresa"
                    dataFormat={cell => (cell ?
                        <div className="d-flex flex-1 align-items-center">
                            <img className="logo-tabla mr-3" src={cell.logo} />
                            <span>{cell.nombre}</span>
                        </div>
                        : ''
                    )}
                >
                    {props.t('Empresa')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="nombres"
                >
                    {props.t('CEO / Responsable')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cohorte"
                    dataFormat={cell => cell ? cell.nombre : ''}
                >
                    {props.t('Cohorte')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="inasistencias"
                    width="20%"
                    dataAlign="end"
                    dataFormat={cell => <RenderNumber value={cell} />}
                >
                    {props.t('Total inasistencias')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="inasistencias"
                    width="1rem"
                    dataFormat={cell => ''}
                />
            </Grid>
        </Card>
    )
}

export default InasistenciaTab;
