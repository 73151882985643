import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import GrowthForm from '../FormGrowth';
import CardForm from '../../../Utils/Cards/CardForm';
import { withTranslation } from 'react-i18next';

class EditarGrowth extends Component {

    componentDidMount=()=>{
        const id = this.props.match.params.id;
        const {me}= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            if (id){
                this.props.leer(id);
            }

        }
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values};
        delete body.verificar_password
        if (id) {
            this.props.editar(body, id);
        }
    }

    onSubmitEnviar = (values) => {
        const id = this.props.match.params.id;
        const body = {...values};
        delete body.verificar_password
        body.correo = true;
        if (id) {
            this.props.editar(body, id);
        }
    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <GrowthForm 
                            onSubmit={this.onSubmit} 
                            t={this.props.t}
                            item={this.props.item}
                            openModal={this.onOpenModal} 
                            removeCohorte={this.props.removeCohorte}
                            onSubmitEnviar={this.onSubmitEnviar}
                            insertarCohortes={this.props.insertarCohortes} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(EditarGrowth);