import moment from 'moment';
import _ from "lodash"
import { ADMIN } from '../../../../../utility/constants';

//Al momento de agregar un nuevo bloque valida si entre en el rango de inicio y fin del evento
const checkedRangoFechas = (horaInicio, horaFin, horaInicioSlot, horaFinSlot)=>{
    if(horaInicioSlot>=horaInicio&&horaInicioSlot<=horaFin){
        if(horaFinSlot>=horaInicio&&horaFinSlot<=horaFin){
            return true
        }
    }
    return false
}

//Al momento de agregar un nuevo bloque de slot valida que un bloque ya existente no tenga el rango de fechas
const checkedRangoBloquesSlots =(horaInicio, horaFin, bloqueSlots )=>{

    const exists = _.find(bloqueSlots, (item)=>{
        const itemHoraInicio= moment(item.fecha_inicio).format('HH:mm')
        const itemHoraFin = moment(item.fecha_fin).format('HH:mm')

       if(horaInicio>itemHoraInicio&&horaInicio<itemHoraFin){
            return true
        }else if(horaFin>itemHoraInicio&&horaFin<itemHoraFin){
            return true
        }else if(itemHoraInicio>horaInicio&&itemHoraInicio<horaFin){
            return true
        }else if(itemHoraFin>horaInicio&&itemHoraFin<horaFin){
            return true
        }else if(horaInicio===itemHoraInicio&&horaFin===itemHoraFin){
            return true
        }      
    })
    return exists
}

// Si se cambia el rango de hora inicio y fin valida que todos los bloques entren en el rango si no ale
const checkedRangoBloquesSlots2 = (horaInicio, horaFin, bloqueSlots )=>{
    const exists = _.find(bloqueSlots, (item)=>{
        const itemHoraInicio= moment(item.fecha_inicio).format('HH:mm')
        const itemHoraFin = moment(item.fecha_fin).format('HH:mm')
        if(horaInicio>itemHoraInicio||horaInicio>itemHoraFin){
            return true
        }else if(horaFin<itemHoraInicio||horaFin<itemHoraFin){
            return true
        }       
    })
    return exists
}

const validators = (values, props)=>{
    const errors = {}
    
    if(!values.cohorte){
        errors.cohorte = `${props.t('Campo requerido')}`
    }
    if(values.cohorte&&values.bloque_slots.length>0){
        const profesoresArray = []
        _.forEach(values.bloque_slots,(bloque)=>{
            if(bloque.growth_angels.length>0){
                _.forEach(bloque.growth_angels,(profesor)=>{
                    const existe = _.find(values.cohorte.growth_angels,(item)=>{
                        return item.id===profesor.id;
                    })
 
                    if(existe===undefined&&profesor.tipo_usuario!==ADMIN){
                        const existe2 = _.find(profesoresArray,(item)=>(item===profesor.nombres))
                        if(existe2===undefined) profesoresArray.push(profesor.nombres)
                    } 
                })
            }
        })
        props.setErrors(profesoresArray);
    }
    if(!values.titulo){
        errors.titulo = `${props.t('Campo requerido')}`
    }
    if(!values.fecha_inicio){
        errors.fecha_inicio = `${props.t('Campo requerido')}`
    }
    if(!values.fecha_hora_inicio){
        errors.fecha_hora_inicio = `${props.t('Campo requerido')}`
    }
    if(!values.fecha_hora_fin){
        errors.fecha_hora_fin = `${props.t('Campo requerido')}`
    }
    if(!values.descripcion){
        errors.descripcion = `${props.t('Campo requerido')}`
    }
    if(values.fecha_hora_inicio&&values.fecha_hora_fin){
        if(values.fecha_hora_inicio>=values.fecha_hora_fin){
            errors.fecha_hora_fin = `${props.t('Hora fin debe ser mayor a hora inicio')}`
        }

    }
    if(values.fecha_inicio && values.fecha_hora_inicio){
        const now = moment();
        const fecha_inicio = moment(`${moment(values.fecha_inicio).format("YYYY-MM-DD")} ${values.fecha_hora_inicio}`);
        if (props.item && props.item.fecha_inicio){
            const item_fecha_inicio = moment(props.item.fecha_inicio);
            if (fecha_inicio.format('YYYY-MM-DD') !== item_fecha_inicio.format('YYYY-MM-DD') && fecha_inicio.format('YYYY-MM-DD') < now.format('YYYY-MM-DD')){
                errors.fecha_inicio = `${props.t('Tiene que ser una fecha futura')}`;
            }
        }else{
            if(fecha_inicio.format('YYYY-MM-DD') < now.format('YYYY-MM-DD')){
                errors.fecha_inicio = `${props.t('Tiene que ser una fecha futura')}`;
            }
        }
    }
    if(values.fecha_hora_inicio && values.fecha_hora_fin){
        if(checkedRangoBloquesSlots2(values.fecha_hora_inicio, values.fecha_hora_fin, props.bloque_slots)){
                errors.fecha_hora_inicio = `${props.t('Tienes slots que no entran en este rango de fechas')}`;
                errors.fecha_hora_fin = `${props.t('Tienes slots que no entran en este rango de fechas')}`;
            }
    }
    if(values.hora_inicio&&values.hora_fin){
        if(values.hora_inicio>=values.hora_fin){
            errors.hora_fin =`${props.t('Hora fin debe ser mayor a hora inicio')}`
        }else if(!checkedRangoFechas(values.fecha_hora_inicio, values.fecha_hora_fin, values.hora_inicio, values.hora_fin)){
                errors.hora_inicio = `${props.t('Las horas no entran en el rango que dura el taller')}`;
                errors.hora_fin =  `${props.t('Las horas no entran en el rango que dura el taller')}`;
        }else if(checkedRangoBloquesSlots(values.hora_inicio, values.hora_fin, props.bloque_slots)){
            errors.hora_inicio = `${props.t('Estas horas ya están en un bloque de slots')}`;
            errors.hora_fin =  `${props.t('Estas horas ya están en un bloque de slots')}`;
        }else if(!values.growth_angels||values.growth_angels.length<=0){
            errors.growth_angels = `${props.t('Seleccione al menos un Growth angel')}`
        }

    }
    if(values.growth_angels&&values.growth_angels.length>0){
        if(!values.hora_inicio){
            errors.hora_inicio =`${props.t('Campo requerido')}`
        }
        if(!values.hora_fin){
            errors.hora_fin =`${props.t('Campo requerido')}`
        }
    }

    return errors;
}

export default validators;