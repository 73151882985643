import { handleActions } from 'redux-actions';
import { api } from "api";
import _ from "lodash";

const LOADER = 'LOADER_REPORTE_DEMOGRAFICO';
const DATA = 'DATA_REPORTE_DEMOGRAFICO';
const PAGE = 'PAGE_REPORTE_DEMOGRAFICO';
const SEARCH = 'SEARCH_REPORTE_DEMOGRAFICO';
const ITEM = 'ITEM_REPORTE_DEMOGRAFICO';
const PAIS = 'PAIS_REPORTE_DEMOGRAFICO';
const COHORTE = 'COHORTE_REPORTE_DEMOGRAFICO';
const EMPRESA = 'EMPRESA_REPORTE_DEMOGRAFICO';
const TIPO_USUARIO = 'TIPO_USUARIO_REPORTE_DEMOGRAFICO';
const GENERO = 'GENERO_REPORTE_DEMOGRAFICO';
const RANGO = 'RANGO_REPORTE_DEMOGRAFICO';




// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA) => ({
    type,
    data
});

const setPage = (page, type=PAGE) => ({
    type,
    page
});

const setSearch = (search, type=SEARCH) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarUsuarios = (page=1) => (dispatch, getStore) => {
    const resource = getStore().demografico;
    const params = { page };
    if (resource.search)
        params.search = resource.search;
    if (resource.pais)
        params.pais = resource.pais.name;
    if (resource.cohorte)
        params.cohorte = resource.cohorte.id;
    if (resource.empresa)
        params.empresa = resource.empresa.id;
    if (resource.tipo_usuario)
        params.tipo_usuario = resource.tipo_usuario.value;
    if (resource.genero)
        params.genero = resource.genero.value;
    if (resource.rango)
        params.rango_inicial = resource.rango[0];
    if (resource.rango)
        params.rango_final = resource.rango[1];

    dispatch(setLoader(true));
    api.get('reportes/demografico', params).then((response) => {
        dispatch(setData(response, DATA));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarUsuarios());
};

const changePais = value => (dispatch) => {
    dispatch(setData(value, PAIS));
    dispatch(listarUsuarios());
}

const changeCohorte = value => (dispatch) => {
    dispatch(setData(value, COHORTE));
    dispatch(listarUsuarios());
}
const changeEmpresa = value => (dispatch) => {
    dispatch(setData(value, EMPRESA));
    dispatch(listarUsuarios());
}
const changeTipoUsuario = value => (dispatch) => {
    dispatch(setData(value, TIPO_USUARIO));
    dispatch(listarUsuarios());
}

const changeGenero = value => (dispatch) => {
    dispatch(setData(value, GENERO));
    dispatch(listarUsuarios());
}
const changeRango = value => (dispatch) => {
    dispatch(setData(value, RANGO));
    dispatch(listarUsuarios());
}

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().demografico;
    let endpoint = '/api/reportes/demografico?excel=true&';
    if (resource.pais)
        endpoint += `pais=${resource.pais.name}&`;
    if (resource.cohorte)
        endpoint += `cohorte=${resource.cohorte.id}&`;
    if (resource.empresa)
        endpoint += `empresa=${resource.empresa.id}&`;
    if (resource.tipo_usuario)
        endpoint += `tipo_usuario=${resource.tipo_usuario.value}&`;
    if (resource.genero)
        endpoint += `genero=${resource.genero.value}&`;
    if (resource.rango)
        endpoint += `rango_inicial=${resource.rango[0]}&`;
    if (resource.rango)
        endpoint += `rango_final=${resource.rango[1]}`;

    endpoint += `&auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

export const actions = {
    listarUsuarios,
    searchChange,
    changePais,
    changeCohorte,
    changeEmpresa,
    changeTipoUsuario,
    changeGenero,
    changeRango,
    descargarExcel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data: data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page: page,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search:search,
        };
    },
    [PAIS]: (state, { data }) => {
        return {
            ...state,
            pais: data,
        };
    },
    [COHORTE]: (state, { data }) => {
        return {
            ...state,
            cohorte: data,
        };
    },
    [EMPRESA]: (state, { data }) => {
        return {
            ...state,
            empresa: data,
        };
    },
    [TIPO_USUARIO]: (state, { data }) => {
        return {
            ...state,
            tipo_usuario: data,
        };
    },
    [GENERO]: (state, { data }) => {
        return {
            ...state,
            genero: data,
        };
    },
    [RANGO]: (state, { data }) => {
        return {
            ...state,
            rango: data,
        };
    }
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        results: []
    },
    page: 1,
    item: {},
    search: '',
    pais: null,
    cohorte: null,
    empresa: null,
    tipo_usuario: null,
    genero: null,
    rango:[0, 100],
};

export default handleActions(reducers, initialState);
