import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import { withTranslation } from 'react-i18next';

const fondo_login = require("../../../../../assets/img/fondo_login.png");
const fondo_cover = require("../../../../../assets/img/login_cover.png");
const logo = require("../../../../../assets/header/Logo.png");

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    recuperarPassword = () => {

    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="login-container" style={{backgroundImage: `url(${fondo_login})`}}>
                <div className="login-panel">
                    <div className="login-card-form">
                        <div className="d-flex flex-column justify-content-center align-items-center flex-5">
                            <div className="mb-4">
                                <img src={logo} className="logo-login" />
                            </div>
                            <div className="w-100 px-md-5 px-3">
                                <LoadMask loading={loader} light>
                                    <LoginForm onSubmit={onSubmit} t={this.props.t} />
                                </LoadMask>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center flex-1">
                            <span className="blanco bold text-sm">{this.props.t('¿Olvidaste tu contraseña?')}&nbsp;&nbsp;<Link to="cambio_contraseña/" className="login-link-action">{this.props.t('Recuperar')}</Link></span>
                            {/* <span className="blanco bold text-sm mt-2">¿No tienes cuenta?&nbsp;&nbsp;<a href="#" onClick={this.recuperarPassword} className="login-link-action">Crear cuenta</a></span> */}
                        </div>
                    </div>
                    <div className="login-card-cover" style={{backgroundImage: `url(${fondo_cover})`}}></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Login);
