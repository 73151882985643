import { handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import _ from "lodash";

const LOADER = 'LOADER_NIVELACION';
const DATA1 = 'DATA1_NIVELACION';
const PAGE1 = 'PAGE1_NIVELACION';
const SEARCH1 = 'SEARCH1_NIVELACION';
const DATA2 = 'DATA2_NIVELACION';
const PAGE2 = 'PAGE2_NIVELACION';
const DATA3 = 'DATA3_NIVELACION';
const PAGE3 = 'PAGE3_NIVELACION';
const ITEM = 'ITEM_NIVELACION';
const SEARCH2 = 'SEARCH2_NIVELACION';
const COHORTE = 'COHORTE_NIVELACION';
const EVENTO = 'EVENTO_NIVELACION';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA1) => ({
    type,
    data
});

const setPage = (page, type=PAGE1) => ({
    type,
    page
});

const setSearch = (search, type=SEARCH1) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarEmpresas = (page=1) => (dispatch, getStore) => {
    const resource = getStore().nivelacion;
    const params = { page };
    params.search = resource.search1;
    if (resource.cohorte)
        params.cohorte = resource.cohorte.id;
    dispatch(setLoader(true));
    api.get('reportes/nivelaciones_empresas', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getCalificacion =(id)=> (dispatch)=>{
    dispatch(setLoader(true));
    return api.get(`/empresa/${id}/obtener_examen_nivelacion/`).then((response)=>{
        dispatch(initializeForm('ExamenNivelacionForm',response));
        console.log(response)
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}
const clearForm = ()=>(dispatch)=>{
    dispatch(initializeForm('ExamenNivelacionForm',{}));
}

const searchChange1 = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarEmpresas());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(search, SEARCH2));
    dispatch(listarAsistencias());
};

const changeCohorte = value => (dispatch) => {
    dispatch(setData(value, COHORTE));
    dispatch(listarEmpresas());
}

const changeEvento = value => (dispatch) => {
    dispatch(setData(value, EVENTO));
}

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().nivelacion;
    let endpoint = '/api/reportes/nivelaciones_empresas?excel=true&';
    if (resource.cohorte)
        endpoint += `cohorte=${resource.cohorte.id}&`;

    endpoint += `auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

export const actions = {
    listarEmpresas,
    searchChange1,
    searchChange2,
    changeCohorte,
    changeEvento,
    descargarExcel,
    getCalificacion,
    clearForm,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA1]: (state, { data }) => {
        return {
            ...state,
            data1: data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [DATA3]: (state, { data }) => {
        return {
            ...state,
            data3: data,
        };
    },
    [PAGE1]: (state, { page }) => {
        return {
            ...state,
            page1: page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [PAGE3]: (state, { page }) => {
        return {
            ...state,
            page3: page,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [SEARCH1]: (state, { search }) => {
        return {
            ...state,
            search1: search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
    [COHORTE]: (state, { data }) => {
        return {
            ...state,
            cohorte: data,
        };
    },
    [EVENTO]: (state, { data }) => {
        return {
            ...state,
            evento: data,
        };
    },
};

export const initialState = {
    loader: false,
    data1: {
        count: 0,
        results: []
    },
    data2: {
        count: 0,
        results: []
    },
    data3: {
        count: 0,
        results: []
    },
    page1: 1,
    page2: 1,
    page3: 1,
    item: {},
    search1: '',
    search2: '',
    cohorte: null,
    evento: null,
};

export default handleActions(reducers, initialState);
