import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/invitacionesMasivas';
import InvitacionesMasivas from './InvitacionesMasivas';

const ms2p = (state) => {
    return {
        ...state.invitacionesMasivas,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(InvitacionesMasivas);
