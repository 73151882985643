import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withTranslation } from 'react-i18next';

import InasistenciaTab from './InasistenciaTab';
import AsistenciaTab from './AsistenciaTab';

class ReporteAsistencia extends Component {

    componentWillMount() {
        console.log('props ', this.props);
        const { listarInasistencias, listarAsistencias, page1, page2 } = this.props;
        listarInasistencias(page1);
        listarAsistencias(page2);
    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <Tabs
                    defaultActiveKey="inasistencia"
                    className="tab-mitad"
                    onChange={this.callback}
                    tabPosition="top"
                >
                    <Tabs.TabPane tab={this.props.t("Inasistencia a talleres")} key="inasistencia">
                        <InasistenciaTab {...this.props} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={this.props.t("Asistencia a talleres")} key="asistencia">
                        <AsistenciaTab {...this.props} />
                    </Tabs.TabPane>
                </Tabs>
            </div>

        )
    }
}

export default withTranslation()(ReporteAsistencia)
