import React, { Component, useState, useEffect } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import AvisoForm from './AvisoForm';
import {NotificationManager} from "react-notifications";
import { withTranslation } from 'react-i18next';


function CrearAviso(props) {
    const { me, history, crear, t, loader} = props;
    const [archivo, setArchivo] = useState([]);

    useEffect(() => {
        if(me&&me.is_superuser===false){
            history.push('/404');
        }
    });

    const onSubmit = (values) => {
        const body = {...values};
        if (body.pais) body.pais = body.pais.name;
        if (body.cohorte) body.cohorte = body.cohorte.id;
        if (body.empresa) body.empresa = body.empresa.id;

        body.archivos_adjuntos = archivo
        const array = [];

        archivo.forEach((file, index)=>{
            if(file != null){
                array.push({"file": file, "name": `archivo-${index}`});
            }
        })

        if (!body.todos) {
            if (body.enviados && body.enviados.length) {
                body.enviados = body.enviados.map(item => ({usuario: item.id }))
                crear(body, array);
            } else {
                NotificationManager.error(`${t('Seleccione al menos un usuario')}`, `${t('ERROR')}`, 3000);
            }
        } else {
            delete body.enviados;
            crear(body, array);
        }
    }

    // Agreagar Archivo
    const setArchivos = (files) => {
        console.log(files[0].size);
        const max_size = 20480;
        let state = {};
        for (let index = 0; index < files.length; index++) {
            const size = files[index].size / 1000
            console.log(size);
            if (size > max_size) {
                NotificationManager.error(`${t('Su archivo debe ser menor a 20mb')}`, `${t('ERROR')}`, 3000);
                break;
            }else{
                const name = files[index].name;
                state = files[index];
                state.nombre = name;
                archivo.push(state)    
            }
        }
        setArchivo([...archivo]);
        
    }


    // Eliminar archivo
    const eliminarArchivo = (index) => {
        let state = []
        state = archivo.filter((item, i) => i != index );

        setArchivo(state);
    }


    return (
        <div className="mb-4 col-12 mt-0">
            <CardForm>
                <LoadMask loading={loader} blur>
                    <AvisoForm {...props} setArchivos={setArchivos} eliminarArchivo={eliminarArchivo} onSubmit={onSubmit} archivo={archivo} />
                </LoadMask>
            </CardForm>
        </div>
    )
}

export default withTranslation()(CrearAviso);