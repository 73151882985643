import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../i18nextInit'

import moment from 'moment';

class Listar extends Component {

    componentWillMount() {
        console.log('props ', this.props);
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page, generarLink, me } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/family_meeting/crear' textBtn={this.props.t('Crear reunión familiar')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "/family_meeting", eliminar, evaluaciones: "/family_meeting", generarLink })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            {this.props.t('Titulo')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cohorte"
                            dataSort
                            dataFormat={cell => `${cell.nombre}`}
                        >
                            {this.props.t('Cohorte')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="12%"
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={cell => (me&&me.timezone) ? moment(cell).tz(me.timezone).format("MMM-DD-YYYY"): moment(cell).format("MMM-DD-YYYY")}
                        >
                            {this.props.t('Fecha')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="10%"
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={cell => (me&&me.timezone) ? moment(cell).tz(me.timezone).format("hh:mm a z"):moment(cell).format("hh:mm a")}
                        >
                            {this.props.t('Hora inicio')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="10%"
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={cell => (me&&me.timezone) ? moment(cell).tz(me.timezone).format("hh:mm a z"):moment(cell).format("hh:mm a")}
                        >
                            {this.props.t('Hora fin')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tiene_evaluaciones"
                            dataSort
                            dataFormat={(cell)=>{
                                if(cell){
                                    return <span className="verde">{this.props.t('Creadas')}</span>
                                }else{
                                    return <span className="marron" >{this.props.t('Pendientes')}</span>
                                }
                            }}
                        >
                            {this.props.t('Autoevaluaciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pre_workshop"
                            dataSort
                            width="10%"
                            dataFormat={(cell)=>{
                                if(cell){
                                    return <span >{this.props.t('Pre-workshop')}</span>
                                }else{
                                    return <span >{this.props.t('Normal')}</span>
                                }
                            }}
                        >
                            {this.props.t('Tipo')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(Listar)
