import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/mentorias';
import SlotsMentoria from './SlotsMentoria';

const ms2p = (state) => {
    return {
        ...state.mentorias,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SlotsMentoria);
