import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, } from 'redux-form';
import { renderField, RenderImageUpload, renderTextArea, TimeZoneSelectField } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';


const validators = (values, props)=>{
    const erros= [];
    if (!values.nombres){
        erros.nombres= `${props.t('Campo requerido')}`
    }
    if (!values.email){
        erros.email = `${props.t('Campo requerido')}`
    }
    if (!values.timezone){
        erros.timezone = `${props.t('Campo requerido')}`
    }
    if( values.new_password && values.verificar_new_password ){
        if (values.new_password !== values.verificar_new_password){
            erros.verificar_new_password = `${props.t('Las contraseñas no coinciden')}`
        }
    }
    return erros;
}

const PerfilForm = props => {
    const { handleSubmit, item } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    {item.avatar && (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="imagen" className="m-0">{props.t('Avatar actual')}</label>
                            <img src={item.avatar} alt="fotografia" className="img-fluid" style={{ width: "10rem", marginTop: "7px" }} />
                        </div>
                    )}
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="imagen" className="m-0">{props.t('Nuevo avatar')}</label>
                        <RenderImageUpload aspect={1} multiple={false} setFile={props.setAvatar} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombres" className="m-0">{props.t('Nombre')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombres" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="telefono" className="m-0">{props.t('Teléfono')}</label>
                        <Field name="telefono" component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="email" className="m-0">{props.t('Correo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="email" component={renderField} type="email" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="timezone" className="m-0">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="timezone" component={TimeZoneSelectField} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="descripcion" className="m-0">{props.t('Biografía')}</label>
                        <Field name="descripcion" component={renderTextArea} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Nueva contraseña')}</span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="new_password" className="m-0">{props.t('Contraseña')}</label>
                        <Field name="new_password" component={renderField} type="password" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="verificar_new_password" className="m-0">{props.t('Verificar contraseña')}</label>
                        <Field name="verificar_new_password" component={renderField} type="password" className="form-control"/>
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/perfil">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/perfil">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'PerfilForm',
    asyncBlurFields: [],
    validate: validators,
})(withTranslation()(PerfilForm));
