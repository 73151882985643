import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { withTranslation } from 'react-i18next';
import Search from '../../../Utils/Grid/SearchInput';
import _ from 'lodash';
import {api} from 'api';

const Checkbox = (props)=> {
    const [checked, setChecked] = React.useState(false);

    React.useEffect(()=>{
        const index = _.findIndex(props.empresas,(item)=>(item.empresa.id===props.id.id));
        if(index!==-1){
            setChecked(true);
        }
    },[checked])

    const toggleChange = () => {
        if(!checked){
            props.agregarEmpresa(props.id);
            setChecked(true);
        }else {
            props.removerEmpresa(props.id);
            setChecked(false);
        }
    }
    return (
        <div className="">
            <label className="checkbox c-checkbox" >
                <input type="checkbox"
                    checked={checked}
                    onChange={toggleChange}
                />
                <span className="fa fa-check"></span>
            </label>
        </div>
    );
}
  
class ListarEmpresas extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            page:1,
            loader:false,
            search_empresas:'',
            empresas: [],
            data_empresas:{
                results:[],
                count:0,
            },
        }
    }
    insertarEmpresas=(empresas)=>{
        this.props.insertarEmpresas(empresas);
        this.props.closeModal();
    }
    agregarEmpresa = (empresa)=>{
        const { empresas } = this.state;
        const existe = _.find(empresas, (item)=>{
            return item.empresa.id ===empresa.id
        } )
        if(existe===undefined)empresas.push({empresa:{...empresa}})
        this.setState({empresas})
    }
    removerEmpresa = (empresa)=>{
        let { empresas } = this.state;
        empresas = _.remove(empresas,(item)=>(item.empresa.id!==empresa.id));
        this.setState({empresas});
    }
    componentDidMount= ()=>{
        this.listarEmpresas(1)
    }
    listarEmpresas = (page = 1, search='')=>{
        const params = { page };
        params.search = search;
        params.participantes='';
        this.setState({loader:true})

        const existentes = this.props.empresas.map(item=>item.empresa.id);
        if(existentes.length>0){
            params.ids_in = existentes;
        }
        api.get('empresa/obtener_empresas', params).then((response) => {
            const data = {};
            data.results  = _.differenceWith(response.results, this.props.empresas,function(val1, val2){
                return val1.id===val2.empresa.id
            })
            data.results = data.results.map((item)=>({id:item.id, nombre:item.nombre,empresa:{...item}}))
            data.count = response.count;

            this.setState({data_empresas:data})
            this.setState({page:page})
        }).catch(() => {
        }).finally(() => {
            this.setState({loader:false})
        });
    }
    searchChange=(search)=>{
        this.setState({search_empresas:search});
        this.listarEmpresas(1, search);
    }

    render() {
        return (
            <div className="mb-4 col-12 mt-3">
                <Card>
                    <div className="d-flex flex-row my-4">
                        <div className="d-none d-lg-flex flex-1"/>
                        <div className="d-flex flex-column flex-1">
                            <Search search={this.state.search_empresas}  searchChange={this.searchChange} />
                        </div>
                    </div>
                    <Grid 
                        hover 
                        striped 
                        data={this.state.data_empresas} 
                        page={this.state.page} 
                        loading={this.state.loader} 
                        onPageChange={e=>this.listarEmpresas(e)} 
                        noResponsive={true} >
                        <TableHeaderColumn
                            isKey
                            dataField="empresa"
                            dataAlign="center"
                            dataSort
                            dataFormat={
                                (cell)=> (  <Checkbox
                                                id={cell}
                                                agregarEmpresa={this.agregarEmpresa} 
                                                removerEmpresa={this.removerEmpresa}
                                                empresas={this.state.empresas} />)
                            }
                        >
                            {this.props.t('ACCIÓNES')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            {this.props.t('NOMBRES')}
                        </TableHeaderColumn>
                    </Grid>
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-1 justify-content-end" >
                            <button className="btn btn-secondary mr-2" onClick={()=>this.props.closeModal()} >{this.props.t('Cancelar')}</button>
                        </div>
                        <div className="d-flex flex-1" >
                            <button className="btn btn-primary ml-2" onClick={()=> this.insertarEmpresas(this.state.empresas)} >{this.props.t('Guardar')}</button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(ListarEmpresas)