import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { renderCountrySelect, renderNumber } from '../../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';

const validators = (values, props)=>{
    const erros= [];
    if (!values.pais){
        erros.pais = `${props.t('Campo requerido')}`;
    }
    if (!values.precio){
        erros.precio = `${props.t('Campo requerido')}`;
    }
    return erros;
}

const MembresiaForm = props => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="pais" className="m-0">{props.t('País')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="pais" component={renderCountrySelect} type="text"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="precio" className="m-0">{props.t('Precio')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="precio" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} type="text" className="form-control w-100" />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/membresias">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/membresias">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )

}

export default reduxForm({
    form: 'MembresiaForm',
    asyncBlurFields: [],
    validate: validators,
})(withTranslation()(MembresiaForm));
