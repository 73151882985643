import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { withTranslation } from 'react-i18next';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';

import Swal from 'sweetalert2';


class Reconocimientos extends Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        //mandamos true para que solo nos traiga las empresas que ya completaron su registro inicial.
        this.props.leer(id,true);
    }

    activarEvaluaciones = (idEmpresa) => {
        Swal.fire({
            title: this.props.t('¿Activar badges y diploma a la empresa?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, activar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const idTaller = this.props.match.params.id;
                this.props.activarReconocimientos(idTaller, idEmpresa);
            }
        });
    }


    render() {
        const { loader, item } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <Card>
                    <LoadMask loading={loader} blur>
                        <div className="w-100 my-4">
                            <Grid hover striped data={{ count: 4, results: item ? item.empresas : [] }} loading={false} pagination={false} >
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={(cell, row) => (
                                        (row.empresa && row.empresa.graduado) ? (
                                            <span className="verde">{this.props.t('Badges y diploma activados')}</span>
                                        ) : (
                                            <button onClick={() => this.activarEvaluaciones(row.empresa.id)} className="btn btn-primary">{this.props.t('Activar')}</button>
                                        )
                                    )}
                                >
                                    {this.props.t('Badges y diploma')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="empresa"
                                    dataFormat={cell => (cell ?
                                        <div className="d-flex flex-1 align-items-center">
                                            <img className="logo-tabla mr-3" src={cell.logo} />
                                            <span>{cell.nombre}</span>
                                        </div>
                                        : ''
                                    )}
                                >
                                    {this.props.t('Nombre')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="empresa"
                                    dataFormat={cell => cell ? cell.nombre_admin : ''}
                                >
                                    {this.props.t('Responsable')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="empresa"
                                    dataFormat={cell => cell ? cell.nombre_admin : ''}
                                >
                                    {this.props.t('Responsable')}
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                        <div className="buttons-box mb-5">
                            <Link className="d-none d-md-flex btn btn-secondary" to="/cohortes">{this.props.t('Regresar')}</Link>
                            <Link className="d-flex d-md-none btn btn-secondary w-100" to="/cohortes">{this.props.t('Regresar')}</Link>
                        </div>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(Reconocimientos);
