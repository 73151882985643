import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CardForm from '../../../Utils/Cards/CardForm';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderDatePicker, renderEditorField, renderFieldCheck, RenderImageUpload, renderNumber } from '../../../Utils/renderField/renderField';
import ArchivosUploader from '../../../Utils/ArchivoUploader';


let PasoDosForm = props => {
    const { handleSubmit, tiene_drill, previousStep, imagen, setImagen, edicion, item, archivos_drill, step, step_name, step_icon } = props;

    return (
        <form onSubmit={handleSubmit}>
            <CardForm>
                <div className="form-group d-flex flex-column flex-1">
                    <div className="d-flex flex-row mb-3">
                        <div className="mr-2">
                            <i className={`fas fa-${step_icon} azul-gris-oscuro`} style={{fontSize: "2.5rem"}}></i>
                        </div>
                        <div className="d-flex flex-column">
                            <h4 className="mb-0 bold azul-gris-oscuro">{`${props.t('Paso')} ${step} ${props.t('de')} ${2}`}</h4>
                            <span className="gris-oscuro text-sm">{`${step_name}`}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        {edicion && (
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor="imagen" className="m-0">{props.t('Imagen actual')}</label>
                                <img src={item.imagen} alt="fotografia" className="img-fluid" style={{ width: "10rem", marginTop: "7px" }} />
                            </div>
                        )}
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="imagen" className="m-0">{edicion ? props.t('Nueva imagen') : props.t('Imagen')}</label>
                            <RenderImageUpload aspect={2/1} imagen={imagen} multiple={false} setFile={setImagen} />
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="limite_participantes" className="m-0">{props.t('Limite de colaboradores por empresa')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="limite_participantes" component={renderNumber} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-column justify-content-end flex-1 p-2">
                            <label htmlFor="titulo" className="m-0">{props.t('El taller tiene drill')}</label>
                            <Field
                                component={renderFieldCheck}
                                className="form-control"
                                name="tiene_drill"
                                label={props.t('Sí')}
                            />
                        </div>
                        <div className="d-flex flex-column justify-content-end flex-1 p-2">
                            <label htmlFor="titulo" className="m-0">{props.t('Realizar encuesta')}</label>
                            <Field
                                component={renderFieldCheck}
                                className="form-control"
                                name="calificar_evento"
                                label={props.t('Sí')}
                            />
                        </div>
                    </div>
                    {(tiene_drill) && (
                        <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor="fecha_limite_entregables" className="m-0">{props.t('Fecha limite de entregables del drill')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                                <Field name="fecha_limite_entregables" component={renderDatePicker} showTimeSelect={true} dateFormat="DD/MM/YYYY HH:mm" />
                            </div>
                            <div className="d-flex flex-column flex-1 p-2"></div>
                        </div>
                    )}
                    {(tiene_drill) && (
                        <div className="d-flex flex-column flex-wrap px-0 mt-2">
                            <span className="text-sm azul-gris-oscuro bold">{props.t('Instrucciones del Drill')}</span>
                            <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                        </div>
                    )}
                    {(tiene_drill) && (
                        <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-1 p-2">
                                <Field name="instrucciones" height="15%" height2="100%" component={renderEditorField} />
                            </div>
                        </div>
                    )}
                    {(tiene_drill) && (
                        <div className="d-flex flex-column flex-wrap px-0 mt-2">
                            <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos del Drill')}</span>
                            <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                        </div>
                    )}
                    {(tiene_drill) && (
                        <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-3 p-2">
                                <ArchivosUploader
                                    archivos={archivos_drill}
                                    upload={e=>props.setArchivos(e, true)}
                                    eliminar={e=>props.eliminarArchivo(e, true)}
                                    btnLabel={props.t('Cargar archivos')}
                                />
                            </div>
                            <div className="flex-2"></div>
                        </div>
                    )}
                    <div className="d-none d-lg-flex buttons-box mt-4">
                        <button type="button" onClick={previousStep} className="btn btn-secondary mr-3">{props.t('Anterior')}</button>
                        <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-4">
                        <button type="button" onClick={previousStep} className="btn btn-secondary flex-1">{props.t('Anterior')}</button>
                        <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                    </div>
                </div>
            </CardForm>
        </form>
    )

}

const selector = formValueSelector('MentoriaForm');

PasoDosForm = reduxForm({
    form: 'MentoriaForm',
    asyncBlurFields: [],
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data, props) => {
        return validate(data, {
            'limite_participantes': validators.exists()(`${props.t('Campo requerido')}`),
            'fecha_limite_entregables': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(PasoDosForm);

const mstp = state => {
    const tiene_drill = selector(state, 'tiene_drill');
    let archivos_drill = selector(state, 'archivos_drill');
    archivos_drill = archivos_drill ? archivos_drill : [];

    return {
        tiene_drill,
        archivos_drill,
    }
};

export default connect(mstp, null)(PasoDosForm);
