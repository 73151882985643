import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import FormAmigosGrowmotor from './Form';
import { withTranslation } from 'react-i18next';

class Crear extends Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        const { me }= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }
        if (id)
            this.props.leer(id);
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values}
        if (id) {
            this.props.editar(body, id);
        } else {
            body.cohortes = values.cohortes.map(item=>item.cohorte.id)
            this.props.crear(body);
        }
    }

    onSubmitEnviar = (values)=> {
        const id = this.props.match.params.id;
        const body = {...values}
        body.enviar_correo = true;
        if (id) {
            this.props.editar(body, id);
        } else {
            body.cohortes = values.cohortes.map(item=>item.cohorte.id)
            this.props.crear(body);
        }
    }
    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <FormAmigosGrowmotor 
                            onSubmit={this.onSubmit} 
                            onSubmitEnviar={this.onSubmitEnviar} 
                            openModal={this.onOpenModal} 
                            removeCohorte={this.props.removeCohorte}
                            insertarCohortes={this.props.insertarCohortes}
                            t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(Crear)