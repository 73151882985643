import Swal from 'sweetalert2';

export function SwalExito(titulo, subtitulo) {

    const SwalMod = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          content: 'd-flex justify-content-center mb-4 gris-oscuro',
          title: 'azul-gris-oscuro bold',
        },
        buttonsStyling: false,
        focusConfirm: false,
        focusCancel: false,
    })

    return (
        SwalMod.fire({
            type: 'success',
            title: titulo,
            text: subtitulo,
            confirmButtonText: 'OK'
        })
    )
}

export function SwalError(titulo, subtitulo) {
    const SwalMod = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error',
          content: 'd-flex justify-content-center mb-4 gris-oscuro',
          title: 'azul-gris-oscuro bold',
        },
        buttonsStyling: false,
        focusConfirm: false,
        focusCancel: false,
    })
    return (
        SwalMod.fire({
            type: 'error',
            title: titulo,
            text: subtitulo,
            confirmButtonText: 'OK'
        })
    )
}
