import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { withTranslation } from 'react-i18next';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField'
import { COUNTRIES } from '../../../../../utility/paises';
import _ from 'lodash';


class ListarMembresias extends Component {

    componentDidMount=()=>{
        console.log('props ', this.props);
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            listar(page);
        }
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/membresia/crear' textBtn={this.props.t('Nuevo')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "/membresia", eliminar })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pais"
                            dataSort
                            dataFormat={cell => {
                                const item = _.find(COUNTRIES, {name: cell});
                                if (item) {
                                    return (
                                        <div className="d-flex flex-row flex-1">
                                            <div>{item.flag}</div>
                                            <div className="ml-2">{item.name}</div>
                                        </div>
                                    )
                                }
                                return <div></div>
                            }}
                        >
                            {this.props.t('País')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio"
                            dataSort
                            dataAlign="right"
                            dataFormat={cell => <RenderCurrency value={cell} prefix="USD " />}
                        >
                            {this.props.t('Precio por año')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pais"
                            width="10%"
                            dataFormat={cell => ""}
                        />
                    </Grid>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(ListarMembresias)
