import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { getI18n  } from 'react-i18next';

const LOADER = 'LOADER_MASIVAS';
const DATA = 'DATA_MASIVAS';
const ITEM_DATA = 'ITEM_MASIVAS';
const PAGE = 'PAGE_MASIVAS';
const ORDERING = 'ORDERING_MASIVAS';
const SEARCH = 'SEARCH_MASIVAS';
const COLABORADORES = 'COLABORADORES_MASIVAS'
const LOADER_COLABORADORES = 'LOADER_COLABORADORES'

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderColaboradores = loaderColaboradores => ({
    type: LOADER_COLABORADORES,
    loaderColaboradores,
});

const setData = data => ({
    type: DATA,
    data,
});

const setColaboradores = colaboradores => ({
    type: COLABORADORES,
    colaboradores,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().avisos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('aviso_general', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerMainData = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('cohorte/obtenerMainMasivas').then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const buscarColaborador = (search="", page=1) => (dispatch, getStore) => {
    if(search && search !== ""){
        dispatch(setLoaderColaboradores(true));
        api.get('user', { search, page }).then((response) => {
            dispatch(setColaboradores(response ? response : {}));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoaderColaboradores(false));
        });
    } else {
        dispatch(setColaboradores({}));
    }
};

const enviarMasivas = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('cohorte/enviarMasivas', data).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(obtenerMainData());
    }).catch((error) => {
        let mensaje =  getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const elminarColab = (id, search="", page=1) => (dispatch) => {
    dispatch(setLoader(true));
    api.put('cohorte/eliminarColab', {id}).then(() => {
        dispatch(obtenerMainData());
        if(search && search !== "") dispatch(buscarColaborador(search, page))
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch((error) => {
        let mensaje =  getI18n().t('Error en la transacción');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
        dispatch(setLoader(false));
    })
}

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('aviso_general', data).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/avisos_generales'));
    }).catch((error) => {
        let mensaje =  getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().avisos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    obtenerMainData,
    enviarMasivas,
    elminarColab,
    crear,
    searchChange,
    onSortChange,
    buscarColaborador,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_COLABORADORES]: (state, { loaderColaboradores }) => {
        return {
            ...state,
            loaderColaboradores,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [COLABORADORES]: (state, { colaboradores }) => {
        return {
            ...state,
            colaboradores,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    loaderColaboradores: false,
    colaboradores: {},
    data: [],
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);