import { handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import _ from "lodash";

const LOADER = 'LOADER_REPORTE_EMPRESAS';
const DATA = 'DATA1_REPORTE_EMPRESAS';
const PAGE = 'PAGE1_REPORTE_EMPRESAS';
const SEARCH = 'SEARCH1_REPORTE_EMPRESAS';
const ITEM = 'ITEM_REPORTE_EMPRESAS';
const PAIS = 'PAIS_REPORTE_EMPRESAS';
const COHORTE = 'COHORTE_REPORTE_EMPRESAS';
const SECTOR = 'SECTOR_REPORTE_EMPRESAS';
const MODELO = 'MODELO_REPORTE_EMPRESAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data, type=DATA) => ({
    type,
    data
});

const setPage = (page, type=PAGE) => ({
    type,
    page
});

const setSearch = (search, type=SEARCH) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarEmpresas = (page=1) => (dispatch, getStore) => {
    const resource = getStore().reporteEmpresas;
    const params = { page };
    if (resource.search)
        params.search = resource.search;
    if (resource.pais)
        params.pais = resource.pais.name;
    if (resource.cohorte)
        params.cohorte = resource.cohorte.id;
    if (resource.sector)
        params.sector = resource.sector.value;
    if (resource.modelo)
        params.modelo = resource.modelo.value;

    dispatch(setLoader(true));
    api.get('reportes/empresas', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarEmpresas());
};

const changePais = value => (dispatch) => {
    dispatch(setData(value, PAIS));
    dispatch(listarEmpresas());
}

const changeCohorte = value => (dispatch) => {
    dispatch(setData(value, COHORTE));
    dispatch(listarEmpresas());
}
const changeSector = value => (dispatch) => {
    dispatch(setData(value, SECTOR));
    dispatch(listarEmpresas());
}
const changeModelo = value => (dispatch) => {
    dispatch(setData(value, MODELO));
    dispatch(listarEmpresas());
}




const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().reporteEmpresas;
    let endpoint = '/api/reportes/empresas?excel=true&';
    if (resource.pais)
        endpoint += `pais=${resource.pais.name}&`;
    if (resource.cohorte)
        endpoint += `cohorte=${resource.cohorte.id}&`;
    if (resource.sector)
        endpoint += `sector=${resource.sector.value}&`;
    if (resource.modelo)
        endpoint += `modelo=${resource.modelo.value}`;

    endpoint += `&auth_token=${localStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

export const actions = {
    listarEmpresas,
    searchChange,
    changePais,
    changeCohorte,
    changeSector,
    changeModelo,
    descargarExcel,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data: data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page: page,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search:search,
        };
    },
    [PAIS]: (state, { data }) => {
        return {
            ...state,
            pais: data,
        };
    },
    [COHORTE]: (state, { data }) => {
        return {
            ...state,
            cohorte: data,
        };
    },
    [SECTOR]: (state, { data }) => {
        return {
            ...state,
            sector: data,
        };
    },
    [MODELO]: (state, { data }) => {
        return {
            ...state,
            modelo: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        count: 0,
        results: []
    },
    page: 1,
    item: {},
    search: '',
    pais: null,
    cohorte: null,
    sector: null,
    modelo: null,
};

export default handleActions(reducers, initialState);
