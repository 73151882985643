import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import 'react-responsive-modal/styles.css';

import CohorteForm from '../CohorteForm';
import moment from 'moment';
import 'moment-timezone';

import { withTranslation } from 'react-i18next';


class CrearCohorte extends Component {
    componentDidMount=()=>{
        const {me}= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }
    }

    onSubmit = (values) => {
        const body = {...values};
        delete body.empresa;
        body.fecha_inicio = moment(moment(body.fecha_inicio).format('YYYY-MM-DD')).tz(body.timezone).startOf('day').format();
        body.fecha_fin = moment(moment(body.fecha_fin).format('YYYY-MM-DD')).tz(body.timezone).endOf('day').format();
        if (body.pais){
            body.pais= body.pais.name
        }
        if (body.empresas){
            body.empresas = body.empresas.map(item => ({empresa: item.empresa.id}) )
        }
        if (!body.empresas){
            body.empresas=[]
        }
        if (body.administrador){
            body.administrador = body.administrador.id;
        }
        console.log("data ", body)
        this.props.crear(body);
    }

    render() {
        const { loader, getEmpresas, agregarEmpresa, removerEmpresa } = this.props;
        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <CohorteForm
                            onSubmit={this.onSubmit}
                            getEmpresas={getEmpresas}
                            agregarEmpresa={agregarEmpresa}
                            removerEmpresa={removerEmpresa}
                            openModal={this.onOpenModal}
                            t={this.props.t}
                            insertarEmpresas={this.props.insertarEmpresas} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(CrearCohorte);
