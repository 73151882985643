import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import CardForm from '../../Utils/Cards/CardForm';
import InvitacionesMasivasForm from './InvitacionesMasivasForm';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class InvitacionesMasivas extends Component {

    componentDidMount=()=>{
        this.props.obtenerMainData()
    }

    onSubmit = (values) => {
        this.props.enviarMasivas(values)
    }

    render() {
        const { loader, data, buscarColaborador, loaderColaboradores, colaboradores } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <InvitacionesMasivasForm 
                            onSubmit={this.onSubmit} 
                            t={this.props.t} 
                            data={data} 
                            change={this.props.change} 
                            elminarColab={this.props.elminarColab}
                            loader={loader}
                            buscarColaborador={buscarColaborador}
                            loaderColaboradores={loaderColaboradores}
                            colaboradores={colaboradores}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(InvitacionesMasivas)