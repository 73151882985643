import React from 'react';
import moment from 'moment';
import _ from  'lodash';
import './DropDownDatePicker.css';
import { withTranslation } from  'react-i18next';

const DropdownDatePicker =(props)=>{
    const [day, setDay] = React.useState('day');
    const [month, setMonth] = React.useState('month');
    const [year , setYear] = React.useState('year'); 

    React.useEffect(()=>{
        if(moment(props.value).isValid()){
            setDay(moment(props.value).date());
            setMonth(moment(props.value).month());
            setYear(moment(props.value).year());
        }
        
    },[props.value])

    React.useEffect(()=>{
        if(day!=='day'&&month!=='month'&&year!=='year'){
            const fecha = moment()
            fecha.month(month);
            fecha.year(year);
            if(day>fecha.daysInMonth()){
                fecha.date(fecha.daysInMonth());
                setDay(fecha.daysInMonth())
            }
            else
                fecha.date(day);
            
            props.onChange(fecha)
        }
    },[day,month,year])

    const months=()=>{
        const monthsArray= [
            {value:0, label:`${props.t('Enero')}`},
            {value:1, label: `${props.t('Febrero')}`},
            {value:2, label: `${props.t('Marzo')}`},
            {value:3, label: `${props.t('Abril')}`},
            {value:4, label: `${props.t('Mayo')}`},
            {value:5, label: `${props.t('Junio')}`},
            {value:6, label: `${props.t('Julio')}`},
            {value:7, label: `${props.t('Agosto')}`},
            {value:8, label: `${props.t('Septiembre')}`},
            {value:9, label: `${props.t('Octubre')}`},
            {value:10, label: `${props.t('Noviembre')}`},
            {value:11, label: `${props.t('Diciembre')}`},
        ]
        if(month==='month')monthsArray.unshift({value:'month',label:`${props.t('Mes')}`});
        return monthsArray;
        
    } 
        
    const days = ()=>{
        let limitDay = 31
        if(month!=='month'&&year!=='year'){
            const auxFecha = moment();
            auxFecha.month(month);
            auxFecha.year(year);
            limitDay = auxFecha.daysInMonth();
        }
        const daysArray = []
        let aux_day = 1
        while(aux_day<=limitDay){
            daysArray.push({value:aux_day, label:aux_day})
            aux_day+=1
        }
        if(day==='day')daysArray.unshift({value:'day',label:`${props.t('Dia')}`});
        return daysArray
        
    }
    const years = ()=>{
        const yearsArray = [];
        if(props.noFuturos){
            const now_year = parseInt(moment().get('year'));
            let aux_year = now_year;
            while(aux_year > ( now_year - 100)){
                yearsArray.push({value: aux_year, label:aux_year});
                aux_year-=1;
            }
        }else if(props.noPasados){
            const now_year = parseInt(moment().get('year'));
            let aux_year = now_year;
            while(aux_year < (now_year + 5)){
                yearsArray.push({value: aux_year, label:aux_year});
                aux_year+=1;
            }
        }else{
            const now_year = parseInt(moment().get('year'));
            let aux_year = now_year + 5;
            while(aux_year > (now_year - 100)){
                yearsArray.push({value: aux_year, label:aux_year});
                aux_year-=1
            }
        }
        if(year==='year')yearsArray.unshift({value:'year',label:`${props.t('Año')}`});
        return yearsArray
    }

    return(
        <div className='dropdown__date__picker' >
            <React.Fragment>
                <div className="dropdown__select flex-3"> 
                    <select name='months' onChange={e=>setMonth(e.target.value)} value={month} id='months' >
                        {(months().map(item=>(<option key={item.value} value={item.value} >{item.label}</option>)))}
                    </select>
                </div>
                <div className="dropdown__select flex-1 px-1" >
                    <select name='days' onChange={e=>setDay(e.target.value)} value={day}  id='days'>
                        {(days().map(item=>(<option key={item.value} value={item.value} >{item.label}</option>)))}
                    </select>
                </div>
                <div className="dropdown__select flex-1" >
                    <select onChange={e=>setYear(e.target.value)} name='years' value={year}  id='years'>
                        {(years().map(item=>(<option key={item.value} value={item.value} >{item.label}</option>)))}
                    </select> 
                </div>

            </React.Fragment>
        </div>
    )

}

export default withTranslation()(DropdownDatePicker);