import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';

class ListarAdmins extends Component {

    componentDidMount=()=>{
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            listar(page);
        }
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/admins/crear' textBtn={this.props.t('Crear Admin')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "/admins", eliminar })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombres"
                            dataSort
                        >
                            {this.props.t('Nombres')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            {this.props.t('Correo Electrónico')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(ListarAdmins)
