import { handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import { COUNTRIES } from '../../../utility/paises';
import _ from 'lodash';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import moment from 'moment';
import 'moment-timezone';
import { getI18n } from 'react-i18next';


const LOADER = 'LOADER_COHORTES';
const DATA = 'DATA_COHORTES';
const ITEM_DATA = 'ITEM_COHORTES';
const PAGE = 'PAGE_COHORTES';
const ORDERING = 'ORDERING_COHORTES';
const SEARCH = 'SEARCH_COHORTES';
const SEARCH_EMPRESAS ='SEARCH_EMPRESAS_COHORTES';
const DATA_EMPRESAS ='DATA_EMPRESAS';
const LOADER_EMPRESAS = 'LOADER_EMPRESAS';
const EMPRESAS_ASIGNADAS = 'EMPRESAS_ASIGNADAS_COHORTES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderEmpresas = loader_empresas => ({
    type: LOADER_EMPRESAS,
    loader_empresas,
});

const setData = (data, type=DATA) => ({
    type,
    data,
});

const setDataEmpresas = data_empresas => ({
    type: DATA_EMPRESAS,
    data_empresas,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setSearchEmpresas = search_empresas => ({
    type: SEARCH_EMPRESAS,
    search_empresas,
});

//------------------------------------
//------CRUD INICIO-------------------
//------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().cohortes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('cohorte', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, registrada=false) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    //Si registrada es true al leer la cohorte solo traerá las empresa que ya completaron su registro inicial
    if (registrada)
        params.registrada='';
    api.get(`cohorte/${id}`, params).then((response) => {
        dispatch(setItem(response));
        const data = {...response}
        if (data.pais) {
            const item = _.find(COUNTRIES, {name: data.pais});
            data.pais = item;
        }
        if (data.timezone) {
            data.fecha_inicio = moment(data.fecha_inicio).tz(data.timezone).startOf('day').format("YYYY-MM-DD")
            data.fecha_fin = moment(data.fecha_fin).tz(data.timezone).endOf('day').format("YYYY-MM-DD")
        }
        dispatch(initializeForm('CohorteForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('cohorte', data).then((response) => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(push(`/cohortes/${response.id}/editar`));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`cohorte/${id}`, {...data, enviar: false}).then(() => {
        NotificationManager.success(getI18n().t('Cohorte actualizada'), getI18n().t('Éxito'), 3000);
        dispatch(push('/cohortes'));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la edición'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const enviarCorreos = (id) => (dispatch, getStore) => {
    const data = getStore().form.CohorteForm.values;
    if (data.pais){
        data.pais = data.pais.name;
    }
    if(data.administrador){
        data.administrador= data.administrador.id;
    }
    if (data.timezone) {
        data.fecha_inicio = moment(data.fecha_inicio).tz(data.timezone).startOf('day').format();
        data.fecha_fin = moment(data.fecha_fin).tz(data.timezone).endOf('day').format();
        data.enviar = true;
        dispatch(setLoader(true));
        api.put(`cohorte/${id}`, data).then(() => {
            NotificationManager.success(getI18n().t('Se han enviado los correos a las empresas'), getI18n().t('Éxito'), 3000);
            dispatch(push('/cohortes'));
        }).catch(() => {
            NotificationManager.error(getI18n().t('Error en la edición'), getI18n().t('ERROR'), 3000);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        NotificationManager.error(getI18n().t('La zona horaria es necesaria'), getI18n().t('ERROR'), 3000);
    }
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`cohorte/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerGrupos = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`cohorte/${id}/obtener_grupos`).then((response) => {
        let data = {...response};
        data.grupos = data.grupos.map( item => {
            item.empresas = item.empresas.map( item2 => ({ id: item2.id }) );
            return item;
        })
        console.log("data ", data);
        dispatch(initializeForm('GruposCohorteForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerEmpresasCohorte = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`cohorte/${id}/empresas_asignadas`).then((response) => {
        dispatch(setData(response, EMPRESAS_ASIGNADAS));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const organizarGrupos = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`cohorte/${id}/organizar_grupos`, data).then((response) => {
        NotificationManager.success(getI18n().t('Grupos organizados para la cohorte'), getI18n().t('Éxito'), 3000);
        dispatch(push('/cohortes'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al organizar grupos');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

//------------------------------------
//------CRUD FIN-------------------
//------------------------------------

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};
const searchChangeEmpresas = search => (dispatch) => {
    dispatch(setSearchEmpresas(search));
    dispatch(listarEmpresas());
};


const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().cohortes.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const getEmpresas = (search)=>()=>{
    const params = {};
    params.search = search;
    return api.get("empresa/obtener_empresas", params).then((data) => {
        if (data){
            const empresas = []
            data.results.forEach(item => {
                empresas.push({'value':item.id, 'label':item.nombre});
            });
            return empresas;
        }
        return [];
    }).catch(() => {
        return [];
    });
}
const listarEmpresas = (page = 1)=>(dispatch, getStore)=>{
    const { values } = getStore().form.CohorteForm;
    const resource = getStore().cohortes;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search_empresas;
    dispatch(setLoaderEmpresas(true));
    api.get('empresa/obtener_empresas', params).then((response) => {
        const data = {};
        data.results  = _.differenceWith(response.results, values.empresas,function(val1, val2){
            return val1.id===val2.empresa.id
        })
        data.results = data.results.map((item)=>({id:item.id,nombre:item.nombre,empresa:{...item}}))
        dispatch(setDataEmpresas(data));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderEmpresas(false));
    });
}

const agregarEmpresa = () =>(dispatch, getStore)=>{
    const { values } = getStore().form.CohorteForm;
    if (values){
        const { empresa } = values;
        const empresas =  values.empresas ? [...values.empresas]:[];
        if (empresa && empresa.value && empresa.label){
            empresas.push({'nombre':empresa.label,'id':empresa.value })
            const formValues = {...values, empresas, 'empresa':{} }
            dispatch(initializeForm('CohorteForm', formValues ))
        }else{
            NotificationManager.error(getI18n().t('Primero seleccione una empresa'), getI18n().t('ERROR'), 3000);
        }
    }else{
        NotificationManager.error(getI18n().t('Primero seleccione una empresa'), getI18n().t('ERROR'), 3000);
    }
}

const insertarEmpresas = (empresas)=>(dispatch, getStore)=>{
    const { values } = getStore().form.CohorteForm;
    let listaEmpresas = []
    if (values&&values.empresas){
        listaEmpresas = [...values.empresas]
    }
    listaEmpresas = [...listaEmpresas,...empresas]
    const formValues = {...values,empresas:listaEmpresas};
    dispatch(initializeForm('CohorteForm', formValues ))

}

const removerEmpresa=(empresa)=>(dispatch, getStore)=>{
    const { values } = getStore().form.CohorteForm;
    let listaempresas = []
    if (values&&values.empresas){
        listaempresas = [...values.empresas]
    }
    listaempresas = _.remove(listaempresas,function(co){
        return co.empresa.id!==empresa.id
    })
    const formValues = {...values,empresas:listaempresas};
    dispatch(initializeForm('CohorteForm', formValues ))
}

const activarReconocimientos = (id, empresa) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`cohorte/${id}/generar_diploma_empresa`, { empresa }).then(() => {
        NotificationManager.success(getI18n().t('Reconocimientos activados para la empresa'), getI18n().t('Éxito'), 3000);
        dispatch(leer(id));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error al activar reconocimientos'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    enviarCorreos,
    eliminar,
    obtenerGrupos,
    obtenerEmpresasCohorte,
    organizarGrupos,
    searchChange,
    onSortChange,
    getEmpresas,
    agregarEmpresa,
    listarEmpresas,
    searchChangeEmpresas,
    insertarEmpresas,
    activarReconocimientos,
    removerEmpresa,
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_EMPRESAS]: (state, { loader_empresas }) => {
        return {
            ...state,
            loader_empresas,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_EMPRESAS]: (state, { data_empresas }) => {
        return {
            ...state,
            data_empresas,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH_EMPRESAS]: (state, { search_empresas }) => {
        return {
            ...state,
            search_empresas,
        };
    },
    [EMPRESAS_ASIGNADAS]: (state, { data }) => {
        return {
            ...state,
            empresas: data,
        };
    },
};

export const initialState = {
    loader: false,
    loader_empresas: true,
    data: {
        results: [],
        count: 0,
    },
    data_empresas: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    search_empresas:'',
    empresas: [],
};


export default handleActions(reducers, initialState);
