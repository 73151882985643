import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { getI18n  } from 'react-i18next';

const LOADER = 'LOADER_AVISOS';
const DATA = 'DATA_AVISOS';
const ITEM_DATA = 'ITEM_AVISOS';
const PAGE = 'PAGE_AVISOS';
const ORDERING = 'ORDERING_AVISOS';
const SEARCH = 'SEARCH_AVISOS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().avisos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('aviso_general', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aviso_general/${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('aviso_general', data, attachments).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/avisos_generales'));
    }).catch((error) => {
        let mensaje =  getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`aviso_general/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().avisos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const resetSelect = () => (dispatch, getStore) => {
    const form = getStore().form.AvisoForm.values;
    dispatch(change('AvisoForm', 'pais', null));
    dispatch(change('AvisoForm', 'cohorte', null));
    dispatch(change('AvisoForm', 'empresa', null));
    dispatch(change('AvisoForm', 'todos', !form.todos));
    dispatch(change('AvisoForm', 'enviados', []));
    setTimeout(() => {
        dispatch(change('AvisoForm', 'pais', form.pais));
        dispatch(change('AvisoForm', 'cohorte', form.cohorte));
        dispatch(change('AvisoForm', 'empresa', form.empresa));
        dispatch(change('AvisoForm', 'todos', !!form.todos));
    }, 100)
}

export const actions = {
    listar,
    leer,
    crear,
    eliminar,
    searchChange,
    onSortChange,
    resetSelect,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
