import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderCountrySelect, AsyncSelectField, renderFieldCheck, renderTextArea } from '../../../Utils/renderField/renderField';
import { USUARIO } from '../../../../../utility/constants';
import { COUNTRIES } from '../../../../../utility/paises';
import { withTranslation } from 'react-i18next';
import { api } from "api";
import ArchivosUploader from '../../../Utils/ArchivoUploader';



let AvisoForm = props => {
    const { handleSubmit, pais, cohorte, empresa, setArchivos, eliminarArchivo, archivo } = props;


    const getCohortes = (search) => {
        const params = {}
        if (search) params.search = search;
        if (pais) params.pais = pais.name;
        return api.get("cohorte", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    const getEmpresas = (search) => {
        const params = {}
        if (search) params.search = search;
        if (cohorte) params.cohorte = cohorte.id;
        return api.get("empresa/obtener_empresas", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    const getUsuarios = (search) => {
        const params = {tipo_usuario: USUARIO}
        if (search) params.search = search;
        if (cohorte) params.cohorte = cohorte.id;
        if (empresa) params.empresa = empresa.id;
        return api.get("user", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="pais" className="m-0">{props.t('País')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="pais" component={renderCountrySelect} type="text"/>
                    </div>
                    {(pais) ? (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="cohorte" className="m-0">{props.t('Cohorte')}</label>
                            <Field
                                name="cohorte"
                                loadOptions={getCohortes}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={true}
                                onCambio={props.resetSelect}
                                className="form-control"
                            />
                        </div>

                    ) : (<div className="d-flex flex-column flex-1 p-2"></div>)}
                </div>
                {(cohorte) && (
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="empresa" className="m-0">{props.t('Empresa')}</label>
                            <Field
                                name="empresa"
                                loadOptions={getEmpresas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={true}
                                onCambio={props.resetSelect}
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column flex-1 p-2"></div>
                    </div>
                )}
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="todos" className="m-0">{props.t('Personas')}</label>
                        <Field name="todos" label={props.t('Seleccionar todos')} component={renderFieldCheck} />
                    </div>
                    {(!props.todos) && (
                        <div className="d-flex flex-column justify-content-end flex-4 p-2">
                            <Field
                                name="enviados"
                                loadOptions={getUsuarios}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isMulti={true}
                                isClearable={true}
                                className="form-control"
                            />
                        </div>
                    )}
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="cuerpo_mensaje" className="m-0">{props.t('Cuerpo del mensaje')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="cuerpo_mensaje" component={renderTextArea} type="text" className="form-control" />
                    </div>
                </div>

                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="cuerpo_mensaje" className="m-0">{props.t('Archivos adjuntos')}</label>
                        <div className="d-flex flex-row flex-1 justify-content-start align-items-center mt-3">

                            <div className="flex-1">
                                
                                <ArchivosUploader
                                    archivos={archivo}
                                    upload={setArchivos}
                                    eliminar={eliminarArchivo}
                                    btnLabel={props.t('Cargar archivos')}
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/avisos_generales">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Guardar')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/avisos_generales">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                </div>
            </div>
        </form>
    )
}

const selector = formValueSelector('AvisoForm');

AvisoForm = reduxForm({
    form: 'AvisoForm',
    asyncBlurFields: [],
    initialValues: {
        // pais: COUNTRIES[0],
        todos: true,
    },
    validate: (data, props) => {
        return validate(data, {
            'pais': validators.exists()(`${props.t('Campo requerido')}`),
            'cuerpo_mensaje': validators.exists()(`${props.t('Campo requerido')}`),
        })
    },
})(AvisoForm);

const mstp = state => {
    const pais = selector(state, 'pais');
    const cohorte = selector(state, 'cohorte');
    const empresa = selector(state, 'empresa');
    const todos = selector(state, 'todos');
    let archivos = selector(state, 'archivos');
    archivos = archivos ? archivos : [];

    return {
        pais,
        cohorte,
        empresa,
        todos,
        archivos,
    }
};

export default connect(mstp, null)(withTranslation()(AvisoForm));
