import i18n from "../i18nextInit";
import { getI18n  } from 'react-i18next';
// Extensiones para archivos permitidas
export const EXTENSIONES = '.xlsx, .xlsm, .xls, .pdf, .pptx, .pptm, .ppt, .odp, .doc, .docx, .docm, .dotx'

// Tipos de usuario
export const ADMIN = 10;
export const GROWTH_ANGEL = 20;
export const USUARIO = 30;
export const PARTNER = 40
export const SPONSOR = 50


export const TIPOS_USUARIO = [
    {value: ADMIN, label: getI18n().t("Administrador")},
    {value: GROWTH_ANGEL, label: getI18n().t("Growth Angel")},
    {value: USUARIO, label: getI18n().t("Participante")}
];

// Generos
export const HOMBRE = 10;
export const MUJER = 20;
export const OTRO = 30;

export const GENEROS = [
    {value: HOMBRE, label: getI18n().t("Hombre")},
    {value: MUJER, label: getI18n().t("Mujer")},
    {value: OTRO, label: getI18n().t("Otro")}
];

// Modelos de negocio
export const B2B = 10;
export const B2C = 20;
export const ALL = 30;

export const MODELOS = [
    {value: B2B, label: "B2B"},
    {value: B2C, label: "B2C"},
    {value: ALL, label: "B2B y B2C"}
];

// TIPOS DE PUBLICACIÓN
export const TALLER = 10;
// ! Encuentro se cambio por el nombre de panel
export const ENCUENTRO_GUERREROS = 20;
export const HISTORIAS = 30;
export const FAMILY_MEETING = 40;
export const MENTORIA = 50;
export const BENEFICIO = 60;
export const INTERCAMBIO = 70;

export const TIPO_PUBLICACIONES = [
    {value: TALLER, label: getI18n().t("Taller")},
    {value: ENCUENTRO_GUERREROS, label: getI18n().t("Panel")},
    {value: HISTORIAS, label: getI18n().t("Historia")},
    {value: FAMILY_MEETING, label: getI18n().t("Family meeting")},
    {value: MENTORIA, label: getI18n().t("Mentoría")},
    {value: BENEFICIO, label: getI18n().t("Beneficio")},
    {value: INTERCAMBIO, label: getI18n().t("Intercambios")},
];


// TIPOS DE IDIOMAS
export const ARABE = 10
export const CHINO = 20
export const INGLES = 30
export const FRANCES = 40
export const ALEMAN = 50
export const HINDI = 60
export const JAPONES = 70
export const PORTUGUES = 80
export const RUSO = 90
export const ESPAÑOL = 100

export const TIPO_IDIOMAS =[
    {value: ARABE, label: 'Árabe'},
    {value: CHINO, label: 'Chino'},
    {value: INGLES, label: 'Ingles'},
    {value: FRANCES, label: 'Francés'},
    {value: ALEMAN, label: 'Alemán'},
    {value: HINDI, label: 'Hindi'},
    {value: JAPONES, label: 'Japones'},
    {value: PORTUGUES, label: 'Portugués'},
    {value: RUSO, label: 'Ruso'},
    {value: ESPAÑOL, label: 'Español'},
]

// Sectores
export const ENERGIA = 10;
export const FINANZAS = 20;
export const SALUD = 30;
export const INDUSTRIALES = 40;
export const TECNOLOGIA = 50;
export const MATERIALES = 60;
export const BIENES_RAICES = 70;
export const TELECOMUNICAICONES = 80;
export const UTILIDADES = 90;
export const CONSUMO_DISCRECIONAL = 100;
export const PRODUCTOS_BASICOS = 110;
export const MARKETING_PUBLICIDAD = 120;
export const MEDICINA = 130;
export const EDUCACION_BASICA = 140;
export const EDUCACION_MEDIA = 150;
export const EDUCACION_AVANZADA = 160;
export const CONTABILIDAD = 170;
export const AEROLINEAS__AVIACION = 180;
export const RESOLUCION_DE_CONFLICTO_ALTERNATIVA = 190;
export const MEDICINA_ALTERNATIVA = 200;
export const ANIMACION = 210;
export const ROPA_Y_MODA = 220;
export const ARQUITECTURA_Y_PLANIFICACION = 230;
export const ARTES_Y_MANUALIDADES = 240;
export const AUTOMOTOR = 250;
export const AVIACION_Y_AEROESPACIAL = 260;
export const BANCARIO = 270;
export const BIOTECNOLOGIA = 280;
export const MEDIOS_DE_DIFUSION = 290;
export const MATERIALES_DE_CONSTRUCCION = 300;
export const ARTICULOS_DE_OFICINA_Y_EQUIPAMIENTO = 310;
export const LOS_MERCADOS_DE_CAPITALES = 320;
export const QUIMICOS = 330;
export const ORGANIZACION_CIVICA_Y_SOCIAL = 340;
export const INGENIERIA_CIVIL = 350;
export const BIENES_RAICES_COMERCIALES = 360;
export const COMPUTADORA_Y_SEGURIDAD_DE_RED = 370;
export const JUEGOS_DE_COMPUTADORA = 380;
export const HARDWARE_DE_LA_COMPUTADORA = 390;
export const REDES_DE_COMPUTADORAS = 400;
export const SOFTWARE_DE_ORDENADOR = 410;
export const CONSTRUCCION = 420;
export const ELECTRONICA_DE_CONSUMO = 430;
export const BIENES_DE_CONSUMO = 440;
export const SERVICIO_AL_CONSUMIDOR = 450;
export const PRODUCTOS_COSMETICOS = 460;
export const LACTEOS = 470;
export const DEFENSA_Y_ESPACIO = 480;
export const DISEÑO = 490;
export const ADMINISTRACION_DE_EDUCACION = 500;
export const E_LEARNING = 510;
export const FABRICACION_ELECTRICA__ELECTRONICA = 520;
export const ENTRETENIMIENTO = 530;
export const SERVICIOS_AMBIENTALES = 540;
export const SERVICIOS_DE_EVENTOS = 550;
export const OFICINA_EJECUTIVA = 560;
export const SERVICIOS_DE_INSTALACIONES = 570;
export const AGRICULTURA = 580;
export const SERVICIOS_FINANCIEROS = 590;
export const ARTE_FINO = 600;
export const PESQUERIA = 610;
export const ALIMENTOS_Y_BEBIDAS = 620;
export const PRODUCCION_DE_ALIMENTOS = 630;
export const RECAUDACION_DE_FONDOS = 640;
export const MUEBLE = 650;
export const JUEGO_Y_CASINOS = 660;
export const VIDRIO_CERAMICA_Y_HORMIGON = 670;
export const ADMINISTRACION_GUBERNAMENTAL = 680;
export const RELACIONES_GUBERNAMENTALES = 690;
export const DISEÑO_GRAFICO = 700;
export const SALUD_BIENESTAR_Y_FITNESS = 710;
export const HOSPITAL_Y_CUIDADO_DE_LA_SALUD = 720;
export const HOSPITALIDAD = 730;
export const RECURSOS_HUMANOS = 740;
export const IMPORTAR_Y_EXPORTAR = 750;
export const SERVICIOS_INDIVIDUALES_Y_FAMILIARES = 760;
export const AUTOMATIZACION_INDUSTRIAL = 770;
export const SERVICIOS_DE_INFORMACION = 780;
export const TECNOLOGIA_DE_LA_INFORMACION_Y_SERVICIOS = 790;
export const SEGURO = 800;
export const ASUNTOS_INTERNACIONALES = 810;
export const COMERCIO_INTERNACIONAL_Y_DESARROLLO = 820;
export const INTERNET = 830;
export const BANCA_DE_INVERSION = 840;
export const GESTION_DE_INVERSIONES = 850;
export const JUDICIAL = 860;
export const CUMPLIMIENTO_DE_LA_LEY = 870;
export const PRACTICACION_DE_LA_LEY = 880;
export const SERVICIOS_JURIDICOS = 890;
export const OFICINA_LEGISLATIVA = 900;
export const OCIO_VIAJES_Y_TURISMO = 910;
export const BIBLIOTECAS = 920;
export const LOGISTICA_Y_CADENA_DE_SUMINISTRO = 930;
export const ARTICULOS_DE_LUJO_Y_JOYAS = 940;
export const MAQUINARIA = 950;
export const CONSULTORIA_DE_GESTION = 960;
export const MARITIMO = 970;
export const INVESTIGACION_DE_MERCADO = 980;
export const MARKETING_Y_PUBLICIDAD = 990;
export const DESARROLLO_DEL_PROGRAMA = 1000;
export const POLITICA_PUBLICA = 1010;
export const RELACIONES_PUBLICAS_Y_COMUNICACIONES = 1020;
export const SEGURIDAD_PUBLICA = 1030;
export const PUBLICACION = 1040;
export const FABRICACION_DE_RALLROAD = 1050;
export const RANCHO = 1060;
export const INSTALACIONES_Y_SERVICIOS_RECREATIVOS = 1070;
export const INSTITUCIONES_RELIGIOSAS = 1080;
export const RENOVABLES_Y_MEDIO_AMBIENTE = 1090;
export const INVESTIGACION = 1100;
export const RESTAURANTES = 1110;
export const VENTA_MINORISTA = 1120;
export const SEGURIDAD_E_INVESTIGACIONES = 1130;
export const SEMICONDUCTORES = 1140;
export const CONSTRUCCION_NAVAL = 1150;
export const ARTICULOS_DEPORTIVOS = 1160;
export const DEPORTES = 1170;
export const PERSONAL_Y_RECLUTAMIENTO = 1180;
export const SUPERMERCADOS = 1190;
export const TELECOMUNICACIONES = 1200;
export const TEXTILES = 1210;
export const TANQUE_DE_PENSAMIENTO = 1220;
export const TABACO = 1230;
export const TRADUCCION_Y_LOCALIZACION = 1240;
export const TRANSPORTE__CAMIONES__FERROCARRIL = 1250;
export const CAPITAL_DE_RIESGO_Y_CAPITAL_PRIVADO = 1260;
export const VETERINARIO = 1270;
export const ALMACENAMIENTO = 1280;
export const VENTA_AL_POR_MAYOR = 1290;
export const VINOS_Y_LICORES = 1300;
export const INALAMBRICA = 1310;
export const ESCRITURA_Y_EDICION = 1320;


export const TIPO_SECTORES = [
    {value: ENERGIA, label: getI18n().t("Energía")},
    {value: FINANZAS, label: getI18n().t("Finanzas")},
    {value: SALUD, label: getI18n().t("Cuidado de la salud")},
    {value: INDUSTRIALES, label: getI18n().t("Acciones industriales")},
    {value: TECNOLOGIA, label: getI18n().t("Tecnologías de la información")},
    {value: MATERIALES, label: getI18n().t("Materiales")},
    {value: BIENES_RAICES, label: getI18n().t("Bienes raices")},
    {value: TELECOMUNICAICONES, label: getI18n().t("Servicios de telecomunicación")},
    {value: UTILIDADES, label: getI18n().t("Utilidades")},
    {value: CONSUMO_DISCRECIONAL, label: getI18n().t("Consumo discrecional (ocio y entretenimiento)")},
    {value: PRODUCTOS_BASICOS, label: getI18n().t("Productos básicos de consumo")},
    {value: MARKETING_PUBLICIDAD, label: getI18n().t("Marketing y publicidad") },
    {value: MEDICINA, label: getI18n().t("Medicina") },
    {value: EDUCACION_BASICA, label: getI18n().t("Educación básica") },
    {value: EDUCACION_MEDIA, label: getI18n().t("Educación media") },
    {value: EDUCACION_AVANZADA, label: getI18n().t("Educación avanzada") },
    {value: CONTABILIDAD, label: getI18n().t("Contabilidad") },
    {value: AEROLINEAS__AVIACION, label: getI18n().t("Aerolíneas / Aviación") },
    {value: RESOLUCION_DE_CONFLICTO_ALTERNATIVA, label: getI18n().t("Resolución de conflicto alternativa") },
    {value: MEDICINA_ALTERNATIVA, label: getI18n().t("Medicina alternativa") },
    {value: ANIMACION, label: getI18n().t("Animación") },
    {value: ROPA_Y_MODA, label: getI18n().t("Ropa y moda") },
    {value: ARQUITECTURA_Y_PLANIFICACION, label: getI18n().t("Arquitectura y planificación") },
    {value: ARTES_Y_MANUALIDADES, label: getI18n().t("Artes y manualidades") },
    {value: AUTOMOTOR, label: getI18n().t("Automotor") },
    {value: AVIACION_Y_AEROESPACIAL, label: getI18n().t("Aviación y aeroespacial") },
    {value: BANCARIO, label: getI18n().t("Bancario") },
    {value: BIOTECNOLOGIA, label: getI18n().t("Biotecnología") },
    {value: MEDIOS_DE_DIFUSION, label: getI18n().t("Medios de difusión") },
    {value: MATERIALES_DE_CONSTRUCCION, label: getI18n().t("Materiales de construcción") },
    {value: ARTICULOS_DE_OFICINA_Y_EQUIPAMIENTO, label: getI18n().t("Artículos de oficina y equipamiento") },
    {value: LOS_MERCADOS_DE_CAPITALES, label: getI18n().t("Los mercados de capitales") },
    {value: QUIMICOS, label: getI18n().t("Químicos") },
    {value: ORGANIZACION_CIVICA_Y_SOCIAL, label: getI18n().t("Organización cívica y social") },
    {value: INGENIERIA_CIVIL, label: getI18n().t("Ingeniería civil") },
    {value: BIENES_RAICES_COMERCIALES, label: getI18n().t("Bienes raíces comerciales") },
    {value: COMPUTADORA_Y_SEGURIDAD_DE_RED, label: getI18n().t("Computadora y seguridad de red") },
    {value: JUEGOS_DE_COMPUTADORA, label: getI18n().t("Juegos de computadora") },
    {value: HARDWARE_DE_LA_COMPUTADORA, label: getI18n().t("Hardware de la computadora") },
    {value: REDES_DE_COMPUTADORAS, label: getI18n().t("Redes de computadoras") },
    {value: SOFTWARE_DE_ORDENADOR, label: getI18n().t("Software de ordenador") },
    {value: CONSTRUCCION, label: getI18n().t("Construcción") },
    {value: ELECTRONICA_DE_CONSUMO, label: getI18n().t("Electrónica de consumo") },
    {value: BIENES_DE_CONSUMO, label: getI18n().t("Bienes de consumo") },
    {value: SERVICIO_AL_CONSUMIDOR, label: getI18n().t("Servicio al consumidor") },
    {value: PRODUCTOS_COSMETICOS, label: getI18n().t("Productos cosméticos") },
    {value: LACTEOS, label: getI18n().t("Lácteos") },
    {value: DEFENSA_Y_ESPACIO, label: getI18n().t("Defensa y Espacio") },
    {value: DISEÑO, label: getI18n().t("Diseño") },
    {value: ADMINISTRACION_DE_EDUCACION, label: getI18n().t("Administración de educación") },
    {value: E_LEARNING, label: getI18n().t("E-learning") },
    {value: FABRICACION_ELECTRICA__ELECTRONICA, label: getI18n().t("Fabricación eléctrica / electrónica") },
    {value: ENTRETENIMIENTO, label: getI18n().t("Entretenimiento") },
    {value: SERVICIOS_AMBIENTALES, label: getI18n().t("Servicios ambientales") },
    {value: SERVICIOS_DE_EVENTOS, label: getI18n().t("Servicios de Eventos") },
    {value: OFICINA_EJECUTIVA, label: getI18n().t("Oficina Ejecutiva") },
    {value: SERVICIOS_DE_INSTALACIONES, label: getI18n().t("Servicios de instalaciones") },
    {value: AGRICULTURA, label: getI18n().t("Agricultura") },
    {value: SERVICIOS_FINANCIEROS, label: getI18n().t("Servicios financieros") },
    {value: ARTE_FINO, label: getI18n().t("Arte fino") },
    {value: PESQUERIA, label: getI18n().t("Pesquería") },
    {value: ALIMENTOS_Y_BEBIDAS, label: getI18n().t("Alimentos y bebidas") },
    {value: PRODUCCION_DE_ALIMENTOS, label: getI18n().t("Producción de alimentos") },
    {value: RECAUDACION_DE_FONDOS, label: getI18n().t("Recaudación de fondos") },
    {value: MUEBLE, label: getI18n().t("Mueble") },
    {value: JUEGO_Y_CASINOS, label: getI18n().t("Juego y Casinos") },
    {value: VIDRIO_CERAMICA_Y_HORMIGON, label: getI18n().t("Vidrio, cerámica y hormigón") },
    {value: ADMINISTRACION_GUBERNAMENTAL, label: getI18n().t("Administración gubernamental") },
    {value: RELACIONES_GUBERNAMENTALES, label: getI18n().t("Relaciones Gubernamentales") },
    {value: DISEÑO_GRAFICO, label: getI18n().t("Diseño gráfico") },
    {value: SALUD_BIENESTAR_Y_FITNESS, label: getI18n().t("Salud, bienestar y fitness") },
    {value: HOSPITAL_Y_CUIDADO_DE_LA_SALUD, label: getI18n().t("Hospital y cuidado de la salud") },
    {value: HOSPITALIDAD, label: getI18n().t("Hospitalidad") },
    {value: RECURSOS_HUMANOS, label: getI18n().t("Recursos humanos") },
    {value: IMPORTAR_Y_EXPORTAR, label: getI18n().t("Importar y exportar") },
    {value: SERVICIOS_INDIVIDUALES_Y_FAMILIARES, label: getI18n().t("Servicios individuales y familiares") },
    {value: AUTOMATIZACION_INDUSTRIAL, label: getI18n().t("Automatización industrial") },
    {value: SERVICIOS_DE_INFORMACION, label: getI18n().t("Servicios de información") },
    {value: TECNOLOGIA_DE_LA_INFORMACION_Y_SERVICIOS, label: getI18n().t("Tecnología de la Información y Servicios") },
    {value: SEGURO, label: getI18n().t("Seguro") },
    {value: ASUNTOS_INTERNACIONALES, label: getI18n().t("Asuntos Internacionales") },
    {value: COMERCIO_INTERNACIONAL_Y_DESARROLLO, label: getI18n().t("Comercio internacional y desarrollo") },
    {value: INTERNET, label: getI18n().t("Internet") },
    {value: BANCA_DE_INVERSION, label: getI18n().t("Banca de inversión") },
    {value: GESTION_DE_INVERSIONES, label: getI18n().t("Gestión de inversiones") },
    {value: JUDICIAL, label: getI18n().t("Judicial") },
    {value: CUMPLIMIENTO_DE_LA_LEY, label: getI18n().t("Cumplimiento de la ley") },
    {value: PRACTICACION_DE_LA_LEY, label: getI18n().t("Practicación de la ley") },
    {value: SERVICIOS_JURIDICOS, label: getI18n().t("Servicios jurídicos") },
    {value: OFICINA_LEGISLATIVA, label: getI18n().t("Oficina legislativa") },
    {value: OCIO_VIAJES_Y_TURISMO, label: getI18n().t("Ocio, Viajes y Turismo") },
    {value: BIBLIOTECAS, label: getI18n().t("Bibliotecas") },
    {value: LOGISTICA_Y_CADENA_DE_SUMINISTRO, label: getI18n().t("Logística y cadena de suministro") },
    {value: ARTICULOS_DE_LUJO_Y_JOYAS, label: getI18n().t("Artículos de lujo y joyas") },
    {value: MAQUINARIA, label: getI18n().t("Maquinaria") },
    {value: CONSULTORIA_DE_GESTION, label: getI18n().t("Consultoría de gestión") },
    {value: MARITIMO, label: getI18n().t("Marítimo") },
    {value: INVESTIGACION_DE_MERCADO, label: getI18n().t("Investigación de mercado") },
    {value: MARKETING_Y_PUBLICIDAD, label: getI18n().t("Marketing y publicidad") },
    {value: DESARROLLO_DEL_PROGRAMA, label: getI18n().t("Desarrollo del programa") },
    {value: POLITICA_PUBLICA, label: getI18n().t("Política pública") },
    {value: RELACIONES_PUBLICAS_Y_COMUNICACIONES, label: getI18n().t("Relaciones públicas y comunicaciones") },
    {value: SEGURIDAD_PUBLICA, label: getI18n().t("Seguridad Pública") },
    {value: PUBLICACION, label: getI18n().t("Publicación") },
    {value: FABRICACION_DE_RALLROAD, label: getI18n().t("Fabricación de Rallroad") },
    {value: RANCHO, label: getI18n().t("Rancho") },
    {value: INSTALACIONES_Y_SERVICIOS_RECREATIVOS, label: getI18n().t("Instalaciones y servicios recreativos") },
    {value: INSTITUCIONES_RELIGIOSAS, label: getI18n().t("Instituciones religiosas") },
    {value: RENOVABLES_Y_MEDIO_AMBIENTE, label: getI18n().t("Renovables y Medio Ambiente") },
    {value: INVESTIGACION, label: getI18n().t("Investigación") },
    {value: RESTAURANTES, label: getI18n().t("Restaurantes") },
    {value: VENTA_MINORISTA, label: getI18n().t("Venta minorista") },
    {value: SEGURIDAD_E_INVESTIGACIONES, label: getI18n().t("Seguridad e investigaciones") },
    {value: SEMICONDUCTORES, label: getI18n().t("Semiconductores") },
    {value: CONSTRUCCION_NAVAL, label: getI18n().t("Construcción naval") },
    {value: ARTICULOS_DEPORTIVOS, label: getI18n().t("Artículos deportivos") },
    {value: DEPORTES, label: getI18n().t("Deportes") },
    {value: PERSONAL_Y_RECLUTAMIENTO, label: getI18n().t("Personal y reclutamiento") },
    {value: SUPERMERCADOS, label: getI18n().t("Supermercados") },
    {value: TELECOMUNICACIONES, label: getI18n().t("Telecomunicaciones") },
    {value: TEXTILES, label: getI18n().t("Textiles") },
    {value: TANQUE_DE_PENSAMIENTO, label: getI18n().t("Tanque de pensamiento") },
    {value: TABACO, label: getI18n().t("Tabaco") },
    {value: TRADUCCION_Y_LOCALIZACION, label: getI18n().t("Traducción y localización") },
    {value: TRANSPORTE__CAMIONES__FERROCARRIL, label: getI18n().t("Transporte / Camiones / Ferrocarril") },
    {value: CAPITAL_DE_RIESGO_Y_CAPITAL_PRIVADO, label: getI18n().t("Capital de riesgo y capital privado") },
    {value: VETERINARIO, label: getI18n().t("Veterinario") },
    {value: ALMACENAMIENTO, label: getI18n().t("Almacenamiento") },
    {value: VENTA_AL_POR_MAYOR, label: getI18n().t("Venta al por mayor") },
    {value: VINOS_Y_LICORES, label: getI18n().t("Vinos y licores") },
    {value: INALAMBRICA, label: getI18n().t("Inalámbrica") },
    {value: ESCRITURA_Y_EDICION, label: getI18n().t("Escritura y edición") },
];

//CONSTANTES LEYENDA REPORTE RETROALIMENTACIÓN
export const LEGEND =  [
    {
        pregunta: "#1",
        titulos: [getI18n().t("Muy ineficaz"), getI18n().t("Ineficaz"), getI18n().t("Moderadamente eficaz"), getI18n().t("Eficaz"), getI18n().t("Muy eficaz")],
        valores: ["1", "2", "3", "4", "5"]
    },
    {
        pregunta: "#2",
        titulos: [getI18n().t("Muy irrelevante"), getI18n().t("Irrelevante"), getI18n().t("Moderadamente relevante"), getI18n().t("Relevante"), getI18n().t("Muy relevante")],
        valores: ["1", "2", "3", "4", "5"]
    },
    {
        pregunta: "#3",
        titulos: [getI18n().t("Muy mal organizado"), getI18n().t("Mal organizado"), getI18n().t("Moderadamente organizado"), getI18n().t("Bien organizado"), getI18n().t("Muy bien organizado")],
        valores: ["1", "2", "3", "4", "5"]
    },
];

// CONSTANTES DE IDIOMAS PARA TRADUCCIÓN

export const IDIOMAS_SISTEMA = [
    { key:'en' , name:'English' },
    { key:'es' , name:'Español' },
    { key:'pt' , name:'Português' },
    { key:'fr', name:'Français'},
]

// TIPOS DE PREWORKSHOP
export const PREWORKSHOP_FINANZAS = 10;
export const PREWORKSHOP_MARKETING = 20;


// Respuestas examen de nivelacion
export const BASICO = 0;
export const INTERMEDIO = 1;
export const EXPERTO = 2;


// Calificacion de talleres
export const MUY_INEFICAZ = 1
export const INEFICAZ = 2
export const MODERADAMENTE_EFICAZ = 3
export const EFICAZ = 4
export const MUY_EFICAZ = 5

export const URL_PUBLIC = 'http://localhost:8080/#/'

const prod = {
    url: {
        URL_PUBLIC: 'https://app.growmotor.io/#/',
    
   }
}

const dev = {
    url: {
        URL_PUBLIC: 'http://localhost:8080/#/'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;