import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Toolbar extends Component {
    render() {
        const { to, textBtn, regresar } = this.props;

        return (
            <div className="d-flex flex-1 row px-3">
                <div className="col-md-4 col-6 px-0">
                    {regresar && (
                        <Link to={regresar} className="btn btn-secondary mb-3 mr-2">{this.props.t('Regresar')}</Link>
                    )}
                    {(to !== undefined) && (
                        <Link to={to} className="btn btn-primary mb-3">{textBtn}</Link>
                    )}
                </div>
                {/* mas botones */}
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
            </div>
        )
    }
}

export default withTranslation()(Toolbar)
