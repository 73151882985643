import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/talleres';
import CrearTaller from './CrearTaller';

const ms2p = (state) => {
    return {
        ...state.talleres,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearTaller);
