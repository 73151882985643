import React, { Component } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { IDIOMAS_SISTEMA } from "../../../../utility/constants";
import { Link } from "react-router-dom";

import '../../../../../style/components/header.css';
const Logo = require('assets/header/Logo.png');
const Logo2 = require('assets/header/Logo2.png');

import { withTranslation } from "react-i18next";

const menu = (changeLanguage) => {
    return (
        <Menu>
            {IDIOMAS_SISTEMA.map((item) => (
                <Menu.Item key={item.key} onClick={(e)=>changeLanguage(item)}>{item.name}</Menu.Item>
            ))}
        </Menu>
    );
};

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            idioma: "English",
            key: "en",
        };
    }
    componentDidMount=()=>{
        const { me } = this.props;
        if(me&&me.idioma){
            const idioma = IDIOMAS_SISTEMA.find(item=>item.key===me.idioma);
            if(idioma) this.changeLanguage(idioma);
        }
    }
    changeLanguage(item) {
        const { i18n, cambiarIdioma  } = this.props;
        if(item){
            i18n.changeLanguage(item.key);
            this.setState({ idioma: item.name, key: item.key });
            cambiarIdioma({'idioma':item.key});
        }
    }

    toggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    };

    render() {
        const { navToggle, logOut, title, subtitle } = this.props;
        const url = window.location.href;
        return (
            <nav className="header__container">
                <div className="header__logo" >
                    <Link to='/' >
                        <img className="d-none d-lg-block" src={Logo}></img>
                    </Link>
                    <img onClick={navToggle} className="d-block d-lg-none logo-responsivo" src={Logo2}></img>
                </div>
                <div className="header__search d-none d-lg-flex flex-3">
                    <span className="text-md ml-4 blanco regular">{title} <span className="text-md celeste-oscuro regular">{subtitle}</span></span>
                </div>
                <div className="header__side flex-2 mr-3 mr-md-5 justify-content-end">
                    <div className="d-flex flex-row align-items-center justify-content-end">
                        <Link title={this.props.t("Notificaciones")} className="d-flex d-md-flex mr-lg-4 mx-sm-3 ml-sm-3" to="/notificaciones">
                            <i style={{fontSize: "20px"}} className={`fas fa-bell ${url.includes("notificaciones") ? 'marron-claro' : 'blanco'}`}/>
                        </Link>
                        <Link title={this.props.t("Perfil")} className="d-none d-md-flex d-md-flex mr-lg-4 mr-sm-3" to="/perfil">
                            <i style={{fontSize: "20px"}} className={`fas fa-user ${url.includes("perfil") ? 'marron-claro' : 'blanco'}`}/>
                        </Link>
                        <Dropdown
                            overlay={menu(this.changeLanguage.bind(this))}
                            className="d-none d-md-block"
                            trigger={["hover"]}
                        >
                            <a
                                className="ant-dropdown-link"
                                onClick={(e) => e.preventDefault()}
                            >
                                {this.state.idioma} <DownOutlined />
                            </a>
                        </Dropdown>
                        <Dropdown
                            overlay={menu(this.changeLanguage.bind(this))}
                            className="d-block d-md-none ml-3"
                            trigger={["hover"]}
                        >
                            <a
                                className="ant-dropdown-link"
                                style={{textTransform: "uppercase"}}
                                onClick={(e) => e.preventDefault()}
                            >
                                {this.state.key} <DownOutlined />
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withTranslation()(Navbar);
