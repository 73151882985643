import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Card from '../../../Utils/Cards/Card';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import { withTranslation } from 'react-i18next';

import moment from 'moment';


class DetalleAviso extends Component {
    componentDidMount=()=>{
        const id = this.props.match.params.id;
        const { leer, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            leer(id);
        }
    }

    render() {
        const { loader, item } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-md-row flex-1 px-3 px-md-5 py-3">
                            {(item.empresa) && (
                                <div className="d-flex flex-column justify-content-center align-items-center flex-1">
                                    <img style={{ width: "2.5rem", height: "2.5rem" }} src={item.empresa.logo} className="logo-tabla" />
                                </div>
                            )}
                            {(item.empresa) && (
                                <div className="d-flex flex-column flex-2 mt-2 mt-md-0">
                                    <span className="text-sm gris-oscuro">{this.props.t('Empresa')}</span>
                                    <span className="text-sm celeste-oscuro">{item.empresa ? item.empresa.nombre : ''}</span>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-2 mt-2 mt-md-0">
                                <span className="text-sm gris-oscuro">{this.props.t('País')}</span>
                                <span className="text-sm celeste-oscuro">{item.pais ? item.pais : ''}</span>
                            </div>
                            {(item.cohorte) && (
                                <div className="d-flex flex-column flex-2 mt-2 mt-md-0">
                                    <span className="text-sm gris-oscuro">{this.props.t('Cohorte')}</span>
                                    <span className="text-sm celeste-oscuro">{item.cohorte ? item.cohorte.nombre : ''}</span>
                                </div>
                            )}
                            <div className="d-flex flex-column flex-2 mt-2 mt-md-0">
                                <span className="text-sm gris-oscuro">{this.props.t('Fecha')}</span>
                                <span className="text-sm celeste-oscuro">{moment(item.creado).format()}</span>
                            </div>
                        </div>
                    </Card>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-1 px-3 px-md-5 py-3">
                            <div className="d-flex flex-column flex-1 my-2">
                                <span className="text-sm bold azul-gris-oscuro">{this.props.t('Cuerpo del mensaje')}</span>
                                <p className="text-sm gris-oscuro">{item.cuerpo_mensaje}</p>
                            </div>
                            <div className="d-flex flex-1 borde-bottom pb-1">
                                <span className="text-sm bold azul-gris-oscuro">{this.props.t('Personas')}</span>
                            </div>
                            <div className="w-100 my-2">
                                <Grid hover striped data={{ count: 4, results: item.enviados ? item.enviados : [] }} loading={false} pagination={false} >
                                    <TableHeaderColumn
                                        isKey
                                        dataField="id"
                                        dataFormat={cell => ''}
                                        width="5%"
                                    />
                                    <TableHeaderColumn
                                        dataField="usuario"
                                        dataFormat={cell => cell ? cell.nombres : ''}
                                    >
                                        {this.props.t('Nombre')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="usuario"
                                        dataFormat={cell => cell ? cell.email : ''}
                                    >
                                        {this.props.t('Correo')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="usuario"
                                        dataFormat={cell => cell ? cell.empresa_nombre : ''}
                                    >
                                        {this.props.t('Empresa')}
                                    </TableHeaderColumn>
                                </Grid>
                            </div>
                            <div className="d-flex flex-row justify-content-center flex-1 my-2">
                                <Link to="/avisos_generales" className="btn btn-secondary">{this.props.t('Regresar')}</Link>
                            </div>
                        </div>
                    </Card>
                </LoadMask>
            </div>
        )
    }
}

export default withTranslation()(DetalleAviso)
