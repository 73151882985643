import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { TALLER } from '../../../utility/constants';
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_EVALUACIONES';
const DATA = 'DATA_EVALUACIONES';
const ITEM_DATA = 'ITEM_EVALUACIONES';
const ITEM_TALLER = 'ITEM_TALLER_EVALUACIONES';
const PAGE = 'PAGE_EVALUACIONES';
const ORDERING = 'ORDERING_EVALUACIONES';
const SEARCH = 'SEARCH_EVALUACIONES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = (item, type=ITEM_DATA) => ({
    type,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (id, page = 1) => (dispatch, getStore) => {
    const resource = getStore().evaluaciones;
    const params = { page };
    params.publicacion = id;
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('evaluacion', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`evaluacion/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EvaluacionForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerTaller = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`evento/${id}`, {sencillo: true}).then((response) => {
        dispatch(setItem(response, ITEM_TALLER));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const reutilizarEvaluacion = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`evento/obtener_evaluacion`, {id}).then((response) => {
        dispatch(initializeForm('EvaluacionForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (idTaller, data, url) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('evaluacion', data).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(push(`/${url}/${idTaller}/evaluaciones`));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (idTaller, id, data, url) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evaluacion/${id}`, data).then(() => {
        NotificationManager.success(getI18n().t('Registro actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(push(`/${url}/${idTaller}/evaluaciones`));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = (idTaller, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`evaluacion/${id}`).then(() => {
        dispatch(listar(idTaller));
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = (id, search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar(id));
};

const onSortChange = (id, ordering) => (dispatch, getStore) => {
    const sort = getStore().evaluaciones.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar(id));
};

const activarEvaluacion = (id, idTaller) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evaluacion/${id}/activar_evaluacion`, {}).then(() => {
        NotificationManager.success(getI18n().t('Se ha activado la evaluación'), getI18n().t('Éxito'), 3000);
        dispatch(listar(idTaller));
        dispatch(leerTaller(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al activar la evaluación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const activarEvaluaciones = (idTaller) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`evaluacion/activar_evaluaciones`, {taller: idTaller}).then(() => {
        NotificationManager.success(getI18n().t('Se han activado todas las evaluaciones'), getI18n().t('Éxito'), 3000);
        dispatch(listar(idTaller));
        dispatch(leerTaller(idTaller));
    }).catch((error) => {
        let mensaje = getI18n().t('Error al activar las evaluacines');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    listar,
    leer,
    leerTaller,
    reutilizarEvaluacion,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    activarEvaluacion,
    activarEvaluaciones,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM_TALLER]: (state, { item }) => {
        return {
            ...state,
            taller: item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    taller: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
