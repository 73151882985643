import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/cuenta/perfil';
import VerPerfil from './VerPerfil';

const ms2p = (state) => {
    return {
        ...state.perfil,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VerPerfil);
