import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { AsyncSelectField, renderDatePicker3, renderField, renderTextArea, renderFieldCheck, renderTimeField, renderFieldRadio, renderEditorField } from '../../../Utils/renderField/renderField';
import { FAMILY_MEETING, PREWORKSHOP_FINANZAS, PREWORKSHOP_MARKETING, ADMIN } from '../../../../../utility/constants';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";
import ArchivosUploader from '../../../Utils/ArchivoUploader';

const getCohortes = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


const validators = (values, props)=>{
    const errors = {}

    if(!values.cohorte){
        errors.cohorte = `${props.t('Campo requerido')}`;
    }
    if(values.cohorte&&values.profesores){
        const errorsProfesores = []
        _.forEach(values.profesores,(profesor)=>{
            if(profesor.profesor){
                const existe = _.find(values.cohorte.growth_angels,(item)=>{
                    return item.id===profesor.profesor.id;
                })
                const index  = _.findIndex(values.profesores, (item)=> item===profesor)
                if(existe===undefined&&profesor.profesor.tipo_usuario!==ADMIN){
                    const errorProfesor = {}
                    errorProfesor.profesor = `${props.t('Este growth angel no esta en la cohorte')}`;
                    errorsProfesores[index]= errorProfesor
                    //props.removerGrowthAngel(profesor.profesor);
                }
            }
        })
        values.profesores.forEach((profesor, index)=>{
            const error = {}
            if(!profesor||!profesor.profesor){
                error.profesor = `${props.t('Campo requerido')}`;
                errorsProfesores[index]= error
            }
        })

        errors.profesores = errorsProfesores
    }
    if(!values.titulo){
        errors.titulo = `${props.t('Campo requerido')}`;
    }
    if(!values.fecha_inicio){
        errors.fecha_inicio = `${props.t('Campo requerido')}`;
    }
    if(!values.hora_inicio){
        errors.hora_inicio = `${props.t('Campo requerido')}`;
    }
    if(!values.hora_fin){
        errors.hora_fin = `${props.t('Campo requerido')}`;
    }
    if(!values.descripcion){
        errors.descripcion = `${props.t('Campo requerido')}`;
    }
    if(values.hora_inicio&&values.hora_fin){
        if(values.hora_inicio>=values.hora_fin){
            errors.hora_fin = `${props.t('Hora fin debe ser mayor a hora inicio')}`;
        }
    }
    if(values.fecha_inicio && values.hora_inicio){
        const now = moment();
        const fecha_inicio = moment(`${moment(values.fecha_inicio).format("YYYY-MM-DD")} ${values.hora_inicio}`);
        if (props.item && props.item.fecha_inicio){
            const item_fecha_inicio = moment(props.item.fecha_inicio);
            if (fecha_inicio.format('YYYY-MM-DD') !== item_fecha_inicio .format('YYYY-MM-DD') && fecha_inicio.format('YYYY-MM-DD') < now.format('YYYY-MM-DD')){
                errors.fecha_inicio = `${props.t('Tiene que ser una fecha futura')}`;
            }
        }else{
            if(fecha_inicio.format('YYYY-MM-DD') < now.format('YYYY-MM-DD')){
                errors.fecha_inicio = `${props.t('Tiene que ser una fecha futura')}`;
            }
        }
    }
    return errors;
}

const renderGrowthAngels = ({ fields, t, getGrowthAngel, cohorte, meta: { error } }) => (
    <div className="row col-12 px-0">
        <div className="col-12 px-0">
            {fields.map((item, index) => {
                return (
                    <div key={`key-${index} ${cohorte?cohorte.id:''}`}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor={`${item}.profesor`} className="m-0">{`Growth Angel No.${index + 1}`}</label>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-10">
                                <Field
                                    name={`${item}.profesor`}
                                    loadOptions={getGrowthAngel}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="nombres"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-2 d-flex align-items-center px-0">
                                <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>
                                    <i className="fas fa-times marron" />
                                </button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-md-4 col-12 form-group mt-sm-3 mt-0">
            <button type="button" className="btn btn-secondary-fill btn-block" onClick={() => fields.push({})}>{t('Agregar Growth Angel')}</button>
        </div>
    </div>
);

let PasoUnoForm = props => {
    const { handleSubmit, pre_workshop, cohorte, archivos } = props;

    const getGrowthAngel = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        if (cohorte&&cohorte.id){
            params.cohorte = cohorte.id;
        }
        return api.get("growth_angels/obtener_growth_angels", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="cohorte" className="m-0">{props.t('Cohorte')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field
                            name="cohorte"
                            loadOptions={getCohortes}
                            type="text"
                            component={AsyncSelectField}
                            valueKey="id"
                            labelKey="nombre"
                            className="form-control"
                        />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="titulo" className="m-0">{props.t('Nombre de la reunión familiar')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="titulo" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column align-items-start justify-content-center flex-1 p-2">
                        <label htmlFor="fecha_inicio" className="m-0">{props.t('Zona horaria de la cohorte')}</label>
                        <span className="text-sm bold celeste-oscuro pl-1">{cohorte ? cohorte.timezone : '------'}</span>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_inicio" className="m-0">{props.t('Fecha de la reunión familiar')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="fecha_inicio" onCambio={props.cambiarFechaDrill} component={renderDatePicker3} noPasados={true}/>
                    </div>

                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="hora_inicio" className="m-0">{props.t('Hora inicio')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="hora_inicio" onCambio={props.cambiarFechaDrill} component={renderTimeField} />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="hora_fin" className="m-0">{props.t('Hora fin')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="hora_fin" component={renderTimeField} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="pre_workshop" className="m-0 mb-2">{props.t('Activar evento tipo pre-workshop')}</label>
                        <Field
                            component={renderFieldCheck}
                            className="form-control"
                            name="pre_workshop"
                            label={props.t('Sí')}
                        />
                    </div>
                    {(pre_workshop) ? (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="pre_workshop" className="m-0 mb-2">{props.t('Tipo de pre-workshop')}</label>
                            <div className="d-flex flex-row flex-1">
                                <Field
                                    type="radio"
                                    name="tipo_preworkshop"
                                    label={props.t('Finanzas')}
                                    parse={val => +val}
                                    value={PREWORKSHOP_FINANZAS}
                                    component={renderFieldRadio}
                                />
                                <Field
                                    type="radio"
                                    name="tipo_preworkshop"
                                    label={props.t('Marketing')}
                                    parse={val => +val}
                                    value={PREWORKSHOP_MARKETING}
                                    component={renderFieldRadio}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex flex-column flex-1 p-2"></div>
                    )}
                    <div className="d-flex flex-column justify-content-end flex-1 p-2">
                        <label htmlFor="titulo" className="m-0">{props.t('Realizar encuesta')}</label>
                        <Field
                            component={renderFieldCheck}
                            className="form-control"
                            name="calificar_evento"
                            label={props.t('Sí')}
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="descripcion" className="m-0">{props.t('Descripción')}</label>
                        <Field name="descripcion" component={renderEditorField} type="text" className="form-control" />
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos del evento')}</span>
                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-3 p-2">
                        <ArchivosUploader
                            archivos={archivos}
                            upload={props.setArchivos}
                            eliminar={props.eliminarArchivo}
                            btnLabel={props.t('Cargar archivos')}
                        />
                    </div>
                    <div className="flex-2"></div>
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <span className="text-sm azul-gris-oscuro bold">{props.t('Growth Angels')}</span>
                    <div className="d-flex w-100 bg-gris" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 mt-2">
                        <FieldArray
                            name="profesores"
                            component={renderGrowthAngels}
                            getGrowthAngel={getGrowthAngel}
                            cohorte={cohorte}
                            t={props.t} />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/family_meetings">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary ml-3">{props.t('Siguiente')}</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/family_meetings">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')}</button>
                </div>
            </div>
        </form>
    )

};

const selector = formValueSelector('FamilyMeetingForm');

PasoUnoForm = reduxForm({
    form: 'FamilyMeetingForm',
    asyncBlurFields: [],
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        tiene_drill: true,
        pre_workshop: false,
        tipo_publicacion: FAMILY_MEETING,
        tipo_preworkshop: PREWORKSHOP_FINANZAS,
        limite_participantes: 0,
        calificar_evento: true,
    },
    validate: validators,
})(PasoUnoForm);

const mstp = state => {
    const pre_workshop = selector(state, 'pre_workshop');
    const cohorte = selector(state, 'cohorte');
    let archivos = selector(state, 'archivos');
    archivos = archivos ? archivos : [];

    return {
        pre_workshop,
        cohorte,
        archivos,
    }
};

export default connect(mstp, null)(PasoUnoForm);
