import React from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from 'react-router-dom';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import Form from "./CambioPwdForm";
import { Link } from 'react-router-dom';

const fondo_login = require('../../../../../assets/img/fondo_login.png')
const fondo_cover = require('../../../../../assets/img/cover_contrasena.png')
const logo_contrasena = require('../../../../../assets/img/contraseña.png')
const logo_correo = require('../../../../../assets/img/correo.png')

class RecuperarPwd extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            email:'',
            cambioPwd: false,
        }
    }

    componentDidMount =()=>{
        const { match } = this.props;
        if(match.params.id){
            this.props.verificarToken(match.params.id).then((res)=>{
                this.setState({cambioPwd:true});
                this.setState({email:res.email});
            })
        }
    }
    onSubmit =(values)=>{
        if(!this.state.cambioPwd){
            this.props.SolicitarPwd(values.email);
        }else{
            if(this.state.email&&values.password){
                this.props.cambiarPwd(this.state.email,values.password);

            }
            
        }
    }
    render(){
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return(
            <div className="login-container" style={{backgroundImage: `url(${fondo_login})`}}>
                <div className="login-panel">
                    <div className="login-card-form">
                        <div className="d-flex flex-row px-md-5 px-3 align-items-center mt-2 mt-md-4">
                            <Link to="/login" ><i className="blanco fas fa-arrow-left mr-2"></i></Link>
                            {
                                !this.state.cambioPwd ?(
                                    <span className="blanco bold text-sm">{this.props.t('Recuperación de contraseña')}</span>
                                ):(
                                    <span className="blanco bold text-sm">{this.props.t('Nueva contraseña')}</span>
                                )
                            }

                        </div>
                        <div className="d-flex flex-column justify-content-center flex-5">
                            <div className="mb-4 px-md-5 px-3 mx-auto mx-md-0">
                                <img src={!this.state.cambioPwd ? logo_correo : logo_contrasena} className="logo-login" style={{width:"10rem", height:"10rem"}} />
                            </div>
                            <div className="w-100 px-md-5 px-3">
                                {
                                    !this.state.cambioPwd ? (
                                        <span className="blanco bold text-sm" >
                                            {this.props.t('Por favor ingresa tu correo electrónico, se te enviara un correo para poder restablecer tu contraseña')}
                                        </span>

                                    ):(
                                        <span className="blanco bold text-sm" >
                                            {this.props.t('Introduce tu nueva contraseña')}
                                        </span>
                                    ) 

                                }

                            </div>
                            <div className="w-100 px-md-5 px-3">
                                <LoadMask loading={this.props.loader} light>
                                    <Form cambioPwd={this.state.cambioPwd} t={this.props.t} onSubmit={this.onSubmit}/>
                                </LoadMask>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center flex-1">
                        </div>
                    </div>
                    <div className="login-card-cover" style={{backgroundImage: `url(${fondo_cover})`}}></div>
                </div>
            </div>

        )
    }
}


export default withTranslation()(RecuperarPwd);