import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../i18nextInit'

import Swal from 'sweetalert2';
import moment from 'moment';

class ListarEvaluaciones extends Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        const { listar, page, leerTaller } = this.props;
        listar(id, page);
        leerTaller(id);
    }

    pageChange = (page) => {
        const id = this.props.match.params.id;
        this.props.listar(id, page);
    }

    todasActivas = () => {
        const { data } = this.props;
        let bandera = true;
        if (data.results.length) {
            data.results.forEach(item => {
                if (!item.visible)
                    bandera = false;
            });
        }
        else {
            bandera = null;
        }

        return bandera;
    }

    searchChange = (search) => {
        const id = this.props.match.params.id;
        this.props.searchChange(id, search);
    }

    onSortChange = (ordering) => {
        const id = this.props.match.params.id;
        this.props.onSortChange(id, ordering);
    }

    onEliminar = (id)=>{
        const idTaller = this.props.match.params.id;
        this.props.eliminar(idTaller, id)
    }

    activarEvaluacion = (id) => {
        Swal.fire({
            title: this.props.t('¿Activar evaluación?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, activar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const idTaller = this.props.match.params.id;
                this.props.activarEvaluacion(id, idTaller);
            }
        });
    }

    activarEvaluaciones = () => {
        Swal.fire({
            title: this.props.t('¿Activar todas las evaluaciones?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, activar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const idTaller = this.props.match.params.id;
                this.props.activarEvaluaciones(idTaller);
            }
        });
    }

    render() {
        const { data, loader, page, taller, me } = this.props;
        const id = this.props.match.params.id;
        const url = window.location.href;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar regresar={url.includes("taller") ? "/talleres" : "/mentorias"} to={`/${url.includes("taller") ? 'taller' : 'mentoria'}/${id}/evaluacion/crear`} textBtn={this.props.t('Crear autoevaluación')} />
                <Card>
                    <div className="d-flex flex-lg-row flex-column justify-content-center align-items-lg-center px-lg-2 mb-3">
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{url.includes("taller") ? this.props.t('Taller') : this.props.t('Mentoría') }:</span>
                            <span className="celeste-oscuro bold text-sm">{taller ? taller.titulo : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('Fecha a realizarse')}:</span>
                            <span className="celeste-oscuro bold text-sm"> 
                                {(me&&me.timezone) ? moment(taller.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY hh:mm a z") : moment(taller.fecha_inicio).format("MMM-DD-YYYY hh:mm a")}
                            </span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="text-sm">{this.props.t('Estado')}:</span>

                            {(taller.todas_activas) ? (
                                <span className="verde bold text-sm">{this.props.t('Activadas')}</span>
                            ) : (
                                this.todasActivas() == false ? (
                                    <button type="button" onClick={this.activarEvaluaciones} className="btn btn-option-one w-50">{this.props.t('Activar')}</button>
                                ) : (
                                    <span className="marron bold text-sm">{this.props.t('Sin evaluaciones')}</span>
                                )
                            )}
                        </div>
                    </div>
                </Card>
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} searchChange={this.searchChange} />
                    </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={this.pageChange} onSortChange={this.onSortChange}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: `/taller/${id}/evaluacion`, eliminar:this.onEliminar})}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            {this.props.t('Titulo')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="visible"
                            dataSort
                            dataFormat={(cell, row)=>{
                                if(cell){
                                    return <span className="verde">{this.props.t('Activado')}</span>
                                }else{
                                    return <button type="button" onClick={() => this.activarEvaluacion(row.id)} className="btn btn-option-one">{this.props.t('Activar')}</button>
                                }
                            }}
                        >
                            {this.props.t('Estado')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(ListarEvaluaciones)
