import React, { Component } from "react";
import NotificationSystem from 'react-notification-system';
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, newToken, cambiarIdioma } from "./redux/modules/cuenta/login";
import { messaging } from "./utility/init-fcm";

// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import Footer from './common/components/layout/Footer/Footer';

import Navbar from "./common/components/layout/Navbar/Navbar";
import { VerifyLogin } from "./common/components/layout";

const info = require('../assets/img/iconos/info.png');
const header = require('../assets/header/1.png');

class PrivateRouteBase extends Component {

    notificationSystem = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    async componentDidMount() {
        // FCM
        let userAgentString = navigator.userAgent;
        try{
            if (window.safari === undefined) {
                console.log("No es safari")
                messaging.requestPermission()
                    .then(() => {
                        this.pushToken(messaging);
                    })
                    .catch(function (err) {
                        console.log("Error al obtener el token.", err);
                    });
                navigator.serviceWorker.addEventListener("message", (message) => {
                    this.handleNotificacion(message);
                });
            } else {
                console.log("Es safari")
            }
        }catch(e){}
    }

    navToggle = () => {
        this.setState({toggleOpen: !this.state.toggleOpen });
    };

    pushToken = async (messaging) => {
        const token = await messaging.getToken();
        this.props.newToken(token);
    };

    handleNotificacion = (message) => {
        console.log('message ', message);
        const { data: { 'firebase-messaging-msg-data': {notification}} } = message;
        // event.preventDefault();
        const notificacion = this.notificationSystem.current;
        notificacion.addNotification({
            children: (
                <div
                    className="h-100 w-100 d-flex"
                    onClick={() => notification.click_action ? window.open(notification.click_action) : null}
                >
                    <div className="notification-icono pr-2">
                        <img src={info} className="push-icono" />
                    </div>
                    <div className="h-100 noti-min-heigth notification-body">
                        <p className="title mb-2">{notification.title}</p>
                        <p className='m-0'>{notification.body}</p>
                    </div>
                </div>
            ),
            level: 'info',
            position: 'tr',
            dismissible: true,
            autoDismiss: 0,
        });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const { getMe, login: { me } } = this.props;
        if (!!token && !!me.email) {
            return true;
        } else if(token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    render() {
        const { component: Component, logOut, title: titulo, subtitle: subtitulo, cambiarIdioma, login: { me }, ...rest } = this.props;
        const isAuthenticated = this.isAuthenticated();
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        (isAuthenticated === true) ? (<div>
                        <div className="sticky-top">
                            <Navbar navToggle={this.navToggle} logOut={logOut} me={me} title={titulo} subtitle={subtitulo} cambiarIdioma={cambiarIdioma} />
                        </div>
                        <div>
                            <NotificationSystem ref={this.notificationSystem} />
                            <div className="navbar__back" style={{backgroundImage: `url(${header})`}}></div>
                            <SiderBar toggleOpen={this.state.toggleOpen} navToggle={this.navToggle} logOut={logOut} me={me} />
                                <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                    <div className="main-center main-content-container pl-lg-4 pr-lg-0 px-0 container-fluid">
                                        <Component {...props} />
                                    </div>
                                </main>
                            </div>
                        </div>) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, getMe, newToken, cambiarIdioma };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(withRouter(PrivateRouteBase));

export default ProtectedRoute;

