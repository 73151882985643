import React, { Component, useState } from 'react';
import CountrySelect from 'react-bootstrap-country-select';
import Select, { Async } from 'react-select';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { COUNTRIES } from '../../../../utility/paises';

import { api } from "api";
import moment from 'moment';


const ToolbarDashboard = ({ changeFirstYear, changeLastYear, changePais, changeCohorte, changeEmpresa, pais, t, first_year, last_year, cohorte, empresa }) => {
    const getCohortes = (search) => {
        return api.get("cohorte/obtener_cohortes", { search })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };
    
    const [refresh, setRefresh] = useState(true)

    const getEmpresas = (search) => {
        const cohortesend = cohorte ? (cohorte.id) : ''

        if (cohorte){
            return api.get("empresa", { search, cohortesend })
                .then((data) => {
                    if (data) return data.results;
                    return [];
                })
                .catch(() => {
                    return [];
                });
        }else{
            return api.get("empresa", { search })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
        }
    };

    return (
        <div className="d-flex flex-1 row px-3 mb-4 mt-2">
            <div className="col-md-3 col-12 px-2 d-flex flex-row align-items-center">
                <DatePicker picker="year" value={moment(first_year)} onChange={changeFirstYear} />
                <span className="text-sm blanco px-2">{t('Hasta')}</span>
                <DatePicker picker="year" value={moment(last_year)} onChange={changeLastYear} />
            </div>
            <div className="col-md-3 col-12 px-2 mt-2 mt-md-0">
                <CountrySelect
                    value={pais}
                    onChange={changePais}
                    placeholder={t('País')}
                />
            </div>
            <div className="col-md-3 col-12 px-2 mt-2 mt-md-0">
                <Async
                    isClearable={true}
                    backspaceRemovesValue={false}
                    value={cohorte}
                    isSearchable={true}
                    loadOptions={getCohortes}
                    getOptionValue={(option) => option["id"]}
                    getOptionLabel={(option) => option["nombre"]}
                    type="text"
                    onChange={(e) => {
                        setRefresh(!refresh);
                        changeCohorte(e);
                    }}
                    multi={false}
                    autoload={false}
                    cache={false}
                    className={classNames(
                        "react-select-container w-100"
                    )}
                    defaultOptions={true}
                    placeholder={t('Cohorte')}
                />
            </div>
            <div className="col-md-3 col-12 px-2 mt-2 mt-md-0">
                {refresh?(
                    <Async
                        isClearable={true}
                        backspaceRemovesValue={false}
                        value={empresa}
                        isSearchable={true}
                        loadOptions={getEmpresas}
                        getOptionValue={(option) => option["id"]}
                        getOptionLabel={(option) => option["nombre"]}
                        type="text"
                        onChange={changeEmpresa}
                        multi={false}
                        autoload={false}
                        cache={false}
                        className={classNames(
                            "react-select-container w-100"
                        )}
                        defaultOptions={true}
                        placeholder={t('Empresa')}
                    />
                ):(
                    <div>
                        <Async
                        isClearable={true}
                        backspaceRemovesValue={false}
                        value={empresa}
                        isSearchable={true}
                        loadOptions={getEmpresas}
                        getOptionValue={(option) => option["id"]}
                        getOptionLabel={(option) => option["nombre"]}
                        type="text"
                        onChange={changeEmpresa}
                        multi={false}
                        autoload={false}
                        cache={false}
                        className={classNames(
                            "react-select-container w-100"
                        )}
                        defaultOptions={true}
                        placeholder={t('Empresa')}
                    />
                    </div>
                )}
                
            </div>
        </div>
    )
  }

export default withTranslation()(ToolbarDashboard)