import React, { Component } from 'react';
import { Link, StaticRouter } from 'react-router-dom';
import { TableHeaderColumn } from "react-bootstrap-table";
import Modal from 'react-responsive-modal';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { withTranslation } from 'react-i18next';
import ModalEmpresa from './ModalEmpresa';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import i18n from '../../../../../i18nextInit'

import Swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';


class SlotsMentoria extends Component {
    state = {
        modal: false,
        idSlot: null
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const { leer } = this.props;
        leer(id, null, true);
    }

    closeModal = () => {
        this.setState({ modal: false, idSlot: null });
    };

    openModal = (idSlot) => {
        this.setState({ modal: true, idSlot });
    };

    getEmpresasAsignar = () => {
        const { empresas, item: { slots } } = this.props;
        const listado = [];
        empresas.forEach( item => {
            const existe = _.find(slots, _item => _item.empresa ? _item.empresa.id == item.empresa.id : false );

            if (!existe)
                listado.push({...item.empresa});
        });
        return listado;
    }

    asignarEmpresa = (values) => {
        const { idSlot } = this.state;
        const id = this.props.match.params.id;
        const body = {...values, slot: idSlot };
        console.log(body)
        this.props.asignarEmpresaSlot(id, body, this.closeModal);
    }

    desasignarEmpresa = (idSlot) => {
        const id = this.props.match.params.id;
        Swal.fire({
            title: this.props.t('¿Desasignar empresa del slot?'),
            text: this.props.t('¡No podrá revertir esta acción!'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: this.props.t('¡Sí, desasignar!'),
            cancelButtonText: this.props.t('No, cancelar'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.desasignarEmpresaSlot(id, idSlot);
            }
        });
    }

    renderModal = () => {
        // const { } = this.state;
        return (
            <Modal
                open={this.state.modal}
                onClose={this.closeModal}
                center
            >
                <div className="mt-5 mx-lg-5 mx-2">
                    <ModalEmpresa
                        loader={this.props.loader}
                        cancelar={this.closeModal}
                        empresas={this.getEmpresasAsignar()}
                        onSubmit={this.asignarEmpresa}
                        t={this.props.t}
                    />
                </div>
            </Modal>
        )
    };

    render() {
        const { loader, item, me } = this.props;
        const lang = i18n.language ? i18n.language : 'en'
        moment.locale(lang)
        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    {this.renderModal()}
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center flex-1 my-4 mx-5">
                            <div className="d-flex flex-column flex-1">
                                <span className="text-sm gris-oscuro">{this.props.t('Cohorte')}</span>
                                <span className="text-sm celeste-oscuro bold">{item.cohorte ? item.cohorte.nombre : ''}</span>
                            </div>
                            <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                <span className="text-sm gris-oscuro">{this.props.t('Mentoría')}</span>
                                <span className="text-sm celeste-oscuro bold">{item.titulo}</span>
                            </div>
                            <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                <span className="text-sm gris-oscuro">{this.props.t('Fecha')}</span>
                                <span className="text-sm celeste-oscuro bold">
                                    {(me&&me.timezone) ? moment(item.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY"):moment(item.fecha_inicio).format("MMM-DD-YYYY")}
                                </span>
                            </div>
                            <div className="d-flex flex-column flex-1 mt-2 mt-md-0">
                                <Link to="/mentorias" className="btn btn-secondary mx-5">Regresar</Link>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className="w-100 px-md-5 pt-2 pb-5">
                            <Grid hover striped data={{ count: item.slots ? item.slots.length : 0, results: item.slots }} loading={false} pagination={false} >
                                <TableHeaderColumn
                                    isKey
                                    dataField="id"
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={(cell, row) => (row.empresa ?
                                        <button style={{width: "8rem"}} className="btn btn-danger-outline" onClick={() => this.desasignarEmpresa(cell)}>{this.props.t('Desasignar')}</button>
                                        : <button style={{width: "8rem"}} className="btn btn-secondary" onClick={() => this.openModal(cell)}>{this.props.t('Asignar')}</button>
                                    )}
                                >
                                    {this.props.t('Acciones')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="empresa"
                                    dataFormat={cell => cell ? this.props.t('Reservado') : this.props.t('Disponible')}
                                >
                                    {this.props.t('Estado')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_inicio"
                                    dataFormat={cell => (me&&me.timezone) ? moment(cell).tz(me.timezone).format("hh:mm a z"):moment(cell).format("hh:mm a")}
                                >
                                    {this.props.t('Hora inicio')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_fin"
                                    dataFormat={cell => (me&&me.timezone) ? moment(cell).tz(me.timezone).format("hh:mm a z"):moment(cell).format("hh:mm a")}
                                >
                                    {this.props.t('Hora fin')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="empresa"
                                    width="28%"
                                    dataFormat={cell => (cell ?
                                        <div className="d-flex flex-1 align-items-center">
                                            <img className="logo-tabla mr-3" src={cell.logo} />
                                            <span>{cell.nombre}</span>
                                        </div>
                                        : ''
                                    )}
                                >
                                    {this.props.t('Reservado por')}
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </Card>
                </LoadMask>
            </div>
        )
    }
}

export default withTranslation()(SlotsMentoria)
