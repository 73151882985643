import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderFieldRadio} from '../../../Utils/renderField/renderField';
import { BASICO, INTERMEDIO, EXPERTO } from '../../../../../utility/constants';
import { withTranslation } from 'react-i18next';


const ExamenNivelacionForm = props => {
    const { handleSubmit } = props;

    return (
        <div style={{ width: "50vw" }} className="flex-1 w-100-responsivo px-2 pt-4 px-md-4" >
            <form onSubmit={handleSubmit} className="form-validate mb-lg px-2 px-lg-4">
                <div className="form-group d-flex flex-column flex-1">
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <span className="text-sm bold azul-gris-oscuro">{props.t('Contabilidad y finanzas')}</span>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column p-0 my-3">
                            <span className="text-sm azul bold">1-{props.t('¿Sistemas y procesos contables?')} </span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                        </div>
                        <div className="d-flex flex-row flex-1 py-2">
                            <div className="flex-1">
                                <Field
                                    label={props.t('Bajo/Básico')}
                                    type="radio"
                                    value={BASICO}
                                    name="pregunta1"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Promedio/Intermedio')}
                                    type="radio"
                                    value={INTERMEDIO}
                                    name="pregunta1"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    //disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Alto/Experto')}
                                    type="radio"
                                    value={EXPERTO}
                                    name="pregunta1"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column p-0 my-3">
                            <span className="text-sm azul bold">2-{props.t('¿Cómo leer los estados financieros?')} </span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                        </div>
                        <div className="d-flex flex-row flex-1 py-2">
                            <div className="flex-1">
                                <Field
                                    label={props.t('Bajo/Básico')}
                                    type="radio"
                                    value={BASICO}
                                    name="pregunta2"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Promedio/Intermedio')}
                                    type="radio"
                                    value={INTERMEDIO}
                                    name="pregunta2"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Alto/Experto')}
                                    type="radio"
                                    value={EXPERTO}
                                    name="pregunta2"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column p-0 my-3">
                            <span className="text-sm azul bold">3-{props.t('¿Cómo calcular e interpretar ratios financieros?')} </span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                        </div>
                        <div className="d-flex flex-row flex-1 py-2">
                            <div className="flex-1">
                                <Field
                                    label={props.t('Bajo/Básico')}
                                    type="radio"
                                    value={BASICO}
                                    name="pregunta3"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Promedio/Intermedio')}
                                    type="radio"
                                    value={INTERMEDIO}
                                    name="pregunta3"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Alto/Experto')}
                                    type="radio"
                                    value={EXPERTO}
                                    name="pregunta3"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <span className="text-sm bold azul-gris-oscuro">{props.t('Marketing')}</span>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column p-0 my-3">
                            <span className="text-sm azul bold">1-{props.t('¿Marketing tradicional?')} </span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                        </div>
                        <div className="d-flex flex-row flex-1 py-2">
                            <div className="flex-1">
                                <Field
                                    label={props.t('Bajo/Básico')}
                                    type="radio"
                                    value={BASICO}
                                    name="pregunta4"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Promedio/Intermedio')}
                                    type="radio"
                                    value={INTERMEDIO}
                                    name="pregunta4"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Alto/Experto')}
                                    type="radio"
                                    value={EXPERTO}
                                    name="pregunta4"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column p-0 my-3">
                            <span className="text-sm azul bold">2-{props.t('¿Marketing digital y redes sociales?')} </span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                        </div>
                        <div className="d-flex flex-row flex-1 py-2">
                            <div className="flex-1">
                                <Field
                                    label={props.t('Bajo/Básico')}
                                    type="radio"
                                    value={BASICO}
                                    name="pregunta5"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Promedio/Intermedio')}
                                    type="radio"
                                    value={INTERMEDIO}
                                    name="pregunta5"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Alto/Experto')}
                                    type="radio"
                                    value={EXPERTO}
                                    name="pregunta5"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column p-0 my-3">
                            <span className="text-sm azul bold">3-{props.t('¿Comercio electrónico?')} </span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '3px'}} />
                        </div>
                        <div className="d-flex flex-row flex-1 py-2">
                            <div className="flex-1">
                                <Field
                                    label={props.t('Bajo/Básico')}
                                    type="radio"
                                    value={BASICO}
                                    name="pregunta6"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Promedio/Intermedio')}
                                    type="radio"
                                    value={INTERMEDIO}
                                    name="pregunta6"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                            <div className="flex-1">
                                <Field
                                    label={props.t('Alto/Experto')}
                                    type="radio"
                                    value={EXPERTO}
                                    name="pregunta6"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex buttons-box mt-4">
                        <button type="submit" className="btn btn-secondary">{props.t('Regresar')}</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-4">
                        <button type="submit" className="btn btn-secondary flex-1 mt-3">{props.t('Regresar')}</button>
                    </div>
                </div>
            </form>
        </div>
    )

}

export default reduxForm({
    form: 'ExamenNivelacionForm',
    asyncBlurFields: [],
})(withTranslation()(ExamenNivelacionForm));
