import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { AsyncSelectField, renderDatePicker3, renderField, renderTimeField, renderEditorField } from '../../../Utils/renderField/renderField';
import CardForm from '../../../Utils/Cards/CardForm';
import { MENTORIA, NIVELES } from '../../../../../utility/constants';
import { api } from "api";
import moment from 'moment';
import Grid from '../../../Utils/Grid';
import { TableHeaderColumn } from "react-bootstrap-table";
import _ from "lodash"
import validators from './validators';
import ArchivosUploader from '../../../Utils/ArchivoUploader';

const getCohortes = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


let PasoUnoForm = props => {
    
    const { handleSubmit, archivos, step, step_name, step_icon, bloque_slots, cohorte} = props;
    const getSlots = () => {
        const array = [];
        if (props.hora_inicio && props.hora_fin) {
            let horaInicio = moment(props.hora_inicio,['HH:mm']);
            let horaFin = moment(props.hora_fin,['HH:mm']);
            
            while(horaInicio.format('HH:mm') < horaFin.format('HH:mm')) {
                const horaAux = moment(horaInicio).add(30, 'minutes');
                if (horaAux > horaFin) {
                    array.push({hora_inicio: horaInicio.format('HH:mm')});
                } else {
                    array.push({hora_inicio: horaInicio.format('HH:mm')});
                }
                horaInicio.add(30, 'minutes');
            }
        }
        return array;
    }
    
    const onEliminar= (row)=>{
        props.removerBloque(row)
    }

    const getGrowthAngel = (search) => {
        let params = {}
        if (search) {
            params.search = search;
        }
        if(cohorte&&cohorte.id){
            params.cohorte = cohorte.id
        }
        return api.get("growth_angels/obtener_growth_angels", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <CardForm col='12'>
                <div className="form-group d-flex flex-column flex-1">
                    <div className="d-flex flex-row mb-3">
                        <div className="mr-2">
                            <i className={`fas fa-${step_icon} azul-gris-oscuro`} style={{fontSize: "2.5rem"}}></i>
                        </div>
                        <div className="d-flex flex-column">
                            <h4 className="mb-0 bold azul-gris-oscuro">{`${props.t('Paso')} ${step} ${props.t('de')} ${2}`}</h4>
                            <span className="gris-oscuro text-sm">{`${step_name}`}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="cohorte" className="m-0">{props.t('Cohorte')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field
                                name="cohorte"
                                loadOptions={getCohortes}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="titulo" className="m-0">{props.t('Nombre de la mentoría')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2" >
                        <div className="d-flex flex-column align-items-start justify-content-center flex-1 p-2">
                            <label htmlFor="fecha_inicio" className="m-0">{props.t('Zona horaria de la cohorte')}</label>
                            <span className="text-sm bold celeste-oscuro pl-1">{cohorte ? cohorte.timezone : '------'}</span>
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="fecha_inicio" className="m-0">{props.t('Fecha de la mentoría')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="fecha_inicio" onCambio={props.cambiarFechaDrill} component={renderDatePicker3} noPasados={true}/>
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2" >
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="fecha_hora_inicio" className="m-0">{props.t('Hora inicio')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="fecha_hora_inicio" onCambio={props.cambiarFechaDrill} component={renderTimeField} />
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="fecha_hora_fin" className="m-0">{props.t('Hora fin')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="fecha_hora_fin" onCambio={props.cambiarFechaDrill} component={renderTimeField} />
                        </div>

                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="descripcion" className="m-0">{props.t('Descripción')}</label>
                            <Field name="descripcion" component={renderEditorField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-wrap px-0 mt-2">
                        <span className="text-sm azul-gris-oscuro bold">{props.t('Archivos del evento')}</span>
                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-3 p-2">
                            <ArchivosUploader
                                archivos={archivos}
                                upload={props.setArchivos}
                                eliminar={props.eliminarArchivo}
                                btnLabel={props.t('Cargar archivos')}
                            />
                        </div>
                        <div className="flex-2"></div>
                    </div>
                </div>
            </CardForm>
            <CardForm col="12">
                <div className="form-group d-flex flex-column flex-1">
                    <div className="d-flex flex-row px-2 azul-gris-oscuro">
                        <span className="text-sm bold" > {props.t('Agregar bloques de slots')} </span>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="hora_inicio" className="m-0">{props.t('Hora inicio')} <span className="gris m-0 text-xsm">({props.t('del bloque')})</span></label>
                            <Field name="hora_inicio" onCambio={props.cambiarFechaDrill} component={renderTimeField} notTouched={true} />
                        </div>
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="hora_fin" className="m-0">{props.t('Hora fin')} <span className="gris m-0 text-xsm">({props.t('del bloque')})</span></label>
                            <Field name="hora_fin" component={renderTimeField} notTouched={true} />
                        </div>
                    </div>
                    <div className="d-flex px-2 py-1" >
                        <span className="bold azul-gris-oscuro text-sm">{props.t('Total de slots')}: {getSlots().length}</span>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-0">
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="titulo" className="m-0">{props.t('Growth angels')} <span className="gris m-0 text-xsm">({props.t('del bloque')})</span></label>
                            <Field
                                    key={`key-${cohorte? cohorte.id:''}`}
                                    name={'growth_angels'}
                                    loadOptions={getGrowthAngel}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="nombres"
                                    className="form-control"
                                    isMulti
                                    notTouched={true}
                                />
                        </div>
                    </div>
                    <div className="d-flex flex-lg-row flex-column flex-wrap px-2 mt-2" >
                        <button type="button" 
                            onClick={handleSubmit(props.agregarBloque)} 
                            className="btn btn-secondary-fill">{props.t('Agregar bloque')}</button>
                    </div>
                    <div className="d-flex pt-3" >
                        <Grid hover pagination={false} striped data={{results:bloque_slots, count:bloque_slots.length}} 
                            key="grid-lists" noResponsive={true} >
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign="center"
                                dataFormat={(cell, row)=>{
                                    return(
                                        <div className="d-flex justify-content-center" >
                                            <a title={props.t('Eliminar')} 
                                                className="action action-gris px-1 py-2" 
                                                style={{cursor: "pointer"}} 
                                                onClick={e=>onEliminar(row)}>
                                                <i className="fas fa-times gris" />
                                            </a>
                                        </div>
                                    )
                                }}
                            >
                                {props.t('Acciones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="cantidad"
                            >
                                {props.t('Slots')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_inicio"
                                dataFormat={cell=> moment(cell).format('hh:mm A')}
                            >
                                {props.t('Hora inicio')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_fin"
                                dataFormat={cell=> moment(cell).format('hh:mm A')}
                            >
                                {props.t('Hora fin')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="growth_angels"
                                dataFormat={cell=>{
                                    return(
                                        <div className="d-flex flex-column" >
                                            {cell.map((item, index)=>(
                                                <span key={index} 
                                                    className={index%2 ? '':'azul-oscuro'}>
                                                    {item.nombres}
                                                </span>
                                            ))}
                                        </div>
                                    )
                                }}
                            >
                                {props.t('Growth Agels')}
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>
            </CardForm>
            <CardForm>
                <div className="form-group d-flex flex-column flex-1">
                    <div className="d-none d-lg-flex buttons-box mt-4">
                        <Link className="btn btn-secondary mr-3" to="/mentorias">{props.t('Cancelar')}</Link>
                        <button type="submit" className="btn btn-primary ml-3">{props.t('Siguiente')}</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-4">
                        <Link className="btn btn-secondary flex-1" to="/mentorias">{props.t('Cancelar')}</Link>
                        <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Siguiente')}</button>
                    </div>
                </div>
            </CardForm>
        </form>
    )

};

const selector = formValueSelector('MentoriaForm');

PasoUnoForm = reduxForm({
    form: 'MentoriaForm',
    asyncBlurFields: [],
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        tiene_drill: true,
        pre_workshop: false,
        tipo_publicacion: MENTORIA,
        calificar_evento: true,
    },
    validate: validators,
})(PasoUnoForm);

const mstp = state => {
    const fecha_inicio = selector(state, 'fecha_inicio');
    const hora_inicio = selector(state, 'hora_inicio');
    const hora_fin = selector(state, 'hora_fin');
    const fecha_hora_inicio = selector(state, 'fecha_hora_inicio');
    const fecha_hora_fin = selector(state, 'fecha_hora_fin')
    const pre_workshop = selector(state, 'pre_workshop');
    const bloque_slots = selector(state, 'bloque_slots');
    const cohorte = selector(state, 'cohorte');
    let archivos = selector(state, 'archivos');
    archivos = archivos ? archivos : [];



    return {
        fecha_inicio,
        hora_inicio,
        hora_fin,
        fecha_hora_inicio,
        fecha_hora_fin,
        pre_workshop,
        bloque_slots,
        cohorte,
        archivos,
    }
};

export default connect(mstp, null)(PasoUnoForm);
