import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const LoginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="login-form d-flex flex-column flex-1" onSubmit={handleSubmit}>
            <div className="d-flex flex-column flex-1 mt-3 has-feedback" >
                <label htmlFor="email" className="blanco bold text-sm">Email</label>
                <Field name="email" label="Email" component={renderField} type="email" className="form-control" />
            </div>
            <div className="d-flex flex-column flex-1 mt-3 has-feedback" >
                <label htmlFor="password" className="blanco bold text-sm">{props.t('Contraseña')}</label>
                <Field
                    name="password"
                    label={props.t("Contraseña")}
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center flex-1 mt-5">
                <button type="submit" className="btn btn-primary d-flex flex-1">{props.t('Login')}</button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data, props) => {
        return validate(data, {
            email: validators.exists()(`${props.t('Campo requerido')}`),
            password: validators.exists()(`${props.t('Campo requerido')}`),
        });
    },
})(LoginForm);
