import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { getI18n  } from 'react-i18next';

const LOADER = 'LOADER_AMIGOS_GROWMOTOR';
const DATA = 'DATA_AMIGOS_GROWMOTOR';
const ITEM_DATA = 'ITEM_AMIGOS_GROWMOTOR';
const PAGE = 'PAGE_AMIGOS_GROWMOTOR';
const ORDERING = 'ORDERING_AMIGOS_GROWMOTOR';
const SEARCH = 'SEARCH_AMIGOS_GROWMOTOR';
const SEARCH_COHORTE = 'SEARCH_COHORTE_AMIGOS_GROWMOTOR';
const DATA_COHORTES = 'DATA_COHORTES_AMIGOS_GROWMOTOR';
const LOADER_COHORTES = 'LOADER_COHORTES_AMIGOS_GROWMOTOR';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderCohortes = loader_cohortes => ({
    type: LOADER_COHORTES,
    loader_cohortes,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setSearchCohorte = search_cohorte => ({
    type: SEARCH_COHORTE,
    search_cohorte,
});

const setDataCohortes =data_cohortes =>({
    type:DATA_COHORTES,
    data_cohortes,
})

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().amigosGrowmotor;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('amigos_growmotor', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`amigos_growmotor/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('AmigosGrowmotorForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('amigos_growmotor', data).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/amigos_growmotor'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`amigos_growmotor/${id}`, data).then(() => {
        NotificationManager.success(getI18n().t('Registro actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/amigos_growmotor'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la edición');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        if (error && error.email) {
            mensaje = error.email;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`amigos_growmotor/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const reenviar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`amigos_growmotor/${id}/reenviar_invitacion`).then(() => {
        NotificationManager.success(getI18n().t('Invitación reenviada'), getI18n().t('Éxito'), 3000);
    }).catch((error) => {
        let mensaje = getI18n().t('Error al enviar invitación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('AmigosGrowmotorForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};
const searchChangeCohortes = search => (dispatch) => {
    dispatch(setSearchCohorte(search));
    dispatch(listarCohortes());
};
const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().amigosGrowmotor.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const listarCohortes = (page = 1)=>(dispatch, getStore)=>{
    const { values } = getStore().form.AmigosGrowmotorForm;
    const resource = getStore().amigosGrowmotor;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search_cohorte;
    dispatch(setLoaderCohortes(true));
    api.get('cohorte', params).then((response) => {
        const data = {}
        data.results  = _.differenceWith(response.results, values.cohortes,function(val1, val2){
            return val1.id===val2.cohorte.id
        })
        data.results = data.results.map((item)=>({nombre:item.nombre,cohorte:{...item}}))
        data.count = data.results.length
        dispatch(setDataCohortes(data));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderCohortes(false));
    });
}

const insertarCohortes = (cohortes)=>(dispatch, getStore)=>{
    const { values } = getStore().form.AmigosGrowmotorForm;
    let listaCohortes = []
    if (values&&values.cohortes){
        listaCohortes = [...values.cohortes]
    }
    listaCohortes = [...listaCohortes,...cohortes]
    const formValues = {...values,cohortes:listaCohortes};
    dispatch(initializeForm('AmigosGrowmotorForm', formValues ))
}

const removeCohorte=(cohorte)=>(dispatch,getStore)=>{
    const { values } = getStore().form.AmigosGrowmotorForm;
    let listaCohortes = []
    if (values&&values.cohortes){
        listaCohortes = [...values.cohortes]
    }
    listaCohortes = _.remove(listaCohortes,function(co){
        return co.cohorte.id!==cohorte.id
    })
    const formValues = {...values,cohortes:listaCohortes};
    dispatch(initializeForm('AmigosGrowmotorForm', formValues ))
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    listarCohortes,
    insertarCohortes,
    searchChangeCohortes,
    removeCohorte,
    reenviar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_COHORTES]: (state, { loader_cohortes }) => {
        return {
            ...state,
            loader_cohortes,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH_COHORTE]: (state, { search_cohorte }) => {
        return {
            ...state,
            search_cohorte,
        };
    },
    [DATA_COHORTES]: (state, { data_cohortes }) => {
        return {
            ...state,
            data_cohortes,
        };
    },
};

export const initialState = {
    loader: false,
    loader_cohortes: false,
    data: {
        results: [],
        count: 0,
    },
    data_cohortes: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    search_cohorte:'',
};

export default handleActions(reducers, initialState);
