import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import perfil from './modules/cuenta/perfil';
import notificaciones from './modules/cuenta/notificaciones';
import usuarios from './modules/usuarios/usuarios';
import empresas from './modules/empresas';
import superAdmins from './modules/admins';
import cohortes from './modules/cohortes';
import growthAngels from './modules/growthAngels';
import talleres from './modules/publicaciones/talleres';
import mentorias from './modules/publicaciones/mentorias';
import evaluaciones from './modules/publicaciones/evaluaciones';
import guerreros from './modules/publicaciones/guerreros';
import familyMeeting from './modules/publicaciones/familyMeeting';
import amigosGrowmotor from './modules/amigosGrowmotor';
import asistencias from './modules/reportes/asistencias';
import avisos from './modules/avisos';
import nivelacion from './modules/reportes/nivelacion';
import membresias from './modules/membresias/index';
import reporteEmpresas from './modules/reportes/empresas';
import demografico from './modules/reportes/demografico';
import dashboard from './modules/reportes/dashboard';
import calificacionesEventos from './modules/reportes/calificacionEventos';
import invitacionesMasivas from './modules/invitacionesMasivas'

export default combineReducers({
    form: formReducer,
    login,
    register,
    perfil,
    usuarios,
    empresas,
    superAdmins,
    talleres,
    mentorias,
    familyMeeting,
    evaluaciones,
    guerreros,
    cohortes,
    growthAngels,
    amigosGrowmotor,
    asistencias,
    notificaciones,
    avisos,
    nivelacion,
    membresias,
    reporteEmpresas,
    demografico,
    dashboard,
    calificacionesEventos,
    invitacionesMasivas,
    routing,
});
