import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import FormEmpresa from './FormEmpresa';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class CrearEmpresa extends Component {

    componentDidMount=()=>{
        const id = this.props.match.params.id;
        const {me}= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            if (id){
                window.id = id
                this.props.leer(id, true);
            } else {
                window.id = null
            }
        }
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const data = {...values};
        if (data.fecha_fundacion)
            data.fecha_fundacion = moment(data.fecha_fundacion).format("YYYY-MM-DD");
        if (data.pais){
            data.pais = data.pais.name;
        }
        if (data.sectores) {
            data.sectores = data.sectores.map(item => ({...item, tipo: item.value}))
        }
        if (id) {
            this.props.editar(data, id);
        } else {
            this.props.crear(data);
        }
    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <FormEmpresa onSubmit={this.onSubmit} t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(CrearEmpresa)