import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/evaluaciones';
import ListarEvaluaciones from './ListarEvaluaciones';

const ms2p = (state) => {
    return {
        ...state.evaluaciones,
        me: state.login.me, 
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarEvaluaciones);
