import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Async, } from 'react-select';
import Grid from '../../../Utils/Grid';
import Card from '../../../Utils/Cards/Card';
import { TableHeaderColumn } from "react-bootstrap-table";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import { api } from 'api';
import { config, TIPO_SECTORES, MODELOS } from '../../../../../utility/constants';
import CountrySelect from 'react-bootstrap-country-select';
import Select from 'react-select';
import SearchInput from '../../../Utils/Grid/SearchInput';

const  IconoEmpresa = require('../../../../../../assets/img/icono-empresa.png');

const getCohortes = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

class reporteEmpresas extends React.Component{
    constructor(props){
        super(props);
    }
    onVer(id) {
        window.open(`${config.url.URL_PUBLIC}empresa/${id}`, '_blank')
    }

    componentDidMount = ()=>{
        this.props.listarEmpresas()
    }

    render(){
        const { data, page, loader, listarEmpresas } = this.props;
        return(
            <div className="px-lg-3 px-0" >
                <Card className="p-lg-4">
                <div className='d-flex px-md-1 flex-1 flex-column flex-md-row'>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <CountrySelect
                            value={this.props.pais}
                            onChange={e=>this.props.changePais(e)}
                            placeholder={this.props.t("País")+"..."} />
                    </div>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.cohorte}
                            isSearchable={true}
                            loadOptions={getCohortes}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeCohorte(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={this.props.t('Cohorte')+'...'}/>

                    </div>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <Select
                            options={TIPO_SECTORES}
                            isSearchable={true}
                            isClearable={true}
                            placeholder={this.props.t("Sector")+"..."}
                            onChange={e=>this.props.changeSector(e)}  />
                    </div>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <Select
                            options={MODELOS}
                            isSearchable={true}
                            isClearable={true}
                            placeholder={this.props.t('Modelo de negocios')+'...'}
                            onChange={e=>this.props.changeModelo(e)}  />
                    </div>
                    <div className="flex-2" >
                        <SearchInput
                            search={this.props.search}
                            searchChange={this.props.searchChange}
                            placeholder={this.props.t("Buscar")+"..."} />

                    </div>
                </div>
                <div className="d-flex flex-1 py-3 justify-content-md-end justify-content-center align-items-center" >
                    <span className="text-qts celeste-oscuro mr-md-5 mr-1 text-center">{`${this.props.t('Empresas encontradas')}: ${this.props.data.count} `}</span>
                    <button type="button" className="btn btn-primary" onClick={e=>this.props.descargarExcel()} >{this.props.t('Descargar Excel')}</button>
                </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={listarEmpresas}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataFormat={(cell,row) => (
                                <div className="d-flex justify-content-center">
                                    <a
                                        title={this.props.t("Ver")}
                                        onClick={(e) => this.onVer(cell)}
                                        className="action action-azul-oscuro  px-1 py-2"
                                    >
                                        <i className="fas fa-eye azul-oscuro"></i>
                                    </a>
                                </div>)}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataFormat={(cell,row)=>(
                                <div className="d-flex flex-1 align-items-center">
                                    <img className="logo-tabla mr-2" src={row.logo ? row.logo : IconoEmpresa } />
                                    <span>{cell}</span>
                                </div>)}
                        >
                            {this.props.t('Empresa')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pais"
                        >
                            {this.props.t('País')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cohorte"
                            dataFormat={cell=> cell.join(',  ')}
                        >
                            {this.props.t('Cohorte')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="sectores"
                            dataFormat={cell=> cell.join(',  ')}
                        >
                            {this.props.t('Sectores')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="modelo_negocios"
                        >
                            {this.props.t('Modelo de negocios')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(reporteEmpresas);
