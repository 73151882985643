import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18nextInit.js";

export default class Root extends Component {
    get content() {
        return <Router>{this.props.routes}</Router>;
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <Provider store={this.props.store}>{this.content}</Provider>
            </I18nextProvider>
        );
    }
}

Root.propTypes = {
    routes: PropTypes.element.isRequired,
    store: PropTypes.object.isRequired,
};
