import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/avisos';
import ListarAvisos from './ListarAvisos';

const ms2p = (state) => {
    return {
        ...state.avisos,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarAvisos);
