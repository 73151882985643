import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { renderField, renderNumber, renderDatePicker3, AsyncSelectField, SelectField, renderCountrySelect, renderFieldCheck, TimeZoneSelectField } from '../../Utils/renderField/renderField';
import { IDIOMAS_SISTEMA } from "../../../../utility/constants";
import { withTranslation } from 'react-i18next';
import {NotificationManager} from "react-notifications";
import { api } from "api";
import moment from 'moment';
import Modal  from 'react-responsive-modal';
import { getI18n } from 'react-i18next';

import GridEmpresas from './Extra-Componets/GridEmpresas';
import ModalEmpresas from './Extra-Componets/ModalEmpresas';


const getAdmins = (search) => {
    let params = { }
    if (search) {
        params.search = search;
    }
    return api.get("admin", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const validators = (values, props)=>{
    const errors= [];
    if (!values.nombre){
        errors.nombre= `${props.t('Campo requerido')}`;
    }
    if (!values.pais){
        errors.pais = `${props.t('Campo requerido')}`;
    }
    if (!values.ciudad){
        errors.ciudad = `${props.t('Campo requerido')}`;
    }
    if (!values.fecha_inicio){
        errors.fecha_inicio =`${props.t('Campo requerido')}`;
    }
    if (!values.timezone){
        errors.timezone =`${props.t('Campo requerido')}`;
    }
    if (!values.fecha_fin){
        errors.fecha_fin = `${props.t('Campo requerido')}`;
    }
    if (!values.administrador){
        errors.administrador = `${props.t('Campo requerido')}`;
    }
    if (!values.costo){
        errors.costo = `${props.t('Campo requerido')}`;
    }
    if (!values.link_informativo){
        errors.link_informativo = `${props.t('Campo requerido')}`;
    }
    if(values.fecha_inicio&&values.fecha_fin){
        const now = moment()
        const fecha_inicio = moment(values.fecha_inicio)
        const fecha_fin = moment(values.fecha_fin)
        if(props.item&&props.item.fecha_inicio&&props.item.fecha_fin){
            const item_fecha_inicio = moment(props.item.fecha_inicio).tz(props.item.timezone).format("YYYY-MM-DD");
            const item_fehca_fin = moment(props.item.fecha_fin).tz(props.item.timezone).format("YYYY-MM-DD");
            if(fecha_inicio.format("YYYY-MM-DD")!==item_fecha_inicio||fecha_fin.format("YYYY-MM-DD")!==item_fehca_fin){
                /* if(fecha_inicio.format("YYYY-MM-DD") < now.format("YYYY-MM-DD")){
                    errors.fecha_inicio = `${props.t('Tiene que ser una fecha futura')}`
                } */
                if(fecha_fin.format("YYYY-MM-DD") < now.format("YYYY-MM-DD")){
                    errors.fecha_fin = `${props.t('Tiene que ser una fecha futura')}`
                }
                if(fecha_inicio.format("YYYY-MM-DD")>fecha_fin.format("YYYY-MM-DD")){
                    errors.fecha_fin = `${props.t('Fecha fin tiene que se mayor a fecha inicio')}`
                }
            }

        }else{

            if(fecha_inicio.format("YYYY-MM-DD") < now.format("YYYY-MM-DD")){
                errors.fecha_inicio = `${props.t('Tiene que ser una fecha futura')}`
            }
            if(fecha_fin.format("YYYY-MM-DD") < now.format("YYYY-MM-DD")){
                errors.fecha_fin = `${props.t('Tiene que ser una fecha futura')}`
            }
            if(fecha_inicio.format("YYYY-MM-DD")>fecha_fin.format("YYYY-MM-DD")){
                errors.fecha_fin = `${props.t('Fecha fin tiene que se mayor a fecha inicio')}`
            }

        }
    }
    return errors;
}

const onSubmitFail = (e) => {
    NotificationManager.error(getI18n().t('Revise los valores ingresados.'), getI18n().t('ERROR'), 3000);
}

let CohorteForm = props => {
    const [open, setOpen] = React.useState(false);
    const { handleSubmit, editar, enviarCorreos, empresas } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre" className="m-0">{props.t('Nombre de la cohorte')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombre" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="pais" className="m-0">{props.t('País')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="pais" component={renderCountrySelect} type="text"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="ciudad" className="m-0">{props.t('Ciudad')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="ciudad" component={renderField} type="text" className="form-control"/>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="ciudad" className="m-0">{props.t('Administrador de la cohorte')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field
                            name="administrador"
                            component={AsyncSelectField}
                            type="text"
                            loadOptions={getAdmins}
                            valueKey="id"
                            labelKey="nombres"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="timezone" className="m-0">{props.t('Zona horaria')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="timezone" component={TimeZoneSelectField} />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_inicio" className="m-0">{props.t('Fecha inicio')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="fecha_inicio" component={renderDatePicker3} noPasados={true} />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_fin" className="m-0">{props.t('Fecha fin')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="fecha_fin" component={renderDatePicker3} noPasados={true} />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="link_informativo" className="m-0">{props.t('Link de la cohorte')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="link_informativo" component={renderField} type="url" className="form-control"/>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_inicio" className="m-0 mb-2">{props.t('¿La cohorte tiene costo?')}</label>
                        <Field
                            component={renderFieldCheck}
                            className="form-control"
                            name="tiene_costo"
                            label={props.t('Sí, tiene')}
                        />
                    </div>
                    {(props.tiene_costo) ? (
                        <div className="d-flex flex-column flex-1 p-2">
                            <label htmlFor="fecha_fin" className="m-0">{props.t('Costo por empresa')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                            <Field name="costo" prefix="USD " component={renderNumber} placeholder="USD 0.00" decimalScale={2} className="form-control" />
                        </div>
                    ) : (
                        <div className="d-flex flex-column flex-1 p-2"></div>
                    )}
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="fecha_inicio" className="m-0 mb-2">{props.t('¿Activar el pago de membresía?')}</label>
                        <Field
                            component={renderFieldCheck}
                            className="form-control"
                            name="activar_membresia"
                        />
                    </div>
                </div>
                <h4 className='pl-4 bold'>{props.t('Configuración KPIs')}</h4>
                <div className="d-flex flex-lg-row flex-column flex-wrap pl-4 pr-4">
                    <div className="d-flex flex-column flex-1 p-2">
                        <div className='row pb-2'>
                            <div className='col-8'/>
                            <div className='col-2 bold' style={{ whiteSpace: 'nowrap' }}>
                                <span>
                                    {props.t('Obligatorio')}
                                </span>
                            </div>
                            <div className='col-2 bold' style={{ whiteSpace: 'nowrap' }}>
                                <span>
                                    {props.t('Ver')}
                                </span>
                            </div>
                        </div>
                        {/* PREGUNTA 1 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                1-{props.t('¿Cuales fueron sus ventas en total del año pasado?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 1.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                1.1.{props.t('¿Cuáles son sus ventas en lo que va del año?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_a_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_a_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 1.2 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                1.2.{props.t('¿Incremento o decremento al mismo periodo del año pasado?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_b_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_b_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 1.3 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                1.3.{props.t('¿Espera sobrepasar los ingresos del año pasado?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_c_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_c_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 1.3.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                1.3.1.{props.t('De ser así, ¿por cuánto aproximadamente?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_c_i_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta1_c_i_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 2 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                2-{props.t('¿Cuantos empleados de tiempo completo tiene?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 2.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                2.1.{props.t('¿Ha hecho nuevas contrataciones en el ultimo trimestre?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_a_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_a_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 2.1.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                2.1.1.{props.t('De ser así, ¿Cuántas?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_a_i_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_a_i_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 2.1.2 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                2.1.2.{props.t('Tipo de trabajo / roles')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_a_ii_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta2_a_ii_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 3 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                3-{props.t('¿Obtuvo nuevo financiamiento ?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta3_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta3_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 3.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                3.1.{props.t('De ser así, ¿por cuánto?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta3_a_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta3_a_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 4 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                4-{props.t('¿Ha expandido su capacidad ( por ejemplo, capacidad de manufactura, edificio o puntos de venta?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta4_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta4_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 4.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                4.1.{props.t('Por favor, describa')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta4_a_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta4_a_show"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <div className='row pb-2'>
                            <div className='col-8'/>
                            <div className='col-2 bold' style={{ whiteSpace: 'nowrap' }}>
                                <span>
                                    {props.t('Obligatorio')}
                                </span>
                            </div>
                            <div className='col-2 bold' style={{ whiteSpace: 'nowrap' }}>
                                <span>
                                    {props.t('Ver')}
                                </span>
                            </div>
                        </div>
                        {/* PREGUNTA 5 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                5-{props.t('¿Ha vendido a clientes en otros estados/regiones de su país?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta7_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta7_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 5.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                5.1.{props.t('De ser así, porfavor indique')}
                            </span>
                            <div className='col-2 pl-2'/>
                            <div className='col-2 pl-2'/>
                        </div>
                        {/* PREGUNTA 5.1.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                5.1.1.{props.t('Estados/regiones')+'.'}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta7_a_i_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta7_a_i_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 5.1.2 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                5.1.2.{props.t('Total aproximado de ingresos por ventas a otros estados o regiones en lo que va del año')+'.'}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta7_a_ii_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta7_a_ii_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 6 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                6-{props.t('¿Ha exportado a otros países?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta8_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta8_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 6.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                6.1.{props.t('De ser así, porfavor indique')}
                            </span>
                            <div className='col-2 pl-2'/>
                            <div className='col-2 pl-2'/>
                        </div>
                        {/* PREGUNTA 6.1.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                6.1.1.{props.t('Países (todos los que apliquen)')+'.'}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta8_a_i_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta8_a_i_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 6.1.2 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-4">
                                6.1.2.{props.t('Total aproximado de ingresos de exportación en lo que va del año')+'.'}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta8_a_ii_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta8_a_ii_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 7 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                7-{props.t('¿Cuánto fueron sus impuestos federales o nacionales (IVA si aplica), pagados en el anterior año calendario?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta9_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta9_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 7.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                7.1.{props.t('¿Cuánto son sus impuestos federales o nacionales en lo que va del año actual?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta9_a_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta9_a_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 8 */}
                        <div className='row'>
                            <span className="text-sm azul bold col-8">
                                8-{props.t('¿Cúanto son sus impuestos estatales o regionales pagados en el anterior año calendario (si aplica)?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta10_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta10_show"
                                />
                            </div>
                        </div>
                        {/* PREGUNTA 8.1 */}
                        <div className='row'>
                            <span className="text-sm azul-gris-oscuro col-8 pl-2">
                                8.1.{props.t('¿Cuánto son sus impuestos estatales o regionales en lo que va del año actual (si aplica)?')}
                            </span>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta10_a_required"
                                />
                            </div>
                            <div className='col-2 pl-2'>
                                <Field
                                    component={renderFieldCheck}
                                    className="form-control"
                                    name="pregunta10_a_show"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* CHECK REALIZAR CUESTIONARIO NIVELACION*/}
                <div className="d-flex flex-column justify-content-end flex-1 p-2">
                    <label htmlFor="titulo" className="m-0">{props.t('Realizar cuestionario de nivelacion')}</label>
                    <Field
                        component={renderFieldCheck}
                        className="form-control"
                        name="cuestionario_nivelacion"
                        label={props.t('Sí, realizar')}
                    />
                </div>

                <div className="d-flex flex-column flex-1 p-2">
                    <label htmlFor="idioma_correo" className="m-0">{props.t('Idioma del correo a enviar')}</label>
                    <Field
                        name="idioma_correo"
                        options={IDIOMAS_SISTEMA}
                        component={SelectField}
                        labelKey="name"
                        valueKey="key"
                    />
                </div>
            
                <div className="d-flex flex-lg-row flex-column flex-wrap p-2">
                    <div className="col-md-4 col-12 form-group mt-sm-3 mt-0 px-0">
                        <button type="button" className="btn btn-secondary-fill btn-block" onClick={(e)=>setOpen(true)} >{props.t('Agregar empresas')}</button>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <GridEmpresas data={empresas}  removerEmpresa={props.removerEmpresa} />
                    </div>
                </div>

                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/cohortes">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary mx-3">{props.t('Guardar')}</button>
                    {(editar && (
                        <button type="button" onClick={enviarCorreos} className="btn btn-option-one ml-3">{props.t('Enviar')}</button>
                    ))}
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/cohortes">{props.t('Cancelar')}</Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">{props.t('Guardar')}</button>
                    {(editar && (
                        <button type="button" onClick={enviarCorreos} className="btn btn-option-one flex-1 mt-3">{props.t('Enviar')}</button>
                    ))}
                </div>
                <Modal open={open} onClose={e=>setOpen(false)} >
                    <ModalEmpresas  empresas={empresas} closeModal={e=>setOpen(false)} insertarEmpresas={props.insertarEmpresas} />
                </Modal>
            </div>
        </form>
    )
}

const selector = formValueSelector('CohorteForm');

CohorteForm = reduxForm({
    form: 'CohorteForm',
    asyncBlurFields: [],
    initialValues: {
        tiene_costo: false,
        activar_membresia: true,
        pregunta1_required: true,
        pregunta1_show: true,
        pregunta1_a_required: true,
        pregunta1_a_show: true,
        pregunta1_b_required: true,
        pregunta1_b_show: true,
        pregunta1_c_required: true,
        pregunta1_c_show: true,
        pregunta1_c_i_required: true,
        pregunta1_c_i_show: true,
        pregunta2_required: true,
        pregunta2_show: true,
        pregunta2_a_required: true,
        pregunta2_a_show: true,
        pregunta2_a_i_required: true,
        pregunta2_a_i_show: true,
        pregunta2_a_ii_required: true,
        pregunta2_a_ii_show: true,
        pregunta3_required: true,
        pregunta3_show: true,
        pregunta3_a_required: true,
        pregunta3_a_show: true,
        pregunta4_required: true,
        pregunta4_show: true,
        pregunta4_a_required: true,
        pregunta4_a_show: true,
        pregunta7_required: true,
        pregunta7_show: true,
        pregunta7_a_show: true,
        pregunta7_a_i_required: true,
        pregunta7_a_i_show: true,
        pregunta7_a_ii_required: true,
        pregunta7_a_ii_show: true,
        pregunta8_required: true,
        pregunta8_show: true,
        pregunta8_a_show: true,
        pregunta8_a_i_required: true,
        pregunta8_a_i_show: true,
        pregunta8_a_ii_required: false,
        pregunta8_a_ii_show: true,
        pregunta9_required: true,
        pregunta9_show: true,
        pregunta9_a_required: true,
        pregunta9_a_show: true,
        pregunta10_required: false,
        pregunta10_show: true,
        pregunta10_a_required: false,
        pregunta10_a_show: true,
        cuestionario_nivelacion: false,
        empresas:[],
        idioma_correo: 'es'
    },
    validate: validators,
    onSubmitFail,
})(CohorteForm);

const mstp = state => {
    const cuestionario_nivelacion = selector(state, 'cuestionario_nivelacion')
    const tiene_costo = selector(state, 'tiene_costo');
    const empresas = selector(state, 'empresas');

    return {
        cuestionario_nivelacion,
        tiene_costo,
        empresas,
    }
};

export default connect(mstp, null)(withTranslation()(CohorteForm));
