import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';

import "./verify_login.css";

const logo = require('../../../../../assets/header/Logo.png')

class VerifyLogin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="verify_login" >
                <div
                    className="d-flex flex-column align-items-center justify-content-center bg-azul-oscuro"
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        margin: "0",
                        padding: "0",
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                    }}
                >
                    <img src={logo} style={{width: '15rem'}} />
                    <p
                        style={{
                            animation: "color infinite linear 2s",
                            animationDelay: "2s",

                        }}
                        className="load-texto"
                    >{ this.props.t("Cargando")+"..." }</p>
                    <Loader
                        type={'Puff'}
                        color="#FFF"
                        height="100"
                        width="100"
                    />
                </div>
            </div>
        )
    }
}

export default withTranslation()(VerifyLogin);
