import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Async, } from 'react-select';
import Grid from '../../../Utils/Grid';
import Card from '../../../Utils/Cards/Card';
import { TableHeaderColumn } from "react-bootstrap-table";
import { api } from 'api';
import { config, GENEROS } from '../../../../../utility/constants';
import CountrySelect from 'react-bootstrap-country-select';
import Select from 'react-select';
import { Slider } from 'antd';

const  IconoEmpresa = require('../../../../../../assets/img/icono-empresa.png');


const getCohortes = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


const getEmpresas = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("empresa/obtener_empresas", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

class reporteDemografico extends React.Component{
    constructor(props){
        super(props);
    }
    onVer(id) {
        window.open(`${config.url.URL_PUBLIC}perfil/${id}`, '_blank')
    }

    componentDidMount = ()=>{
        this.props.listarUsuarios()
    }

    render(){
        const { data, page, loader, listarUsuarios, t } = this.props;
        const TIPOS_USUARIOS = [
            {value: 0, label: t("Todos")},
            {value: 1, label: t("CEO's")},
            {value: 2, label: t("Colaboradores")},
            {value: 3, label: t("Colideres")},
        ]
        return(
            <div className="px-lg-3 px-0" >
                <Card className="p-lg-4">
                <div className='d-flex px-md-1 flex-1 flex-column flex-md-row'>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <CountrySelect
                            value={this.props.pais}
                            onChange={e=>this.props.changePais(e)}
                            placeholder={this.props.t("País")+"..."} />
                    </div>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.cohorte}
                            isSearchable={true}
                            loadOptions={getCohortes}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeCohorte(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={this.props.t('Cohorte')+'...'}/>

                    </div>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.empresa}
                            isSearchable={true}
                            loadOptions={getEmpresas}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeEmpresa(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={this.props.t('Empresa')+'...'}/>

                    </div>
                    <div className="flex-1 pr-md-4 pb-3 pb-md-0" >
                        <Select
                            options={TIPOS_USUARIOS}
                            isSearchable={true}
                            isClearable={true}
                            placeholder={ this.props.t("Tipo Usuario")+"..."}
                            onChange={e=>this.props.changeTipoUsuario(e)}  />
                    </div>
                    <div className="flex-1" >
                        <Select
                            options={GENEROS}
                            isSearchable={true}
                            isClearable={true}
                            placeholder={this.props.t("Genero")+"..."}
                            onChange={e=>this.props.changeGenero(e)}  />
                    </div>
                </div>
                <div className="d-flex flex-1 py-3 px-1 flex-column flex-md-row" >
                    <div className="flex-1">
                        <span className="azul-gris-oscuro text-xs"> {this.props.t('Rango de edad')}</span>
                        <div className="d-flex flex-row" >
                        <span className="azul-gris-oscuro text-xsm" >{this.props.rango[0]}</span>
                            <div className="flex-1 px-1" >
                                <Slider
                                    range
                                    defaultValue={[0,100]}
                                    onAfterChange={e=>{
                                        this.props.changeRango(e);
                                        }} />
                            </div>
                            <span className="azul-gris-oscuro text-xsm" >{this.props.rango[1]}</span>
                        </div>
                    </div>
                    <div className="d-none d-md-flex flex-1"/>
                    <div className="d-flex flex-2 py-3 justify-content-md-end justify-content-center align-items-center" >
                        <span className="text-qts celeste-oscuro mr-md-5 mr-1 text-center">{`${this.props.t('Personas encontradas')}: ${this.props.data.count} `}</span>
                        <button type="button" className="btn btn-primary" onClick={e=>this.props.descargarExcel()} >{this.props.t('Descargar Excel')}</button>
                    </div>
                </div>
                    <Grid hover striped data={data} page={page} loading={loader} onPageChange={listarUsuarios}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataFormat={(cell,row) => (
                                <div className="d-flex justify-content-center">
                                    <a
                                        title={this.props.t("Ver")}
                                        onClick={(e) => this.onVer(cell)}
                                        className="action action-azul-oscuro  px-1 py-2"
                                    >
                                        <i className="fas fa-eye azul-oscuro"></i>
                                    </a>
                                </div>)}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombres"
                        >
                            {this.props.t('Usuario')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="genero"
                        >
                            {this.props.t('Genero')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="edad"
                        >
                            {this.props.t('Edad')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_usuario"
                        >
                            {this.props.t('Tipo')}
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="empresa"
                            dataFormat={(cell,row)=>(
                                <div className="d-flex flex-1 align-items-center">
                                    <img className="logo-tabla mr-2" src={cell.logo ? cell.logo : IconoEmpresa } />
                                    <span>{cell.nombre}</span>
                                </div>)}
                        >
                            {this.props.t('Empresa')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pais"
                        >
                            {this.props.t('País')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cohorte"
                            dataFormat={cell=> cell.join(',  ')}
                        >
                            {this.props.t('Cohorte')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(reporteDemografico);
