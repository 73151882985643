import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import {NotificationManager} from "react-notifications";
import { withTranslation } from 'react-i18next';
import {  SwalError } from '../../../Utils/Notificaciones';

import moment from 'moment';

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';

class CrearMentoria extends Component {

    state = {
        step: 1,
        step_name: this.props.t('Info general'),
        step_icon: 'file-alt',
        imagen: null,
        edicion: false,
        archivos: [],
        errors :[],
        archivos_drill :[],
    }

    componentWillMount() {
        this.props.cleanForm()
        const id = this.props.match.params.id;
        if (id) {
            this.props.leer(id, this.preSetArchivos);
            this.setState({ edicion: true });
        }
    }
    setErrors=(errors)=>{
        this.setState({errors:errors});
    }

    componentWillUnmount() {
        this.props.cleanForm();
    }

    setImagen = (imagen) => {
        this.setState({ imagen });
    }

    preSetArchivos = (archivos_existentes, drill=false) => {
        if(drill){
            const archivos_drill = [];
            for (const item of archivos_existentes) {
                archivos_drill.push(null);
            }
    
            this.setState({ archivos_drill });
        }else{
            const archivos = [];
            for (const item of archivos_existentes) {
                archivos.push(null);
            }
    
            this.setState({ archivos });
        }
    }

    setArchivos = (files, drill=false) => {
        if(drill){
            console.log("files ", files);
            const archivos_drill = this.state.archivos_drill;
            for (let index = 0; index < files.length; index++) {
                archivos_drill.push(files[index]);
            }
            // Seteamos en redux y el state
            this.setState({ archivos_drill });
            this.props.setArchivos(files, drill);
        }else{
            console.log("files ", files);
            const archivos = this.state.archivos;
            for (let index = 0; index < files.length; index++) {
                archivos.push(files[index]);
            }
            // Seteamos en redux y el state
            this.setState({ archivos });
            this.props.setArchivos(files, drill);
        }
    }

    eliminarArchivo = (index, drill=false) => {
        if(drill){
            let archivos_drill = this.state.archivos_drill;
            archivos_drill = _.filter(archivos_drill, (item, i) => i != index );
    
            // Seteamos en redux y el state
            this.setState({ archivos_drill });
            this.props.eliminarArchivo(index, drill);
        }else{
            let archivos = this.state.archivos;
            archivos = _.filter(archivos, (item, i) => i != index );
    
            // Seteamos en redux y el state
            this.setState({ archivos });
            this.props.eliminarArchivo(index, drill);
        }
    }

    nextStep = () => {
        let step = this.state.step;
        console.log("step ", step)
        if(this.state.errors&&this.state.errors.length>0&&step==1){
            const growth_angels = '"'+`${this.state.errors.join(', ')}`+'"'
            const mensaje = `${this.props.t('Estos Growth Angels no están en la cohorte seleccionada ')} ${growth_angels}`
            console.log('-------------'+mensaje)
            SwalError(this.props.t('ERROR'), mensaje);

        }else{
            step += 1;
        }
        if (step == 2) {
            this.setState({step_name: this.props.t('Configuraciones') });
            this.setState({step_icon: 'cog' });
        }
        this.setState({ step });
        window.scroll(0, 0);
    }

    previousStep = () => {
        let step = this.state.step;
        step -= 1;
        if (step == 1) {
            this.setState({step_name: this.props.t('Info general') });
            this.setState({step_icon: 'file-alt' });
        }
        this.setState({ step });
        window.scroll(0, 0);
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values}
        const fecha = moment(body.fecha_inicio);
        if (body.cohorte.timezone) {
            body.fecha_inicio = moment.tz(`${fecha.format('YYYY-MM-DD')} ${body.fecha_hora_inicio}`, body.cohorte.timezone).format();
            body.fecha_fin = moment.tz(`${fecha.format('YYYY-MM-DD')} ${body.fecha_hora_fin}`, body.cohorte.timezone).format();
            if (body.fecha_limite_entregables)
                body.fecha_limite_entregables = moment.tz(`${moment(body.fecha_limite_entregables).format('YYYY-MM-DD HH:mm')}`, body.cohorte.timezone).format();
            if (body.bloque_slots && body.bloque_slots.length>0){
                body.bloque_slots = body.bloque_slots.map((item)=>({
                    id:item.id,
                    growth_angels:item.growth_angels,
                    fecha_inicio: moment.tz(`${moment(item.fecha_inicio).format('YYYY-MM-DD HH:mm')}`, body.cohorte.timezone).format(),
                    fecha_fin: moment.tz(`${moment(item.fecha_fin).format('YYYY-MM-DD HH:mm')}`, body.cohorte.timezone).format() }))
                if (this.props.item.cohorte&&this.props.item.cohorte.id!== body.cohorte.id){
                    body.bloque_slots = body.bloque_slots.map((item)=>({...item,id:`${item.id}-key`}));
                }
            }
        } else {
            body.fecha_inicio = moment(`${fecha.format('YYYY-MM-DD')} ${body.fecha_hora_inicio}`).format();
            body.fecha_fin = moment(`${fecha.format('YYYY-MM-DD')} ${body.fecha_hora_fin}`).format();
            if (body.fecha_limite_entregables)
            body.fecha_limite_entregables = moment(body.fecha_limite_entregables).format()
            if (body.bloque_slots && body.bloque_slots.length>0){
                body.bloque_slots = body.bloque_slots.map((item)=>({
                    id:item.id,
                    growth_angels:item.growth_angels,
                    fecha_inicio: moment(item.fecha_inicio).format(),
                    fecha_fin: moment(item.fecha_fin).format(),
                }))
            }
        }
        
        body.cohorte = body.cohorte.id;
        body.tipo_preworkshop = body.pre_workshop ? body.tipo_preworkshop : null;

        delete body.fecha_hora_inicio;
        delete body.fecha_hora_fin;
        delete body.hora_inicio;
        delete body.hora_fin;
        delete body.growth_angels;
        delete body.slots;

        if (body.bloque_slots && body.bloque_slots.length>0) {
            const array = [];
            array.push({ "file": this.state.imagen, "name": "imagen" });
            if (body.tiene_drill) {
                this.state.archivos_drill.forEach((file, index) => {
                    if (file != null) {
                        array.push({ "file": file, "name": `drill-${index}`});
                    }
                })
            }
            this.state.archivos.forEach((file, index)=>{
                if(file != null){
                    array.push({"file": file, "name": `archivo-${index}`});
                }
            })
            console.log("body ", body);
            if (id) {
                this.props.editar(id, body, array);
            } else {
                body.profesores = [];
                this.props.crear(body, array);
            }
        } else {
            NotificationManager.error(`${this.props.t('Tienes que asignar slots a la mentoria')}`, `${this.props.t('ERROR')}`, 3000);
        }

    }

    render() {
        const { loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-0">
                <div>
                    <LoadMask loading={loader} blur>
                        {(this.state.step == 1 && (
                            <PasoUnoForm
                                onSubmit={this.nextStep}
                                previousStep={this.previousStep}
                                cambiarFechaDrill={this.props.cambiarFechaDrill}
                                {...this.state}
                                agregarBloque={this.props.agregarBloque}
                                removerBloque={this.props.removerBloque}
                                t={this.props.t}
                                item={this.props.item}
                                setErrors={this.setErrors}
                                setArchivos={this.setArchivos}
                                eliminarArchivo={this.eliminarArchivo}
                                 />
                        ))}
                        {(this.state.step == 2 && (
                            <PasoDosForm
                                item={item} onSubmit={this.onSubmit} {...this.state}
                                previousStep={this.previousStep} setImagen={this.setImagen}
                                setArchivos={this.setArchivos} eliminarArchivo={this.eliminarArchivo} t={this.props.t}
                            />
                        ))}
                    </LoadMask>
                </div>
            </div>
        )
    }
}

export default withTranslation()(CrearMentoria)
