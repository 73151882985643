import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import { COUNTRIES } from '../../../utility/paises';
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { getI18n } from 'react-i18next';

const LOADER = 'LOADER_EMPRESAS';
const DATA = 'DATA_EMPRESAS';
const ITEM_DATA = 'ITEM_EMPRESAS';
const PAGE = 'PAGE_EMPRESAS';
const ORDERING = 'ORDERING_EMPRESAS';
const SEARCH = 'SEARCH_EMPRESAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().empresas;
    const params = { page };
    params.participantes='';
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('empresa/obtener_empresas', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, form=false) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`empresa/${id}`).then((response) => {
        dispatch(setItem(response));
        const data = {...response}
        if (data.pais) {
            const item = _.find(COUNTRIES, {name: data.pais});
            data.pais = item;
        }
        console.log("PRIMERO: ", data.sectores)
        if (data.sectores) {
            data.sectores = data.sectores.map(item => ({...item, value: item.tipo, label: item.tipo_text}))
        }
        console.log("SEGUNDO: ", data.sectores)
        dispatch(initializeForm('EmpresaForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('empresa', data).then(() => {
        NotificationManager.success(getI18n().t('Registro creado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/empresas'));
    }).catch((error) => {
        let mensaje = getI18n().t('Error en la creación');
        if (error && error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`empresa/${id}`, data).then(() => {
        NotificationManager.success(getI18n().t('Registro actualizado'), getI18n().t('Éxito'), 3000);
        dispatch(push('/empresas'));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la edición'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`empresa/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success(getI18n().t('Registro eliminado'), getI18n().t('Éxito'), 3000);
    }).catch(() => {
        NotificationManager.error(getI18n().t('Error en la transacción'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('SerieFacturaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().empresas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
