import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import {NotificationManager} from "react-notifications";
import { withTranslation, getI18n } from 'react-i18next';

import moment from "moment";
import "moment-timezone";

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';

class CrearEvento extends Component {

    state = {
        step: 1,
        step_name: `${getI18n().t('Info general')}`,
        step_icon: 'file-alt',
        imagen: null,
        edicion: false,
        archivos: [],
        archivos_drill :[],
    }

    componentWillMount() {
        this.props.cleanForm()
        const id = this.props.match.params.id;
        if (id) {
            this.props.leer(id, this.preSetArchivos);
            this.setState({ edicion: true });
        }
    }

    componentWillUnmount() {
        this.props.cleanForm();
    }

    setImagen = (imagen) => {
        this.setState({ imagen });
    }

    preSetArchivos = (archivos_existentes, drill=false) => {
        if(drill){
            const archivos_drill = [];
            for (const item of archivos_existentes) {
                archivos_drill.push(null);
            }
    
            this.setState({ archivos_drill });
        }else{
            const archivos = [];
            for (const item of archivos_existentes) {
                archivos.push(null);
            }
    
            this.setState({ archivos });
        }
    }

    setArchivos = (files, drill=false) => {
        if(drill){
            console.log("files ", files);
            const archivos_drill = this.state.archivos_drill;
            for (let index = 0; index < files.length; index++) {
                archivos_drill.push(files[index]);
            }
            // Seteamos en redux y el state
            this.setState({ archivos_drill });
            this.props.setArchivos(files, drill);
        }else{
            console.log("files ", files);
            const archivos = this.state.archivos;
            for (let index = 0; index < files.length; index++) {
                archivos.push(files[index]);
            }
            // Seteamos en redux y el state
            this.setState({ archivos });
            this.props.setArchivos(files, drill);
        }
    }

    eliminarArchivo = (index, drill=false) => {
        if(drill){
            let archivos_drill = this.state.archivos_drill;
            archivos_drill = _.filter(archivos_drill, (item, i) => i != index );
    
            // Seteamos en redux y el state
            this.setState({ archivos_drill });
            this.props.eliminarArchivo(index, drill);
        }else{
            let archivos = this.state.archivos;
            archivos = _.filter(archivos, (item, i) => i != index );
    
            // Seteamos en redux y el state
            this.setState({ archivos });
            this.props.eliminarArchivo(index, drill);
        }
    }

    nextStep = () => {
        let step = this.state.step;
        console.log("step ", step)
        step += 1;
        if (step == 2) {
            this.setState({step_name: `${getI18n().t('Configuraciones')}` });
            this.setState({step_icon: 'cog' });
        }
        this.setState({ step });
        window.scroll(0, 0);
    }

    previousStep = () => {
        let step = this.state.step;
        step -= 1;
        if (step == 1) {
            this.setState({step_name: `${getI18n().t('Info general')}` });
            this.setState({step_icon: 'file-alt' });
        }
        this.setState({ step });
        window.scroll(0, 0);
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values}
        const fecha = moment(body.fecha_inicio);
        if (body.cohorte.timezone) {
            body.fecha_inicio = moment.tz(`${fecha.format('YYYY-MM-DD')} ${body.hora_inicio}`, body.cohorte.timezone).format();
            body.fecha_fin = moment.tz(`${fecha.format('YYYY-MM-DD')} ${body.hora_fin}`, body.cohorte.timezone).format();
            if (body.fecha_limite_entregables)
                body.fecha_limite_entregables = moment.tz(`${moment(body.fecha_limite_entregables).format('YYYY-MM-DD HH:mm')}`, body.cohorte.timezone).format();    
        } else {
            body.fecha_inicio = moment(`${fecha.format('YYYY-MM-DD')} ${body.hora_inicio}`).format();
            body.fecha_fin = moment(`${fecha.format('YYYY-MM-DD')} ${body.hora_fin}`).format();
            if (body.fecha_limite_entregables)
                body.fecha_limite_entregables = moment(body.fecha_limite_entregables).format()
        }

        body.tipo_preworkshop = body.pre_workshop ? body.tipo_preworkshop : null;
        body.cohorte = body.cohorte.id;

        delete body.hora_inicio;
        delete body.hora_fin;

        if (body.profesores && body.profesores.length) {

            const array = [];
            array.push({ "file": this.state.imagen, "name": "imagen" });
            if (body.tiene_drill) {
                this.state.archivos_drill.forEach((file, index) => {
                    if (file != null) {
                        array.push({ "file": file, "name": `drill-${index}`});
                    }
                })
            }
            this.state.archivos.forEach((file, index)=>{
                if(file != null){
                    array.push({"file": file, "name": `archivo-${index}`});
                }
            })
            console.log("body ", body);
            if (id) {
                this.props.editar(id, body, array);
            } else {
                body.profesores = body.profesores.map(item => ({profesor: item.profesor.id}));
                this.props.crear(body, array);
            }
        } else {
            NotificationManager.error(this.props.t('Seleccione al menos un Growth Angel'), this.props.t('ERROR'), 3000);
        }

    }

    render() {
        const { loader, item } = this.props;
        const { step, step_name, step_icon, imagen, edicion, archivos } = this.state;
        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <div className="d-flex flex-row mb-3">
                        <div className="mr-2">
                            <i className={`fas fa-${step_icon} azul-gris-oscuro`} style={{fontSize: "2.5rem"}}></i>
                        </div>
                        <div className="d-flex flex-column">
                            <h4 className="mb-0 bold azul-gris-oscuro">{`${this.props.t('Paso')} ${step} ${this.props.t('de')} ${2}`}</h4>
                            <span className="gris-oscuro text-sm">{`${step_name}`}</span>
                        </div>
                    </div>
                    <LoadMask loading={loader} blur>
                        {(this.state.step == 1 && (
                            <PasoUnoForm
                                onSubmit={this.nextStep}
                                previousStep={this.previousStep}
                                cambiarFechaDrill={this.props.cambiarFechaDrill}
                                t={this.props.t}
                                item={this.props.item}
                                removerGrowthAngel={this.props.removerGrowthAngel}
                                setArchivos={this.setArchivos}
                                eliminarArchivo={this.eliminarArchivo}  />
                        ))}
                        {(this.state.step == 2 && (
                            <PasoDosForm
                                item={item} edicion={edicion} onSubmit={this.onSubmit}
                                previousStep={this.previousStep} imagen={imagen} setImagen={this.setImagen}
                                setArchivos={this.setArchivos} eliminarArchivo={this.eliminarArchivo} t={this.props.t}
                            />
                        ))}
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(CrearEvento)
