import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { validate } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderCountrySelect, renderTextArea, SelectField, renderDatePicker3, renderField, renderFieldCheck } from '../../Utils/renderField/renderField';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import Swal from 'sweetalert2';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../Utils/Grid";
import { MODELOS, TIPO_SECTORES } from '../../../../utility/constants';

const validators = (values, props) => {
    const errors = {};
    if (!values.cohorte) {
        errors.cohorte = `${props.t('Campo requerido')}`;
    }
    if (!values.empresa) {
        errors.empresa = `${props.t('Campo requerido')}`;
    }
    if (values.colaboradores && values.colaboradores.length > 0){
        const colaboradores = []
        values.colaboradores.forEach(colaborador => {
            const errors_colabs = {}
            if (!colaborador.nombres) {
                errors_colabs.nombres = `${props.t('Campo requerido')}`
            }
            if (!colaborador.email) {
                errors_colabs.email = `${props.t('Campo requerido')}`
            }
            if (values.colaboradores.filter(col => col.email === colaborador.email).length > 1) {
                errors_colabs.email = `${props.t('El email debe ser único')}`
            }
            if (colaborador.colider && values.colaboradores.filter(col => col.colider === true).length > 1) {
                errors_colabs.email = `${props.t('Solamente se permite 1 colíder')}`
            }
            colaboradores.push(errors_colabs)
        })
        errors.colaboradores = colaboradores
    }
    return errors;
}

const renderColaboradores = ({fields, t, elminarColab, loader, selected_empresa, selected_cohorte, data, colaboradores, colabs, meta: { error }}) => {
    console.log("EL LOADER--->", loader)
    if(fields.length == 0 && selected_empresa && !loader){
        colaboradores.forEach(colaborador => {
            const {
                id,
                nombre,
                email,
                colider,
                talleres,
                is_active,
            } = colaborador
            fields.push({
                id,
                nombres: nombre,
                talleres,
                email,
                colider,
                is_active,
                disabled: true,
            })
        })
    }

    const span_class = "text-sm p-2 bold"

    return(
        <div className='d-flex flex-column'>
            {fields && fields.length ?
            <div className='d-flex flex-row flex-1' >
                <div className='col-12' style={{ backgroundColor: "#0A1F40", color: "white", borderRadius: 4 }} >
                    <div className='row'>
                        <div className='col-1' >
                            <div className="d-flex justify-content-between" >
                                <span className={`${span_class}`}>{t('Acciones')}</span>
                            </div>
                        </div>
                        <div className='col' >
                            <div className="d-flex justify-content-between" >
                                <span className={`${span_class}`}>{t('Nombre')}</span>
                            </div>
                        </div>
                        <div className='col' >
                            <div className="d-flex justify-content-between" >
                                <span className={`${span_class}`}>{t('Correo')}</span>
                            </div>
                        </div>
                        <div className='col-1' >
                            <div className="d-flex justify-content-between" >
                                <span className={`${span_class}`}>{t('Colíder')}</span>
                            </div>
                        </div>
                        <div className='col-1' >
                            <div className="d-flex justify-content-between" >
                                <span className={`${span_class}`}>{t('Estado')}</span>
                            </div>
                        </div>
                        <div className='col' >
                            <div className="d-flex justify-content-between" >
                                <span className={`${span_class}`}>{t('Talleres')}</span>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>: null
            }
            {fields.map((item, index)=>(
                <div key={index} className='pb-4' >
                    <div className='d-flex flex-row flex-1' >
                        <div className='col-12' >
                            <div className='row'>
                                <div className="col-1 pt-4 pl-4">
                                    <button className="btn-delete" onClick={
                                        (e) => 
                                        { 
                                            e.preventDefault(); 
                                            let id = colabs[index].id
                                            if(id){
                                                Swal.fire({
                                                    title: t('¿Eliminar?'),
                                                    text: t('¡No podrá revertir esta acción!'),
                                                    type: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonText: t('¡Sí, eliminar!'),
                                                    cancelButtonText: t('No, cancelar'),
                                                    reverseButtons: true
                                                }).then((result) => {
                                                    if (result.value) {
                                                        elminarColab(colabs[index].id) 
                                                        setTimeout(() => {
                                                            fields.remove(index)
                                                        }, 80);
                                                    }
                                                });
                                            } else {
                                                fields.remove(index)
                                            }
                                        }
                                    }>
                                        <i className="fas fa-times marron" />
                                    </button>
                                </div>
                                <div className='col pt-2' >
                                    <Field name={`${item}.nombres`} component={renderField} type='text' disabled={colabs[index] && colabs[index].disabled}/>
                                </div>
                                <div className='col pt-2' >
                                    <Field name={`${item}.email`} component={renderField} type='email' disabled={colabs[index] && colabs[index].disabled} />
                                </div>
                                {console.log('HOLA MUNDO', colabs[index])}
                                <div className='col-1 pt-4' >
                                    <Field name={`${item}.colider`} component={renderFieldCheck} label={colabs[index] && colabs[index].colider ? t('Si') : t('No')} /*disabled={colabs[index] && colabs[index].disabled}*/ />
                                </div>
                                <div className='col-1 pt-4'>
                                    {colabs[index] ? 
                                        colabs[index].is_active == true ?
                                            <span className="verde">{t('Registrado')}</span> :
                                        colabs[index].is_active == false ? 
                                            <span className="marron">{t('Invitado')}</span> :
                                        null : 
                                    null
                                    }
                                </div>
                                <div className='col pt-2' >
                                    <Field
                                        name={`${item}.talleres`}
                                        isMulti
                                        options={
                                            selected_cohorte && data && data.length > 0 ?
                                            data.filter(cohorte => cohorte.id === selected_cohorte)[0].talleres :
                                            []
                                        }
                                        component={SelectField}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {selected_empresa && 
                <div className="d-flex align-items-center justify-content-end">
                    <button 
                        type="button"
                        disabled={colabs ? colabs.length>=10 : false } 
                        className="btn btn-secondary-fill btn-block w-25" 
                        onClick={() => fields.push({colider:false})}>
                            <span className='text-sm'>{t('Agregar Colaborador')}</span>
                    </button>
                </div>
            }
        </div>
    )
}

let InvitacionesMasivasForm = props => {
    const { handleSubmit, data, colaboradores, selected_cohorte, selected_empresa, change, colabs, elminarColab, loader, loaderColaboradores, buscarColaborador } = props;
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column flex-1">
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre" className="m-0">{props.t('Cohorte')}</label>
                        <Field
                            name="cohorte"
                            options={data}
                            component={SelectField}
                            onCambio={(e) => {
                                change('empresa', null)
                                change('talleres', null)
                                change('colaboradores', [])
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column flex-1 p-2">
                        <label htmlFor="nombre_admin" className="m-0">{props.t('Empresa')}</label>
                        <Field
                            name="empresa"
                            options={
                                selected_cohorte && data && data.length > 0 ?
                                data.filter(cohorte => cohorte.id === selected_cohorte)[0].empresas :
                                []
                            }
                            component={SelectField}
                            onCambio={(e) => {
                                change('colaboradores', [])
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column flex-wrap px-0 mt-2">
                    <div className="d-flex flex-column flex-1 p-2">
                        <div className='row'>
                            <div className='col'>
                                <label htmlFor="nombre_admin" className="m-0">{props.t('¿Reenviar invitación de registro?')}</label>
                                <Field 
                                    name={`reenviar_registro`} 
                                    component={renderFieldCheck}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-lg-row flex-column px-0 mt-2">
                    <div className="d-flex flex-column flex-1">
                        <FieldArray 
                            name='colaboradores' 
                            component={renderColaboradores} 
                            t={props.t}
                            selected_empresa={selected_empresa}
                            colaboradores={
                                data && selected_cohorte && selected_empresa ?
                                data.filter(cohorte => cohorte.id === selected_cohorte)[0].
                                empresas.filter(empresa => empresa.id === selected_empresa)[0].
                                colaboradores.filter(colaborador => colaborador.admin_empresa === false) :
                                []
                            }
                            elminarColab={elminarColab}
                            selected_cohorte={selected_cohorte}
                            data={data}
                            colabs={colabs}
                            loader={loader}
                        />
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <Link className="btn btn-secondary mr-3" to="/">
                        <span className='text-sm'>{props.t('Cancelar')}</span>
                    </Link>
                    <button type="submit" className="btn btn-primary ml-3">
                        <span className='text-sm'>{props.t('Guardar')}</span>
                    </button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <Link className="btn btn-secondary flex-1" to="/">
                        <span className='text-sm'>{props.t('Cancelar')}</span>
                    </Link>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">
                        <span className='text-sm'>{props.t('Guardar')}</span>
                    </button>
                </div>
                <LoadMask loading={loaderColaboradores} blur>
                    <div className="d-none d-lg-flex buttons-box mt-4 mb-4">
                        <input
                            placeholder={props.t('Buscar')}
                            type="text"
                            className='form-control'
                            value={search}
                            onChange={e => {
                                const text = e.target.value
                                setSearch(text)
                                //buscarColaborador(text)
                            }}
                            onKeyPress={key => {
                                if(key.key === "Enter"){
                                    key.preventDefault()
                                    buscarColaborador(search, 1)
                                }
                            }}
                        />
                    </div>
                    {colaboradores && colaboradores.results ? 
                        <Grid hover striped data={colaboradores} page={page} loading={loaderColaboradores} onPageChange={(page) => {
                            buscarColaborador(search, page)
                            setPage(page)
                        }}>
                            <TableHeaderColumn
                                isKey
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={(cell, row) => (
                                    <div className="d-flex justify-content-center">
                                        <a 
                                            title={props.t('Eliminar')} 
                                            className="action action-gris px-1 py-2" 
                                            style={{cursor: "pointer"}} 
                                            onClick={() => {
                                                Swal.fire({
                                                    title: props.t('¿Eliminar?'),
                                                    text: props.t('¡No podrá revertir esta acción!'),
                                                    type: 'warning',
                                                    showCancelButton: true,
                                                    confirmButtonText: props.t('¡Sí, eliminar!'),
                                                    cancelButtonText: props.t('No, cancelar'),
                                                    reverseButtons: true
                                                }).then((result) => {
                                                    if (result.value) {
                                                        elminarColab(cell, search, page)
                                                    }
                                                });
                                            }}
                                        >
                                            <i className="fas fa-times gris"></i>
                                        </a>
                                    </div>
                                )}
                            >
                                {props.t('Acciones')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="nombres"
                                dataSort
                            >
                                {props.t('Nombre')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="email"
                                dataSort
                            >
                                {props.t('Correo')}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="is_active"
                                dataSort
                                dataFormat={ cell => cell ? <span className="verde">{props.t('Registrado')}</span> : <span className="marron" >{props.t('Invitado')}</span>}
                            >
                                {props.t('Estado')}
                            </TableHeaderColumn>
                        </Grid> : null}
                </LoadMask>
            </div>
        </form>
    )

}

InvitacionesMasivasForm = reduxForm({
    form: 'InvitacionesMasivasForm',
    asyncBlurFields: [],
    initialValues: {
        // fecha_fundacion: moment()
    },
    validate: validators,
})(withTranslation()(InvitacionesMasivasForm));

const selector = formValueSelector('InvitacionesMasivasForm')

InvitacionesMasivasForm = connect(state => {
    const selected_cohorte = selector(state, 'cohorte')
    const selected_empresa = selector(state, 'empresa')
    const colabs = selector(state, 'colaboradores')

    return{
        selected_cohorte,
        selected_empresa,
        colabs,
    }
})(InvitacionesMasivasForm)

export default InvitacionesMasivasForm
