import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { SwalError, SwalExito } from '../../../common/components/Utils/Notificaciones';
import { getI18n } from 'react-i18next';

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/login_admin', data).then((response) => {
        localStorage.setItem('token', response.token);
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
    }).catch(() => {
        NotificationManager.error(getI18n().t('Credenciales incorrectas, vuelva a intentar'), getI18n().t('ERROR'), 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/user/me').then(me => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
    })
        .catch(() => {
    }).finally(() => {});
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {});
    localStorage.removeItem('token');
};

export const newToken = (token) => (dispatch) => {
    const body = { token };
    api.post('fcm', body).catch((error) => { }).then((data) => {
    });
};

const verificarToken=(token)=>(dispatch)=>{
    dispatch(setLoader(true))
    const params= { token };
    return api.post('user/verificar_token/', params).then((response)=>{
        return response;
    }).catch(()=>{
        SwalError(getI18n().t('Error'), getI18n().t('El link se vencido o es invalido')).then(()=>dispatch(push('/')))
    }).finally(()=>dispatch(setLoader(false)))
}

const cambiarPwd = (email, contraseña)=>(dispatch)=>{
    dispatch(setLoader(true))
    const params = { email, contraseña }
    api.post('user/cambio_contraseña/', params).then(()=>{
        SwalExito(getI18n().t('Éxito'), getI18n().t('Cambio de contraseña Exitoso')).then((e)=>dispatch(push('/login')))
    }).catch(()=>{
        NotificationManager.error(getI18n().t('Error al cambiar la contraseña'), getI18n().t('ERROR'), 3000);
    }).finally(()=>dispatch(setLoader(false)))
}

const SolicitarPwd =(email)=>(dispatch)=>{
    dispatch(setLoader(true))
    const admins = true;
    const params = { email, admins };
    api.post('user/recuperar_contraseña/', params).then(()=>{
        SwalExito(getI18n().t('Éxito'), getI18n().t('Se te ha enviado un correo para continuar con el proceso')).then((e)=>dispatch(push('/login')))
    }).catch(()=>{
        SwalError(getI18n().t('Error'), getI18n().t('Correo invalido'))

    }).finally(()=>dispatch(setLoader(false)))
}
export const cambiarIdioma=(data)=>(dispatch)=>{
    api.post('user/cambiar_idioma',data).then(()=>{
    }).catch(()=>{})
}

export const actions = {
    onSubmit,
    logOut,
    newToken,
    cambiarPwd,
    verificarToken,
    SolicitarPwd,
    cambiarIdioma,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
