import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import AdminForm from '../FormAdmin';
import moment from 'moment';

import { withTranslation } from 'react-i18next';


class CrearAdmin extends Component {
    componentDidMount=()=>{
        const {me}= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }
    }

    onSubmit = (values) => {
        const body = {...values};
        delete body.verificar_password;
        this.props.crear(body);
    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <AdminForm onSubmit={this.onSubmit} t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(CrearAdmin);