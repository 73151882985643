import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/reportes/empresas';
import reporteEmpresas from './reporteEmpresas';

const ms2p = (state) => {
    return {
        ...state.reporteEmpresas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(reporteEmpresas);
