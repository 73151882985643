import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CardForm from '../../../Utils/Cards/CardForm';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderField } from '../../../Utils/renderField/renderField';
import moment from 'moment';
import i18n from '../../../../../i18nextInit'


const renderPreguntas = ({ fields, t,  meta: { error } }) => (
    <div className="d-flex flex-1 flex-column">
        {fields.map((item, index) => (
            <div key={index} className="d-flex flex-1 flex-row">
                <div className="d-flex flex-column flex-4 mb-3">
                    <label htmlFor={`${item}.pregunta`} className="m-0">{`${t('Declaración')} No. ${index + 1}`} <span className="gris m-0 text-xsm">({t('Obligatorio')})</span></label>
                    <div className="d-flex flex-row flex-1">
                        <div className="flex-4">
                            <Field name={`${item}.pregunta`} component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="d-flex flex-1 ml-2 ml-lg-0 justify-content-center align-items-center">
                            {((index + 1) === fields.length) ? (
                                <div>
                                    <button type="button" className="btn-action bg-marron-claro mr-1" onClick={() => fields.push({})}>
                                        <i className="fas fa-plus blanco" />
                                    </button>
                                    {(fields.length > 1) && (
                                        <button type="button" className="btn-action bg-gris ml-1" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>
                                            <i className="fas fa-times blanco" />
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <button type="button" className="btn-action bg-gris" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>
                                    <i className="fas fa-times blanco" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
)

const renderBloques = ({ fields, url, t, meta: { error }, id }) => (
    <div className="d-flex flex-1 flex-column align-items-center">
        {fields.map((item, index) => (
            <div key={index} className="w-100">
                <CardForm noPaddingYContent={true} noPaddingXContent={true}>
                    <div className="d-flex flex-column flex-1 my-3">
                        <div className="d-flex flex-column flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-1 p-2">
                                <label htmlFor="fecha_inicio" className="m-0">{`${t('Bloque')} No. ${index + 1}`} <span className="gris m-0 text-xsm">({t('Obligatorio')})</span></label>
                                <Field name={`${item}.nombre`} component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="d-flex flex-row flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-2 p-2">
                                <FieldArray name={`${item}.preguntas`} component={renderPreguntas} t={t}/>
                            </div>
                            <div className="d-none d-lg-flex flex-1"></div>
                        </div>
                        {((index + 1) === fields.length) ? (
                            <div className="d-flex flex-column flex-1 mx-2">
                                <div className="buttons-box flex-lg-row flex-column">
                                    <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-one" onClick={() => fields.push({preguntas: [{}]})}>{t('Agregar bloque')}</button>
                                    <button type="button" className="w-100 d-flex d-lg-none flex-1 btn btn-option-one" onClick={() => fields.push({preguntas: [{}]})}>{t('Agregar bloque')}</button>
                                    {(fields.length > 1) && (
                                        <Fragment>
                                            <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-two-outline ml-1" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar bloque')}</button>
                                            <button type="button" className="w-100 d-flex d-lg-none flex-1 btn btn-option-two-outline mt-1" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar bloque')}</button>
                                        </Fragment>
                                    )}
                                </div>
                                <div className="form-group d-flex flex-column flex-1">
                                    <div className="d-none d-lg-flex buttons-box mt-4">
                                        <Link to={`/${url.includes("taller") ? 'taller' : 'mentoria'}/${id}/evaluaciones`} className="btn btn-secondary mr-3">{t('Cancelar')}</Link>
                                        <button type="submit" className="btn btn-primary ml-3">{t('Guardar')}</button>
                                    </div>
                                    <div className="d-flex d-lg-none flex-column mt-4">
                                        <Link to={`/${url.includes("taller") ? 'taller' : 'mentoria'}/${id}/evaluaciones`} className="btn btn-secondary flex-1">{t('Cancelar')}</Link>
                                        <button type="submit" className="btn btn-primary flex-1 mt-3">{t('Guardar')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column flex-1 mx-2">
                                <div className="buttons-box">
                                    <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-one" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar bloque')}</button>
                                    <button type="button" className="d-flex d-lg-none flex-1 btn btn-option-one" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar bloque')}</button>
                                </div>
                            </div>
                        )}
                    </div>
                </CardForm>
            </div>
        ))}
        {(!fields.length) && (
            <div className="w-100">
                <CardForm noPaddingYContent={true} noPaddingXContent={true}>
                    <div className="d-flex flex-column my-3 mx-2">
                        <div className="buttons-box">
                            <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-one" onClick={() => fields.push({preguntas: [{}]})}>{t('Agregar bloque')}</button>
                            <button type="button" className="d-flex d-lg-none flex-1 btn btn-option-one" onClick={() => fields.push({preguntas: [{}]})}>{t('Agregar bloque')}</button>
                        </div>
                        <div className="form-group d-flex flex-column flex-1">
                            <div className="d-none d-lg-flex buttons-box mt-4">
                                <Link to={`/${url.includes("taller") ? 'taller' : 'mentoria'}/${id}/evaluaciones`} className="btn btn-secondary mr-3">{t('Cancelar')}</Link>
                                <button type="submit" className="btn btn-primary ml-3">{t('Guardar')}</button>
                            </div>
                            <div className="d-flex d-lg-none flex-column mt-4">
                                <Link to={`/${url.includes("taller") ? 'taller' : 'mentoria'}/${id}/evaluaciones`} className="btn btn-secondary flex-1">{t('Cancelar')}</Link>
                                <button type="submit" className="btn btn-primary flex-1 mt-3">{t('Guardar')}</button>
                            </div>
                        </div>
                    </div>
                </CardForm>
            </div>
        )}
    </div>
)

let FormEvaluacion = props => {
    const { handleSubmit, taller, id, url, me } = props;
    const lang = i18n.language ? i18n.language : 'en'
    moment.locale(lang)
    return (
        <form onSubmit={handleSubmit}>
            <CardForm noPaddingXContent={true} noPaddingYContent={true}>
                <div className="d-flex flex-lg-row flex-column justify-content-center align-items-lg-center px-lg-2 my-3">
                    <div className="d-flex flex-column flex-1">
                        <span className="text-sm">{props.t('Panel') }:</span>
                        <span className="celeste-oscuro bold text-sm">{taller ? taller.titulo : ''}</span>
                    </div>
                    <div className="d-flex flex-column flex-1">
                        <span className="text-sm">{props.t('Fecha a realizarse')}:</span>
                        <span className="celeste-oscuro bold text-sm"> 
                            {(me&&me.timezone) ? moment(taller.fecha_inicio).tz(me.timezone).format("MMM-DD-YYYY hh:mm a z") : moment(taller.fecha_inicio).format("MMM-DD-YYYY hh:mm a")}
                        </span>
                    </div>
                </div>
                <div className="form-group d-flex flex-lg-row flex-column px-lg-2 flex-1">
                    <div className="d-flex flex-column flex-1 flex-wrap px-0 mt-2">
                        <label htmlFor="nombre" className="m-0">{props.t('Titulo')} <span className="gris m-0 text-xsm">({props.t('Obligatorio')})</span></label>
                        <Field name="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
            </CardForm>
            <div className="d-flex flex-column flex-wrap px-0 mt-2">
                <div className="d-flex flex-column flex-1 mt-2">
                    <FieldArray name="bloques" component={renderBloques} id={id} url={url}  t={props.t} />
                </div>
            </div>
        </form>
    )

}

const validators = (values, props)=>{
    const errors= [];
    if (!values.nombre){
        errors.nombre= `${props.t('Campo requerido')}`;
    }
    if (values.bloques && values.bloques.length) {
        const bloques = [];

        values.bloques.forEach((bloque, indexB) => {
            const preguntas = [];

            if (bloque.preguntas && bloque.preguntas.length) {
                bloque.preguntas.forEach((pregunta, indexP) => {
                    if (!pregunta.pregunta) {
                        preguntas[indexP] = { pregunta: `${props.t('Campo requerido')}` }
                    }
                });
            }

            if (!bloque.nombre) {
                bloques[indexB] = { preguntas, nombre: `${props.t('Campo requerido')}`}

            } else {
                bloques[indexB] = { preguntas }
            }
        });

        errors.bloques = bloques;
    }
    return errors;
}

const selector = formValueSelector('EvaluacionForm');

FormEvaluacion = reduxForm({
    form: 'EvaluacionForm',
    asyncBlurFields: [],
    initialValues: {
        bloques: [{ preguntas: [{}] }]
    },
    validate: validators,
})(FormEvaluacion);

const mstp = state => {
    const nombre = selector(state, 'nombre');

    return {
        nombre,
    }
};

export default connect(mstp, null)(FormEvaluacion);
