import React, { Component } from 'react';
import Card from '../../../Utils/Cards/Card';
import { withTranslation } from 'react-i18next';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { Link } from 'react-router-dom';

const fondo = require('../../../../../../assets/img/fondo-perfil.png');
const avatar = require("../../../../../../assets/img/avatar.png");


class VerPerfil extends Component {

    componentDidMount() {
        const { leer } = this.props;
        leer();
    }

    render() {
        const { item, loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-1">
                            <div className="perfil-portada" style={{backgroundImage: `url(${fondo})`}}>
                                <Link to="/perfil/editar" className="btn-action bg-marron-claro d-flex justify-content-center align-items-center float-right m-2">
                                    <i className="fas fa-pen-alt blanco"></i>
                                </Link>
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-1">
                                <div className="d-flex flex-1 justify-content-center perfil-avatar">
                                    <img className="perfil-avatar-img" src={item.avatar ? item.avatar : avatar} />
                                </div>
                                <div className="d-flex flex-column flex-md-row flex-3 align-items-center mb-3 perfil-datos">
                                    <div className="d-flex flex-column align-items-md-start align-items-center flex-2">
                                        <span className="text-md azul-gris-oscuro mt-3 mt-md-0">{item.nombres}</span>
                                        <span className="text-sm azul-gris-oscuro">{this.props.t('Administrador')}</span>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row flex-2 mt-3 mt-md-0 px-3 px-sm-4 px-md-0 w-100">
                                        <div className="d-flex flex-column flex-1">
                                            <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Teléfono')}</label>
                                            <span className="celeste-oscuro">{item.telefono ? item.telefono : this.props.t('No específicado')}</span>
                                        </div>
                                        <div className="d-flex flex-column flex-1">
                                            <label className="text-sm azul-gris-oscuro p-0 mb-0">{this.props.t('Correo')}</label>
                                            <span className="celeste-oscuro">{item.email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-1 my-3 px-3 px-sm-4 px-lg-5">
                                <div className="d-flex flex-column flex-wrap px-0 px-lg-4">
                                    <span className="text-sm azul-gris-oscuro bold">{this.props.t('Biografía')}</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <div className="d-flex flex-1 mt-3 px-lg-4">
                                    <p>{item.descripcion ? item.descripcion : this.props.t('Sin descripción')}</p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </LoadMask>
            </div>

        )
    }
}

export default withTranslation()(VerPerfil)

