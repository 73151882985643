import ListarEncuentro  from './Listar';
import CrearEncuentro  from './Crear';
import ListarEvaluaciones  from './ListarEvaluaciones';
import CrearEvaluaciones  from './CrearEvaluaciones';

export default {
    ListarEncuentro,
    CrearEncuentro,
    ListarEvaluaciones,
    CrearEvaluaciones,
}