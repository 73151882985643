import React, { Component } from 'react';
import {NotificationManager} from "react-notifications";
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import MembresiaForm from './MembresiaForm';
import { withTranslation } from 'react-i18next';

class CrearMembresia extends Component {

    componentDidMount=()=>{
        const id = this.props.match.params.id;
        const {me}= this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            if (id) this.props.leer(id);
        }
    }


    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values};
        if (body.pais) body.pais = body.pais.name;

        if (parseFloat(body.precio) <= 0) {
            NotificationManager.error(`${this.props.t('Precio debe ser mayor a 0')}`,`${this.props.t('ERROR')}`, 3000);
        } else {
            if (id) {
                this.props.editar(body, id);
            } else {
                this.props.crear(body);
            }
        }

    }

    render() {
        const { loader } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <MembresiaForm {...this.props} onSubmit={this.onSubmit} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(CrearMembresia)
