import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CardForm from '../../../Utils/Cards/CardForm';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderField, SelectField } from '../../../Utils/renderField/renderField';
import _ from 'lodash';


const getEmpresas = (grupos, empresas, empresa_seteada) => {
    // console.log("grupo ", grupos.empresas);
    // console.log("empresas ", empresas);
    console.log("empresa_seteada ", empresa_seteada);

    const empresas_asignadas = [];
    for (const grupo of grupos) {
        for (const empresa of grupo.empresas) {
            empresas_asignadas.push({...empresa})
        }
    }

    const empresas_filtradas = _.filter(empresas, (item) => {
        const existe = _.find(empresas_asignadas, { 'id': item.id });
        console.log("existe ", existe);
        return existe ? existe.id == empresa_seteada.id ? true : false : true;
    })

    return empresas_filtradas;

}

const renderEmpresas = ({ fields, t, grupos, empresas, empersas_grupo, meta: { error } }) => (
    <div className="d-flex flex-1 flex-column">
        {fields.map((item, index) => (
            <div key={index} className="d-flex flex-1 flex-row">
                <div className="d-flex flex-column flex-4 mb-3">
                    <label htmlFor={`${item}.pregunta`} className="m-0">{`${t('Empresa')} ${index + 1}`} <span className="gris m-0 text-xsm">({t('Obligatorio')})</span></label>
                    <div className="d-flex flex-row flex-1">
                        <div className="flex-3">
                            <Field
                                name={`${item}.id`}
                                // options={empresas}
                                options={getEmpresas(grupos, empresas, empersas_grupo[index])}
                                component={SelectField}
                                labelKey="empresa_nombre"
                                valueKey="id"
                            />
                        </div>
                        <div className="d-flex flex-1 ml-2 ml-lg-0 justify-content-center align-items-center">
                            {((index + 1) === fields.length && fields.length < 4) ? (
                                <div>
                                    <button type="button" className="btn-action bg-marron-claro mr-1" onClick={() => fields.push({})}>
                                        <i className="fas fa-plus blanco" />
                                    </button>
                                    <button type="button" className="btn-action bg-gris ml-1" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>
                                        <i className="fas fa-times blanco" />
                                    </button>
                                </div>
                            ) : (
                                <button type="button" className="btn-action bg-gris" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>
                                    <i className="fas fa-times blanco" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
)

const renderGrupos = ({ fields, t, grupos, empresas, meta: { error } }) => (
    <div className="d-flex flex-1 flex-column align-items-center">
        {fields.map((item, index) => (
            <div key={index} className="w-100">
                <CardForm key={index} noPaddingYContent={true} noPaddingXContent={true}>
                    <div className="d-flex flex-column flex-1 my-3">
                        <div className="d-flex flex-column flex-1 p-2">
                            <span className="text-sm azul-gris-oscuro bold">{`${t('Grupo')} ${index + 1}`} <span className="gris m-0 text-xsm">({t('Obligatorio')})</span></span>
                            <div className="d-flex w-100 bg-gris" style={{ height: '2px' }} />
                        </div>
                        <div className="d-flex flex-row flex-wrap px-0 mt-2">
                            <div className="d-flex flex-column flex-2 p-2">
                                <FieldArray name={`${item}.empresas`} component={renderEmpresas} empersas_grupo={grupos[index].empresas} grupos={grupos} empresas={empresas} t={t}/>
                            </div>
                            <div className="d-none d-lg-flex flex-1"></div>
                        </div>
                        {((index + 1) === fields.length) ? (
                            <div className="d-flex flex-column flex-1 mx-2">
                                <div className="buttons-box flex-lg-row flex-column">
                                    <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-one mr-1" onClick={() => fields.push({empresas: [{}]})}>{t('Agregar grupo')}</button>
                                    <button type="button" className="w-100 d-flex d-lg-none flex-1 btn btn-option-one mb-1" onClick={() => fields.push({empresas: [{}]})}>{t('Agregar grupo')}</button>
                                    <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-two-outline ml-1" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar grupo')}</button>
                                    <button type="button" className="w-100 d-flex d-lg-none flex-1 btn btn-option-two-outline mt-1" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar grupo')}</button>
                                </div>
                                <div className="form-group d-flex flex-column flex-1">
                                    <div className="d-none d-lg-flex buttons-box mt-4">
                                        <Link to={`/cohortes`} className="btn btn-secondary mr-3">{t('Cancelar')}</Link>
                                        <button type="submit" className="btn btn-primary ml-3">{t('Guardar')}</button>
                                    </div>
                                    <div className="d-flex d-lg-none flex-column mt-4">
                                        <Link to={`/cohortes`} className="btn btn-secondary flex-1">{t('Cancelar')}</Link>
                                        <button type="submit" className="btn btn-primary flex-1 mt-3">{t('Guardar')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex flex-column flex-1 mx-2">
                                <div className="buttons-box">
                                    <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-two-outline" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar grupo')}</button>
                                    <button type="button" className="d-flex d-lg-none flex-1 btn btn-option-two-outline" onClick={(e) => { e.preventDefault(); fields.remove(index) }}>{t('Eliminar grupo')}</button>
                                </div>
                            </div>
                        )}
                    </div>
                </CardForm>
            </div>
        ))}
        {(!fields.length) && (
            <div className="w-100">
                <CardForm noPaddingYContent={true} noPaddingXContent={true}>
                    <div className="d-flex flex-column my-3 mx-2">
                        <div className="buttons-box">
                            <button type="button" style={{ width: "12rem" }} type="button" className="d-none d-lg-flex btn btn-option-one" onClick={() => fields.push({empresas: [{}]})}>{t('Agregar grupo')}</button>
                            <button type="button" className="d-flex d-lg-none flex-1 btn btn-option-one" onClick={() => fields.push({empresas: [{}]})}>{t('Agregar grupo')}</button>
                        </div>
                        <div className="form-group d-flex flex-column flex-1">
                            <div className="d-none d-lg-flex buttons-box mt-4">
                                <Link to={`/cohortes`} className="btn btn-secondary mr-3">{t('Cancelar')}</Link>
                                <button type="submit" className="btn btn-primary ml-3">{t('Guardar')}</button>
                            </div>
                            <div className="d-flex d-lg-none flex-column mt-4">
                                <Link to={`/cohortes`} className="btn btn-secondary flex-1">{t('Cancelar')}</Link>
                                <button type="submit" className="btn btn-primary flex-1 mt-3">{t('Guardar')}</button>
                            </div>
                        </div>
                    </div>
                </CardForm>
            </div>
        )}
    </div>
)

let GrupoForm = props => {
    const { handleSubmit, grupos, empresas } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column flex-wrap px-0 mt-2">
                <div className="d-flex flex-column flex-1 mt-2">
                    <FieldArray name="grupos" component={renderGrupos} grupos={grupos} empresas={empresas}  t={props.t} />
                </div>
            </div>
        </form>
    )

}

const selector = formValueSelector('GruposCohorteForm');

GrupoForm = reduxForm({
    form: 'GruposCohorteForm',
    asyncBlurFields: [],
})(GrupoForm);

const mstp = state => {
    const grupos = selector(state, 'grupos');

    return {
        grupos,
    }
};

export default connect(mstp, null)(GrupoForm);
