import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Menu } from 'antd';
import { withTranslation } from 'react-i18next';
const { SubMenu } = Menu;

// Iconos del menú
const Home1 = require("../../../../../assets/img/menu/Home1.png");
const Home2 = require("../../../../../assets/img/menu/Home2.png");
const Masivas1 = require("../../../../../assets/img/menu/Masivas1.png");
const Masivas2 = require("../../../../../assets/img/menu/Masivas2.png");
const Cohorte1 = require("../../../../../assets/img/menu/Cohorte1.png");
const Cohorte2 = require("../../../../../assets/img/menu/Cohorte2.png");
const Configuracion1 = require("../../../../../assets/img/menu/Configuracion1.png");
const Configuracion2 = require("../../../../../assets/img/menu/Configuracion2.png");
const Talleres1 = require("../../../../../assets/img/menu/Talleres1.png");
const Talleres2 = require("../../../../../assets/img/menu/Talleres2.png");
const Reportes1 = require("../../../../../assets/img/menu/Reportes1.png");
const Reportes2 = require("../../../../../assets/img/menu/Reportes2.png");
const Perfil1 = require("../../../../../assets/img/menu/Perfil1.png");
const Perfil2 = require("../../../../../assets/img/menu/Perfil2.png");
const Salir1 = require("../../../../../assets/img/menu/Salir1.png");
const Salir2 = require("../../../../../assets/img/menu/Salir2.png");


const avatar = require("../../../../../assets/img/avatar.png");
const logo = require("../../../../../assets/header/Logo.png")

class SideBar extends Component {

    // submenu keys of first level
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

    state = {
        openKeys: [],
    };

    constructor(props) {
        super(props);
        console.log("props sidebar ", props);
    }

    onOpenChange = openKeys => {
        if (openKeys && openKeys.length) {
            this.setState({ openKeys: [openKeys[openKeys.length - 1]] })
        } else {
            this.setState({ openKeys });
        }
    };

    navigate = (path) => {
        this.props.navToggle();
        this.props.history.push(path);
    }

    render() {
        const { toggleOpen, navToggle, logOut, me } = this.props;
        return (
            <aside className={`main-sidebar px-0 col-10 col-md-3 col-lg-2 ${toggleOpen ? '' : 'open'}`}>
                <div className="main-navbar">
                    <nav className="align-items-stretch bg-white flex-md-nowrap p-0 navbar navbar-light">
                        <div className="d-flex d-md-none flex-row flex-1 justify-content-center align-items-center mt-3">
                            <img src={logo} style={{width: "7rem"}} />
                        </div>
                        <a href="#" onClick={navToggle} className="w-100 mr-0 navbar-brand" >
                            <div className="d-flex flex-column flex-1 justify-content-center align-items-center"
                                style={{ overflow: "hidden" }}>
                                <img className="perfil-img" src={me ? me.avatar ? me.avatar : avatar : avatar} alt="Perfil" />
                                <span className="perfil-nombre">{me ?`${me.nombres && me.nombres}` : 'dfdfdf'}</span>

                            </div>
                        </a>
                        {/* <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={navToggle}>
                            <i className="material-icons"></i>
                        </a> */}
                    </nav>
                </div>
                <div className="nav-wrapper w-100">
                    <Menu
                        mode="inline"
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ width: 256 }}
                    >
                        <Menu.Item
                            key="home"
                            icon={<Fragment>
                                <img src={Home1} className={`icon-menu1`} />
                                <img src={Home2} className={`icon-menu2`} />
                            </Fragment>}
                            onClick={key => this.navigate('/')}
                        >
                            {this.props.t('Inicio')}
                        </Menu.Item>
                        <SubMenu
                            key="grow-hacks"
                            icon={<Fragment>
                                <img src={Talleres1} className={`icon-menu1`} />
                                <img src={Talleres2} className={`icon-menu2`} />
                            </Fragment>}
                            title="Growth Hacks"
                        >
                            <Menu.Item key="talleres"  onClick={key => this.navigate('/talleres')}>{this.props.t('Talleres')}</Menu.Item>
                            <Menu.Item key="panel"  onClick={key => this.navigate('/panel')}>{this.props.t('Panel')}</Menu.Item>
                            <Menu.Item key="mentorias" onClick={key => this.navigate('/mentorias')}>{this.props.t('Mentorías')}</Menu.Item>
                            <Menu.Item key="familyMeetings" onClick={key => this.navigate('/family_meetings')}>{this.props.t('Reuniones Familiares')}</Menu.Item>
                        </SubMenu>
                        {(me.is_superuser&&(
                            <SubMenu
                                key="cohortes"
                                icon={<Fragment>
                                    <img src={Cohorte1} className={`icon-menu1`} />
                                    <img src={Cohorte2} className={`icon-menu2`} />
                                </Fragment>}
                                title={this.props.t("Cohortes")}
                            >
                                <Menu.Item key="cohorte" onClick={key => this.navigate('/cohortes')}>{this.props.t('Cohortes')}</Menu.Item>
                                <Menu.Item key="empresas" onClick={key => this.navigate('/empresas')}>{this.props.t('Empresas')}</Menu.Item>
                                <Menu.Item key="amigos" onClick={key => this.navigate('/amigos_growmotor')}>{this.props.t('Partners y Patrocinadores')}</Menu.Item>
                            </SubMenu>
                        ))}
                        <Menu.Item
                            key="perfil"
                            icon={<Fragment>
                                <img src={Perfil1} className={`icon-menu1`} />
                                <img src={Perfil2} className={`icon-menu2`} />
                            </Fragment>}
                            onClick={key => this.navigate('/perfil')}
                            className="d-block d-md-none"
                        >
                            {this.props.t('Perfil')}
                        </Menu.Item>
                        {(me.is_superuser&&(
                            <SubMenu
                                key="configuraciones"
                                icon={<Fragment>
                                    <img src={Configuracion1} className={`icon-menu1`} />
                                    <img src={Configuracion2} className={`icon-menu2`} />
                                </Fragment>}
                                title={this.props.t('Configuraciones')}
                            >
                                <Menu.Item key="admins" onClick={key => this.navigate('/admins')}>{this.props.t('Administradores')}</Menu.Item>
                                <Menu.Item key="growth_angels" onClick={key => this.navigate('/growth_angels')}>Growth Angels</Menu.Item>
                                <Menu.Item key="avisos_generales" onClick={key => this.navigate('/avisos_generales')}>{this.props.t('Avisos Generales')}</Menu.Item>
                                <Menu.Item key="membresias" onClick={key => this.navigate('/membresias')}>{this.props.t('Membresias')}</Menu.Item>
                            </SubMenu>
                        ))}
                        <Menu.Item
                            key="invitaciones_masivas"
                            icon={<Fragment>
                                <img src={Masivas1} className={`icon-menu1`} />
                                <img src={Masivas2} className={`icon-menu2`} />
                            </Fragment>}
                            onClick={key => this.navigate('/invitaciones_masivas')}
                        >
                            {this.props.t('Invitaciones masivas')}
                        </Menu.Item>
                        <SubMenu
                            key="reportes"
                            icon={<Fragment>
                                <img src={Reportes1} className={`icon-menu1`} />
                                <img src={Reportes2} className={`icon-menu2`} />
                            </Fragment>}
                            title={this.props.t('Reportes')}
                        >
                            <Menu.Item key="asistencias" onClick={key => this.navigate('/reporte/asistencias')}>{this.props.t('Inasistencias y asistencias')}</Menu.Item>
                            <Menu.Item key="nivelacion" onClick={key => this.navigate('/reporte/nivelacion')}>{this.props.t('Evaluaciones de nivelación')}</Menu.Item>
                            <Menu.Item key="empresas" onClick={key => this.navigate('/reporte/empresas')}>{this.props.t('Empresas')}</Menu.Item>
                            <Menu.Item key="demografico" onClick={key => this.navigate('/reporte/demigrafico')}>{this.props.t('Demográfico')}</Menu.Item>
                            <Menu.Item key="retroalimentacion" onClick={key => this.navigate('/reporte/calificaciones_eventos')}>{this.props.t('Retroalimentación')}</Menu.Item>
                        </SubMenu>
                        <Menu.Item
                            key="exit"
                            icon={<Fragment>
                                <img src={Salir1} className={`icon-menu1`} />
                                <img src={Salir2} className={`icon-menu2`} />
                            </Fragment>}
                            onClick={key => {this.navigate('/login'); logOut(); }}
                        >
                            {this.props.t('Salir')}
                        </Menu.Item>
                    </Menu>
                </div>
                <div className="d-block d-lg-none menu-fondo" onClick={navToggle}></div>
            </aside>
        )
    }
}

export default withRouter(withTranslation()(SideBar));
