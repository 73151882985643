import React, { Component } from 'react';
import { Async } from 'react-select';
import classNames from 'classnames';
import { TableHeaderColumn } from "react-bootstrap-table";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { TALLER } from '../../../../../utility/constants';
import i18n from '../../../../../i18nextInit'

import moment from 'moment';
import { api } from "api";


const getCohortes = (search) => {
    const params = {}
    if (search) params.search = search;
    return api.get("cohorte/obtener_cohortes", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


const AsistenciaTab = (props) => {
    const { data2, page2, loader, listarAsistencias } = props;
    const lang = i18n.language ? i18n.language : 'en'
    const getTalleres = (search) => {
        const params = { tipo_publicacion: TALLER }
        if (search) params.search = search;
        if (props.cohorte) params.cohorte = props.cohorte.id;
        return api.get("evento", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    return (
        <Card className="p-lg-4" >
            <div className="px-lg-4 my-2">
                <HeaderBasico
                    search={props.search2} searchChange={props.searchChange2}
                    filtro1={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={props.cohorte}
                            isSearchable={true}
                            loadOptions={getCohortes}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => props.changeCohorte(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={props.t('Filtrar por cohorte')+'...'}
                        />
                    }
                    filtro2={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={props.evento}
                            isSearchable={true}
                            loadOptions={getTalleres}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["titulo"])}
                            type="text"
                            onChange={(e) => props.changeEvento(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder={props.t('Filtrar por evento')+'...'}
                        />
                    }
                />
            </div>
            <div className="px-lg-2 my-3 d-flex flex-row flex-1 justify-content-end">
                <button onClick={props.descargarExcel} className="btn btn-primary">{props.t('Descargar excel')}</button>
            </div>
            <Grid hover striped data={data2} page={page2} loading={loader} onPageChange={listarAsistencias}>
                <TableHeaderColumn
                    isKey
                    dataField="id"
                    dataAlign="center"
                    width="2%"
                    dataFormat={cell => ''}
                />
                <TableHeaderColumn
                    dataField="usuario"
                    dataFormat={cell => cell ? cell.nombres : ''}
                >
                    {props.t('Usuario')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="empresa"
                    dataFormat={cell => (cell ?
                        <div className="d-flex flex-1 align-items-center">
                            <img className="logo-tabla mr-3" src={cell.logo} />
                            <span>{cell.nombre}</span>
                        </div>
                        : ''
                    )}
                >
                    {props.t('Empresa')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="cohorte_titulo"
                >
                    {props.t('Cohorte')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="evento_titulo"
                >
                    {props.t('Evento')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="creado"
                    width="10%"
                    dataFormat={cell => moment(cell).locale(lang).format('MMM-DD-YYYY')}
                >
                    {props.t('Fecha')}
                </TableHeaderColumn>
            </Grid>
        </Card>
    )
}

export default AsistenciaTab;
