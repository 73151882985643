import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../../i18nextInit'
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Card from '../../../Utils/Cards/Card';
import moment from 'moment';

const sistema = require("../../../../../../assets/img/iconos/sistema.png");

class Notificaciones extends Component {

    componentDidMount() {
        console.log(this.props);
        this.props.listar();
    }

    render() {
        const { loader, data, listar, page } = this.props;
        
        const lang = i18n.language ? i18n.language : 'en'

        return (
            <div className="social-layout mb-4 col-12 mt-3 mt-md-0" style={{ maxWidth: "100%" }}>
                <LoadMask loading={loader} blur fixed>
                    <Card noPadding={true}>
                        <div className="d-flex flex-column flex-1 pt-3 py-3">
                            <div className="d-flex flex-row flex-1 borde-bottom px-5">
                                <h4 className=" azul-gris-oscuro">{this.props.t('Hoy')}</h4>
                            </div>
                            <div className="d-flex flex-column flex-1">
                                {data.results.map(item => {
                                    const creado = moment(item.creado);

                                    if (creado.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                                        return (
                                            <div key={item.id} className="d-flex flex-row flex-1 borde-bottom px-md-3 px-2 py-2">
                                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                                    <img
                                                        style={{ width: "2.5rem", height: "2.5rem" }}
                                                        className="d-none d-md-flex logo-tabla"
                                                        src={item.generado_por ? item.generado_por.avatar : sistema}
                                                    />
                                                    <img
                                                        style={{ width: "2rem", height: "2rem" }}
                                                        className="d-flex d-md-none logo-tabla"
                                                        src={item.generado_por ? item.generado_por.avatar : sistema}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column flex-5 justify-content-center align-items-start">
                                                    <span className="text-sm bold azul-gris-oscuro">{item.generado_por ? item.generado_por.nombre : 'Growmotor'}</span>
                                                    <span className="text-equal azul-gris-oscuro">{item.mensaje}</span>
                                                    <span className="text-equal regular gris">{creado.locale(lang).fromNow()}</span>
                                                </div>
                                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                                    {(item.link) && (
                                                        <button onClick={() => window.open(item.link, '_blank')} className="d-flex btn btn-primary">{this.props.t('Ver')}</button>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="d-flex flex-row flex-1 borde-bottom px-5 mt-3">
                                <h4 className="azul-gris-oscuro">{this.props.t('Anteriores')}</h4>
                            </div>
                            <div className="d-flex flex-column flex-1">
                                {data.results.map(item => {
                                    const creado = moment(item.creado);

                                    if (creado.format("YYYY-MM-DD") != moment().format("YYYY-MM-DD")) {
                                        return (
                                            <div key={item.id} className="d-flex flex-row flex-1 borde-bottom px-3 py-2">
                                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                                    <img
                                                        style={{ width: "2.5rem", height: "2.5rem" }}
                                                        className="logo-tabla"
                                                        src={item.generado_por ? item.generado_por.avatar : sistema}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column flex-5 justify-content-center align-items-start">
                                                    <span className="text-sm bold azul-gris-oscuro">{item.generado_por ? item.generado_por.nombre : 'Growmotor'}</span>
                                                    <span className="text-equal azul-gris-oscuro">{item.mensaje}</span>
                                                    <span className="text-equal regular gris">{creado.locale(lang).fromNow()}</span>
                                                </div>
                                                <div className="d-flex flex-1 justify-content-center align-items-center">
                                                    {(item.link) && (
                                                        <button onClick={() => window.open(item.link, '_blank')} className="d-flex btn btn-primary">{this.props.t('Ver')}</button>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            {(data.results.length) ? (
                                <div className="d-flex flex-row flex-1 align-items-center my-3 px-3">
                                    <Pagination pageSize={10} current={page} total={data.count} onChange={listar} />
                                </div>
                            ) : (
                                <div className="d-flex flex-row flex-1 justify-content-center align-items-center my-3">
                                    <span className="text-sm bold gris-oscuro">{this.props.t('Sin resultados')}</span>
                                </div>
                            )}
                        </div>
                    </Card>
                </LoadMask>
            </div>
        )
    }

}

export default withTranslation()(Notificaciones)
