import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import Card from '../../../Utils/Cards/Card';
import { withTranslation } from 'react-i18next';
import Search from '../../../Utils/Grid/SearchInput';
import { standardActions } from "../../../Utils/Grid/StandardActions";


class GridEmpresas extends Component {

    render() {
        const { data } = this.props;
        return (
            <div className="col-12 p-0">
                <Grid hover striped noResponsive={true} pagination={false}
                    data={data ? { results: data, count: data.length } : { results: [],count: 0}} >
                    <TableHeaderColumn
                        isKey
                        dataField="empresa"
                        dataAlign="center"
                        dataSort
                        dataFormat={standardActions({eliminar:this.props.removerEmpresa})}
                    >
                        {this.props.t('ACCIÓNES')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="empresa"
                        dataSort
                        dataFormat={cell=>cell.nombre}
                    >
                        {this.props.t('NOMBRE')}
                    </TableHeaderColumn>
                </Grid>
            </div>
        )
    }
}

export default withTranslation()(GridEmpresas)