import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CohorteForm from '../CohorteForm';
import moment from 'moment';
import 'moment-timezone';
import CardForm from '../../../Utils/Cards/CardForm';
import 'react-responsive-modal/styles.css';

export default class EditarAdmin extends Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        const { me }=this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            if (id){
                this.props.leer(id);
            }
        }
    }
    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const body = {...values};
        delete body.empresa;
        body.fecha_inicio = moment(body.fecha_inicio).tz(body.timezone).startOf('day').format();
        body.fecha_fin = moment(body.fecha_fin).tz(body.timezone).endOf('day').format();
        if (body.pais){
            body.pais= body.pais.name
        }
        if (body.empresas){
            body.empresas = body.empresas.map(item => ({id:item.id,empresa:item.empresa}) )
        }
        if (!body.empresas){
            body.empresas=[]
        }
        if (body.administrador){
            body.administrador = body.administrador.id;
        }
        if(id){
            console.log("data ", body)

            this.props.editar(body, id);
        }
    }

    render() {
        const { loader, getEmpresas, agregarEmpresa, enviarCorreos, removerEmpresa } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <CohorteForm
                            onSubmit={this.onSubmit}
                            getEmpresas={getEmpresas}
                            agregarEmpresa={agregarEmpresa}
                            editar={true}
                            openModal={this.onOpenModal}
                            removerEmpresa={removerEmpresa}
                            enviarCorreos={() => enviarCorreos(this.props.match.params.id)}
                            t={this.props.t}
                            item={this.props.item}
                            insertarEmpresas={this.props.insertarEmpresas} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
