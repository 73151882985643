import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/amigosGrowmotor';
import Crear from './Crear';

const ms2p = (state) => {
    return {
        ...state.amigosGrowmotor,
        me:state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Crear);
