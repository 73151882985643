import React, { useState, useEffect } from 'react';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";


const ModalColaboradores = (props) => {

    const { item, cerrar } = props;

    return (
        <div style={{ width: "45vw" }} className="d-flex flex-column flex-1 w-100-responsivo">
            <div className="d-flex flex-row borde-bottom px-3 py-1 flex-1 pt-3">
                <div className="d-flex flex-column flex-1">
                    <span className="text-sm">{props.t('Taller')}</span>
                    <span className="celeste-oscuro bold text-sm">{item ? item.titulo : ''}</span>
                </div>
                <div className="d-flex flex-column flex-1">
                    <span className="text-sm">{props.t('Taller')}</span>
                    <span className="celeste-oscuro bold text-sm">{item ? item.fecha_inicio : ''}</span>
                </div>
            </div>
            <div className="d-flex flex-column flex-1 my-3 px-3">
                <Grid hover striped data={{count: 0, results: item ? item.colaboradores : []}} loading={false} pagination={false} >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataAlign="center"
                        width="2%"
                        dataFormat={cell => ''}
                    />
                    <TableHeaderColumn
                        dataField="nombres"
                    >
                        {props.t('Colaboradores')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="asistencia"
                        dataFormat={cell => (cell ? cell.asistencia ? <span className="verde">{props.t('Asistió')}</span>
                        : <span className="marron">{props.t('Asistencia anulada')}</span>
                        : <span className="marron">{props.t('No asistió')}</span>)}
                    >
                        {props.t('Estado')}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="asistencia"
                        dataFormat={cell => (cell ? cell.nota_inasistencia : '' )}
                    >
                        {props.t('Comentario')}
                    </TableHeaderColumn>
                </Grid>
            </div>
            <div className="d-none d-lg-flex buttons-box my-4">
                <button type="button" onClick={cerrar} className="btn btn-secondary">{props.t('Regresar')}</button>
            </div>
            <div className="d-flex d-lg-none flex-column my-4">
                <button type="button" onClick={cerrar} className="btn btn-secondary flex-1">{props.t('Regresar')}</button>
            </div>
        </div>
    )
}

export default ModalColaboradores;
