import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from '../../../Utils/Cards/Card';
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { HeaderBasico } from '../../../Utils/Grid/Headers';
import Toolbar from '../../../Utils/Grid/Toolbar';
import { withTranslation } from 'react-i18next';


class ListarEmpresas extends Component {
    componentDidMount=()=>{
        console.log('props ', this.props);
        const { listar, page, me } = this.props;
        if(me&&me.is_superuser===false){
            this.props.history.push('/404');
        }else{
            listar(page);
        }
    }

    render() {
        const { data, loader, listar: onPageChange, onSortChange, eliminar, page } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                {/* Botones */}
                <Toolbar to='/empresa/crear' textBtn={this.props.t('Crear empresa')} />
                <Card>
                    <div className="px-lg-2 my-2">
                        <HeaderBasico {...this.props} />
                    </div>
                    <Grid hover striped data={data ? data : {results:[], count:0 }} page={page} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange} >
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ editar: "/empresa", eliminar })}
                        >
                            {this.props.t('Acciones')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            {this.props.t('Nombre')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre_admin"
                            dataSort
                        >
                            {this.props.t('Dueño(a)')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="telefono"
                            dataSort
                        >
                            {this.props.t('Teléfono')}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="correo_admin"
                            dataSort
                        >
                            {this.props.t('Correo')}
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>

        )
    }
}

export default withTranslation()(ListarEmpresas)

