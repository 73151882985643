import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/publicaciones/mentorias';
import CrearMentoria from './CrearMentoria';

const ms2p = (state) => {
    return {
        ...state.mentorias,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearMentoria);
