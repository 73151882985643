import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import PerfilForm from './PerfilForm';
import moment from 'moment';
import { withTranslation } from 'react-i18next';


class EditarPerfil extends Component {

    state = {
        avatar: null
    }

    componentDidMount() {
        const { leer } = this.props;
        leer();
    }

    setAvatar = (avatar) => {
        this.setState({ avatar });
    }

    onSubmit = (values) => {
        if (this.state.avatar != null) {
            this.props.editar(values, [{"file": this.state.avatar, "name": "avatar"}])
        } else {
            this.props.editar(values, [])
        }
    }

    render() {
        const { loader, item } = this.props;

        return (
            <div className="mb-4 col-12 mt-0">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <PerfilForm item={item} onSubmit={this.onSubmit} setAvatar={this.setAvatar} t={this.props.t} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default withTranslation()(EditarPerfil);