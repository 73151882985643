import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CardForm from '../../../Utils/Cards/CardForm';
import {NotificationManager} from "react-notifications";
import FormEvaluacion from './FormEvaluacion';
import { withTranslation } from 'react-i18next';


class CrearEvaluacion extends Component {


    componentWillMount() {
        const id = this.props.match.params.id;
        const idEva = this.props.match.params.idEva;

        const { leerTaller, leer } = this.props;
        leerTaller(id);

        if (idEva)
            leer(idEva)
    }

    onSubmit = (values) => {
        const url = 'panel'
        const idEva = this.props.match.params.idEva;
        const id = this.props.match.params.id;
        const body = {...values, publicacion: id};

        if (body.bloques && body.bloques.length) {
            if (idEva) {
                this.props.editar(id, idEva, body, url);
            } else {
                this.props.crear(id, body, url);
            }
        } else {
            NotificationManager.error(this.props.t('Por favor crear al menos un bloque'), this.props.t('ERROR'), 3000);
        }
    }

    render() {
        const { loader, taller } = this.props;
        const url = window.location.href;

        return (
            <div className="mb-4 col-12 mt-0">
                <LoadMask loading={loader} blur>
                    <FormEvaluacion {...this.props} onSubmit={this.onSubmit} taller={taller} url={url} id={this.props.match.params.id} me={this.props.me} />
                </LoadMask>
            </div>
        )
    }
}

export default withTranslation()(CrearEvaluacion)
